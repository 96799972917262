.logoBlockWrapper {
    height: 500px;
    background:
        url('../../../../shared/images/svg/landingHeader/cubesXl.svg') center left / auto 100% no-repeat,
        url('../../shared/images/png/propackHeaderBg.png') center right / auto 100% no-repeat;
}

.contentWrapper {
    padding: 136px 62px 0;

    & > svg {
        height: 60px;
        width: 560px;
        margin-bottom: 32px;
    }

    & > p {
        width: 590px;
        font-weight: 350;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 32px;
    }

    & > div {
        gap: 12px;

        & > button,
        & > a {
            display: flex;
            height: 56px;
            padding: 18px 30px 18px 20px;
            gap: 8px;
            align-items: center;
            text-decoration: none;
            border-radius: 6px;

            & > span {
                font-weight: 350;
                font-size: 16px;
                line-height: 20px;
                display: block;
            }

            &:first-child {
                background-color: var(--ds-main-red);
                border: none;

                & > span {
                    color: var(--ds-main-white);
                }
            }

            &:last-child {
                background-color: var(--ds-main-white);
                border: 1px solid var(--ds-main-gray);

                & > span {
                    color: var(--ds-main-black);
                }
            }
        }
    }
}

@media (max-width: 1359px) {
    .logoBlockWrapper {
        height: 400px;
        background:
            url('../../../../shared/images/svg/landingHeader/cubesLg.svg') center left / auto 100% no-repeat,
            url('../../shared/images/png/propackHeaderBg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 85px 48px 0;
    }
}

@media (max-width: 1023px) {
    .logoBlockWrapper {
        background:
            url('../../../../shared/images/svg/landingHeader/cubesMd.svg') center left / auto 100% no-repeat,
            url('../../shared/images/png/propackHeaderBg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 72px 24px 0;

        & > svg {
            width: 298px;
            height: 48px;
        }
    }
}

@media (max-width: 767px) {
    .logoBlockWrapper {
        height: 595px;
        background:
            url('../../../../shared/images/svg/landingHeader/cubesSm.svg') left bottom / 144px 288px no-repeat,
            url('../../shared/images/png/propackHeaderBgSm.png') right bottom / 100% 288px no-repeat;
    }

    .contentWrapper {
        padding: 36px 18px 51px 18px;
        background: var(--ds-main-white);

        & > svg {
            width: 324px;
            height: 32px;
            margin-bottom: 24px;
        }

        & > p {
            width: 100%;
            font-size: 14px;
            line-height: 22px;
        }

        & > div {
            flex-direction: column;
            gap: 6px;

            & > button {
                width: 190px;
                height: 48px;
                padding: 12px 20px 12px 12px;
                display: flex;
                justify-content: center;

                & > span {
                    font-size: 14px;
                    line-height: 24px;
                    display: inline;
                }
            }
            & > a {
                height: 48px;
                width: 190px;
                padding: 0 0 0 18px;
                & > span {
                    font-size: 14px;
                }
            }
        }
    }
}
