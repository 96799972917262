.trialVersionWrapper {
    width: 100%;
    height: 487px;
    position: relative;
    background: url('../../shared/images/png/trialVersionBg.png') center / 100% 100% no-repeat;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9));
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../../shared/images/svg/trialVersionCubes/trialVersionCubesXl.svg') top right / auto 100%
            no-repeat;
    }
}

.contentWrapper {
    width: 724px;
    height: fit-content;
    margin-left: 62px;
    padding-top: 127px;
    position: relative;
    z-index: 2;

    & > h2 {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 56px;
        font-weight: 500;
        line-height: 60px;
        text-align: left;
        text-transform: uppercase;
        color: var(--ds-main-white);
        margin-bottom: 56px;
    }

    & > button,
    & > a {
        display: flex;
        width: 206px;
        height: 56px;
        padding: 16px 30px 16px 20px;
        gap: 8px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-red);
        text-decoration: none;

        span {
            margin-top: 2px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 1359px) {
    .trialVersionWrapper {
        height: 400px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../shared/images/svg/trialVersionCubes/trialVersionCubesLg.svg') top right / auto 100%
                no-repeat;
        }
    }

    .contentWrapper {
        margin-left: 48px;
        padding-top: 99px;

        & > h2 {
            font-size: 46px;
            line-height: 50px;
            margin-bottom: 46px;
        }
    }
}

@media (max-width: 1023px) {
    .trialVersionWrapper {
        height: 340px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../shared/images/svg/trialVersionCubes/trialVersionCubesMd.svg') top right / auto 100%
                no-repeat;
        }
    }

    .contentWrapper {
        width: 560px;
        margin-left: 24px;
        padding-top: 94px;

        & > h2 {
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 36px;
        }
    }
}

@media (max-width: 767px) {
    .trialVersionWrapper {
        height: 190px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../shared/images/svg/trialVersionCubes/trialVersionCubesSm.svg') top right / auto 100%
                no-repeat;
        }
    }

    .contentWrapper {
        width: 262px;
        padding-top: 42px;

        & > h2 {
            font-size: 17px;
            line-height: 24px;
            margin-bottom: 16px;
        }

        & > button {
            width: 190px;
            height: 42px;
            padding: 9px 24px 9px 12px;
            gap: 6px;

            span {
                line-height: 18px;
            }
        }
    }
}
