.confirmContainer {
  padding: 48px;

  & > h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  & > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 24px;
    margin-bottom: 68px;
  }

  & > div {
    & > *:first-child {
      background-color: var(--ds-main-black);
      color: var(--ds-main-white);
      display: flex;
      min-width: 96px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      border: none;
      margin-right: 12px;

      & > span {
        color: var(--ds-main-white);
        font-weight: 350;
      }
    }

    & > *:last-child {
      color: var(--ds-main-black);
      background-color: var(--ds-main-white);
      display: flex;
      min-width: 96px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      border-color: var(--ds-main-black);

      & > span {
        font-weight: 350;
      }
    }
  }
}
