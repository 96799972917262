.posContainer {
    padding: 100px 62px 97px 62px;
    gap: 101px;

    & > h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px; /* 87.5% */
        letter-spacing: -0.01em;
        text-transform: uppercase;
        width: 400px;
    }

    & > div {
        height: auto;
        gap: 56px 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin-right: 76px;
    }

    .posItem {
        max-width: 476px;
        gap: 32px;
        & > img {
            width: 60px;
            height: 60px;
        }
        & > div {
            max-width: 384px;
            & > div {
                font-size: 24px;
                font-style: normal;
                font-weight: 350;
                line-height: 24px;
                margin-bottom: 20px;
                max-width: 300px;
            }
            & > span {
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: 1359px) {
    .posContainer {
        padding: 80px 24px 80px 24px;
        flex-direction: column;

        & > h2 {
            font-size: 40px;
        }

        & > div {
            gap: 48px 13px;
            margin-right: 0;
        }

        .posItem {
            max-width: 479px;
            gap: 33px;
            flex-direction: row;

            & > div {
                max-width: 351px;
                & > div {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 350;
                    line-height: 24px;
                    margin-bottom: 24px;
                    max-width: 300px;
                }
                & > span {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .posContainer {
        grid-template-rows: min-content min-content;
        gap: 63px;

        & > div {
            height: auto;
        }
        .posItem {
            flex-direction: column;
            gap: 16px;
        }
    }
}

@media (max-width: 767px) {
    .posContainer {
        gap: 48px;

        & > h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px; /* 108.333% */
            letter-spacing: -0.24px;
            text-transform: uppercase;
            width: 200px;
        }

        & > div {
            height: 516px;
            gap: 30px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        .posItem {
            max-width: 351px;
            gap: 16px;
            flex-direction: column;

            & > img {
                width: 40px;
                height: 40px;
            }

            & > div {
                max-width: 324px;
                & > div {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 350;
                    line-height: 20px;
                    margin-bottom: 16px;
                }
                & > span {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .posContainer {
        padding-bottom: 78px;
        padding-left: 18px;
        padding-right: 18px;

        & > div {
            height: 607px;
            grid-template-columns: 1fr;
        }
        .posItem {
            flex-direction: row;
            height: 120px;
        }
    }
}
