$title-gray: #576273;

.chartSection {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.chartContainer {
    position: relative;
    width: 100%;
    display: flex;
    overflow: clip;
    height: auto;
}

.chart {
    width: 100%;
    max-height: 301px;
    margin-right: 8px;

    & > canvas {
        max-height: 300px;
        height: 100%;
    }
}

.titleBottom {
    position: absolute;
    bottom: 10%;
    left: 2%;
    & span {
        font-family: Inter;
        font-size: 11px;
        font-weight: 600;
        line-height: 13.31px;
        letter-spacing: -0.05em;
        text-align: right;
        color: $title-gray;
    }
}

.titleTop {
    position: absolute;
    top: 5%;
    right: 7%;
    & span {
        font-family: Inter;
        font-size: 11px;
        font-weight: 600;
        line-height: 13.31px;
        letter-spacing: -0.05em;
        text-align: right;
        color: $title-gray;
    }
}

.horizontalSliderContainer {
    position: relative;
    height: 36px;
    width: 99%;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    margin-left: 6px;
    margin-top: 20px;

    .chartPreview {
        & > canvas {
            height: 36px;
            width: 100% !important;
        }
    }

    .sliderOverlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        width: 100%;
        z-index: 10;

        .sliderRoot {
            height: 36px;
            background-color: rgba(245, 247, 250, 0.1);

            .range {
                background-color: var(--ds-bg-dark-blue);
                opacity: 0.1;
                border-left: 2px solid var(--ds-main-blue);
                border-right: 2px solid var(--ds-main-blue);
            }

            .handle {
                height: 16px;
                width: 16px;
                border-radius: 0px 0px 4px 4px !important;
                border: none !important;
                border-color: var(--ds-bg-dark-blue) !important;
                background-color: var(--ds-bg-dark-blue);
                position: relative;
            }

            & > span:nth-child(2) {
                margin-top: -8px;
                position: relative;

                rotate: 270deg;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: transparent;
                    transform: translate(75%, -50%);
                    width: 8px;
                    height: 8px;
                    background-image: url('./img/Vector.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    rotate: 90deg;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: var(--ds-bg-dark-blue);
                    top: 0px;
                    left: -9px;
                    width: 34px;
                    height: 1px;
                }
            }

            & > span:nth-child(3) {
                margin-top: -8px;
                margin-left: -16px;
                rotate: 90deg;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: transparent;
                    transform: translate(75%, -50%);
                    width: 8px;
                    height: 8px;
                    background-image: url('./img/Vector.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    rotate: 90deg;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: var(--ds-bg-dark-blue);
                    top: 0px;
                    left: -10px;
                    width: 34px;
                    height: 1px;
                }
            }
        }
    }
}

.verticalSliderRoot {
    margin-top: 20px;
    margin-right: 8px;
    right: 0;
    max-height: 300px;
    height: auto;
    width: 1px;
    background-color: var(--ds-border-gray);

    .range {
        background-color: var(--ds-main-blue);
        // height: 100%;
    }

    .handle {
        height: 16px;
        width: 16px;
        border-radius: 4px 4px 4px 4px !important;
        border: none !important;
        z-index: 10;
        background-color: var(--ds-bg-dark-blue);
        border-color: var(--ds-bg-dark-blue) !important;
        margin-top: -0.5rem;
        margin-left: -0.5rem;
    }

    & > span:nth-child(2) {
        rotate: -180deg;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            background-color: transparent;
            transform: translate(75%, -50%);
            width: 8px;
            height: 8px;
            background-image: url('./img/Vector.svg');
            background-size: contain;
            background-repeat: no-repeat;
            rotate: 90deg;
        }
    }

    & > span:nth-child(3) {
        rotate: 0deg;

        &:after {
            content: '';
            display: block;
            position: absolute;
            background-color: transparent;
            transform: translate(75%, -50%);
            width: 8px;
            height: 8px;
            background-image: url('./img/Vector.svg');
            background-size: contain;
            background-repeat: no-repeat;
            rotate: 90deg;
        }
    }
}

.hoveredTooltip {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;
}

.staticTooltip {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;

    &:hover {
        z-index: 1000;
    }
}

@media (hover: none) and (pointer: coarse) {
    .hoveredTooltip {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .verticalSliderRoot {
        height: auto;
    }
}
