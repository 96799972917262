.requestModalWrapper {
    width: 480px;
    padding: 43px 48px;

    & > h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 19px;
    }

    & > p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
    }

    & > button {
        padding: 12px 24px;
        width: 112px;
        height: 42px;
        border-radius: 6px;
        border: none;
        background-color: var(--ds-main-black);
        margin-top: 50px;

        & > span {
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}
