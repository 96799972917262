@import '../../../../../../../shared/commonStyles/media-css-variables';

.functionalItemWrapper {
    width: 100%;
    height: 360px;
    gap: 60px;
}

.imageWrapper {
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.textWrapper {
    width: 50%;
    height: 100%;
    gap: 12px;

    & > h4 {
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }

    & > p {
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-dark-gray);
    }
}

@media (max-width: $lg) {
    .functionalItemWrapper {
        height: 300px;
        gap: 48px;
    }

    .imageWrapper {
        height: 300px;
    }

    .textWrapper {
        & > h4 {
            font-size: 28px;
        }
    }
}

@media (max-width: $md) {
    .functionalItemWrapper {
        height: auto;
        gap: 24px;
    }

    .imageWrapper {
        width: 100%;
        height: 360px;
    }

    .textWrapper {
        width: 100%;
        height: auto;
    }
}

@media (max-width: $sm) {
    .functionalItemWrapper {
        gap: 20px;
    }

    .imageWrapper {
        height: 220px;
    }

    .textWrapper {
        & > h4 {
            font-size: 18px;
            line-height: 20px;
        }

        & > p {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
