.serviceItemWrapper {
    margin: 0 62px 120px;
    gap: 60px;
}

.logoBlockWrapper {
    height: 500px;
    border-radius: 6px;
}

.contentWrapper {
    width: 600px;
    height: 100%;
    gap: 35px;

    & > h2 {
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        text-align: left;
        color: #000;
    }

    .textWrapper {
        gap: 10px;

        & > p {
            font-size: 18px;
            font-weight: 350;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-dark-gray);
        }

        & > ul {
            padding-left: 24px;
            list-style: none;

            li {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                text-align: left;
                color: var(--ds-main-dark-gray);
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: -24px;
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    background-color: var(--ds-main-red);
                }

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .btnWrapper {
        gap: 12px;

        & > button,
        & > a {
            display: flex;
            height: 56px;
            padding: 16px 30px 16px 20px;
            gap: 8px;
            border-radius: 6px;
            text-decoration: none;

            span {
                margin-top: 2px;
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 20px;
                text-align: left;
                color: inherit;
            }
        }
    }
}

.redBtn {
    color: var(--ds-main-white);
    background: var(--ds-main-red);
    border: none;
}

.whiteBtn {
    color: var(--ds-main-black);
    background: var(--ds-main-white);
    border: 1px solid var(--ds-border-gray);
}

@media (max-width: 1359px) {
    .serviceItemWrapper {
        margin: 0 48px 120px;
    }

    .logoBlockWrapper {
        height: 420px;
    }

    .contentWrapper {
        width: 488px;

        .textWrapper {
            & > p {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .serviceItemWrapper {
        margin: 0 24px 80px;
        gap: 48px;
    }

    .logoBlockWrapper {
        height: 360px;
    }

    .contentWrapper {
        gap: 24px;

        & > h2 {
            font-size: 36px;
            line-height: 36px;
        }
    }
}

@media (max-width: 767px) {
    .serviceItemWrapper {
        margin: 0 18px 50px;
        gap: 50px;
    }

    .logoBlockWrapper {
        height: auto;
        border-radius: 6px;
    }

    .contentWrapper {
        width: 100%;
        padding: 259px 0 0 0;
        height: auto;
        gap: 16px;

        & > h2 {
            font-size: 30px;
            line-height: 30px;
        }

        .btnWrapper {
            padding: 24px 0 0 0;
            flex-direction: column;

            & > button {
                height: 42px;
                padding: 9px 24px 9px 12px;
                gap: 6px;
                display: flex;
                justify-content: center;

                span {
                    line-height: 18px;
                    text-align: center;
                    max-width: 126px;
                }
            }

            .widthBtn {
                width: fit-content;
            }

            &:has(.widthBtn) {
                padding: 0;
            }
        }
    }
}
