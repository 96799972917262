.productCapabilitiesWrapper {
    height: 500px;
    padding: 0 62px;
    justify-content: space-between;

    h2 {
        margin-top: 96px;
        width: 283px;
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }
}

.contentWrapper {
    margin-top: 92px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 60px;
    align-content: start;
}

@media (max-width: 1359px) {
    .productCapabilitiesWrapper {
        flex-direction: column;
        justify-content: normal;
        height: 515px;
        padding: 0 48px;

        h2 {
            margin-top: 70px;
            width: 236px;
            font-size: 28px;
            line-height: 32px;
            letter-spacing: 0;
        }
    }

    .contentWrapper {
        margin-top: 42px;
        gap: 51px 60px;
    }
}

@media (max-width: 1023px) {
    .productCapabilitiesWrapper {
        height: 612px;
        padding: 0 24px;

        h2 {
            margin-top: 54px;
        }
    }

    .contentWrapper {
        gap: 43px 60px;
    }
}

@media (max-width: 767px) {
    .productCapabilitiesWrapper {
        height: 1044px;
        padding: 0 18px;

        h2 {
            margin-top: 104px;
            font-size: 22px;
            line-height: 24px;
            letter-spacing: -0.01em;
        }
    }

    .contentWrapper {
        margin-top: 24px;
        grid-template-columns: repeat(1, 1fr);
        gap: 35px;
    }
}
