.cartProductsListSection {
  padding: 0 62px 62px 62px;

  .productsList {
    max-width: 851px;
    margin-right: 24px;

    .productsListItem:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 1600px) {
  .cartProductsListSection {
    padding: 0 24px 48px 24px;
    .productsContainer {
      flex-direction: column;

      .productsList {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .cartProductsListSection {
    padding: 0 24px 48px 24px;

    .productsList {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 768px) {
  .cartProductsListSection {
    padding: 0 17px 28px 17px;

    .productsList {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 24px;

      .productsListItem:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
