.renewalModalWrapper {
    width: 480px;
    padding: 48px;
    gap: 32px;
}

.contentWrapper {
    gap: 24px;

    & > h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-transform: uppercase;
    }

    & > span {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
}

.dataWrapper {
    gap: 32px;

    & > div {
        gap: 16px;
    }
}

.dropdownWrapper {
    gap: 8px;

    span {
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: var(--ds-main-black);
    }

    & > div {
        width: 100%;
    }
}

.checkboxWrapper {
    & > div {
        gap: 8px;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
        }

        svg {
            cursor: pointer;
        }
    }
}

.buttonsWrapper {
    gap: 12px;

    & > button {
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: none;
        border-radius: 6px;

        & > span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: inherit;
        }

        &.blackBtn {
            background: var(--ds-main-black);
            color: var(--ds-main-white);
        }

        &.whiteBtn {
            background: var(--ds-main-white);
            color: var(--ds-main-black);
            border: 1px solid var(--ds-main-gray);
        }
    }
}

@media (max-width: 767px) {
    .renewalModalWrapper {
        width: 324px;
        padding: 43px 24px 24px;
    }
}
