.searchItemWrapper {
    padding: 6px 0;
    gap: 18px;

    button {
        width: fit-content;
        height: 42px;
        padding: 12px 24px;
        border: 1px solid var(--ds-main-gray);
        border-radius: 6px;
        background: var(--ds-main-white);

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: center;
            color: var(--ds-main-black);
        }
    }
}

.contentWrapper {
    gap: 14px;

    & > div {
        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-gray);
        }

        h4 {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-black);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    p {
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-black);
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 1359px) {
    .contentWrapper {
        gap: 15px;

        & > div {
            h4 {
                font-size: 20px;
                line-height: 20px;
            }
        }

        p {
            font-size: 16px;
        }
    }
}

@media (max-width: 767px) {
    .searchItemWrapper {
        padding: 6px 0 3px;
        gap: 13px;
    }

    .contentWrapper {
        gap: 10px;

        & > div {
            span {
                font-size: 12px;
                line-height: 14px;
            }

            h4 {
                font-size: 18px;
                line-height: 18px;
            }
        }

        p {
            font-size: 12px;
            line-height: 18px;
        }
    }
}
