.priceBlockWrapper {
    gap: 110px;
    padding: 90px 62px 120px;
    background: var(--ds-bg-extra-light-gray);
}

.descriptionWrapper {
    gap: 6px;

    h2 {
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }

    p {
        width: 402px;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-dark-gray);
    }
}

.priceWrapper {
    margin-top: 7px;
    width: 861px;
    gap: 24px;

    h4 {
        font-size: 15px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }
}

.includeTariff {
    gap: 7px;

    & > div {
        gap: 16px;
        height: 104px;
    }

    .itemWrapper {
        gap: 16px;
        width: fit-content;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
        }
    }

    & > span {
        margin-top: 9px;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: var(--ds-border-gray);
}

.dopInfo {
    gap: 7px;

    & > div {
        gap: 16px;
        width: 451px;
    }

    .dopOptionItem {
        & > div {
            width: 341px;
            gap: 16px;

            span {
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                text-align: left;
            }
        }

        & > span {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;

            span {
                font-weight: 400;
            }
        }
    }
}

@media (max-width: 1359px) {
    .priceBlockWrapper {
        gap: 74px;
        padding: 65px 48px 120px;
    }

    .descriptionWrapper {
        gap: 4px;

        h2 {
            font-size: 28px;
        }

        p {
            width: 255px;
        }
    }

    .priceWrapper {
        margin-top: 5px;
        width: 100%;
    }

    .includeTariff {
        & > div {
            height: 144px;
        }
    }

    .dopInfo {
        & > div {
            width: 100%;
        }

        .dopOptionItem {
            & > div {
                width: fit-content;
            }
        }
    }
}

@media (max-width: 1023px) {
    .priceBlockWrapper {
        flex-direction: column;
        gap: 24px;
        padding: 65px 24px 80px;
    }

    .descriptionWrapper {
        p {
            width: 100%;
        }
    }

    .priceWrapper {
        margin-top: 0;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .priceBlockWrapper {
        gap: 30px;
        padding: 38px 18px 60px;
    }

    .descriptionWrapper {
        gap: 14px;

        h2 {
            font-size: 22px;
            line-height: 24px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .priceWrapper {
        gap: 18px;
    }

    .includeTariff {
        gap: 10px;

        .itemWrapper {
            span {
                font-size: 14px;
            }
        }

        & > span {
            font-size: 18px;
        }
    }

    .dopInfo {
        gap: 8px;

        & > div {
            gap: 20px;
        }

        .dopOptionItem {
            & > div {
                width: 232px;

                span {
                    font-size: 15px;
                    line-height: 20px;
                }
            }

            & > span {
                font-size: 14px;
            }
        }
    }
}
