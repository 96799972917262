.articlePageWrapper {
    padding: 97px 308px 97px 491px;

    & > div:last-child {
        border-top: 1px solid var(--ds-border-gray);
        margin-top: 60px;
        padding-top: 24px;
    }
}

.titleWrapper {
    margin: 41px 0 35px;
    gap: 44px;

    & > h2 {
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        text-align: left;
    }

    & > p {
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        text-align: left;
    }
}

@media (max-width: 1359px) {
    .articlePageWrapper {
        padding: 97px 105px 97px 104px;

        & > div:last-child {
            padding-top: 28px;
        }
    }

    .titleWrapper {
        margin: 40px 0 36px;
        gap: 42px;
    }
}

@media (max-width: 1023px) {
    .articlePageWrapper {
        padding: 97px 42px 97px 84px;

        & > div:last-child {
            margin-top: 48px;
            padding-top: 25px;
        }
    }

    .titleWrapper {
        gap: 40px;
    }
}

@media (max-width: 767px) {
    .articlePageWrapper {
        padding: 36px 18px 60px;

        & > div:last-child {
            margin-top: 47px;
            padding-top: 23px;
        }
    }

    .titleWrapper {
        margin: 28px 0 38px;
        gap: 32px;

        & > h2 {
            font-size: 24px;
            line-height: 30px;
        }

        & > p {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
