.cardWrapper {
    height: 283px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    box-sizing: border-box;
    position: relative;

    &.productPage {
        gap: 60px;
        border: none;
        height: 360px;
    }
}

.imageWrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 30px 0 36px 36px;

    .productPage & {
        padding: 44px 0;
    }
}

.textWrapper {
    display: grid;
    gap: 21px;

    .productPage & {
        gap: 24px;

        p {
            font-size: 18px;
            line-height: 30px;
        }

        img {
            height: 46px;
        }
    }

    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        letter-spacing: 0;
    }
}

.marksWrapper {
    position: absolute;
    top: -2px;
    right: 16px;
    display: flex;
    gap: 8px;
}

.btn {
    border-radius: 6px;
    width: max-content;
    min-height: 42px;
    max-height: 42px;
    padding: 12px 24px;
    display: flex;
    align-items: center !important;

    .productPage & {
        width: 325px;
    }

    span {
        font-size: 16px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: 0;
    }
}

.soonBtn {
    padding: 9px 23px 9px 12px;
}

.openBtn {
    background: var(--ds-bg-green);
    border: none;
    text-decoration: none;

    span {
        color: var(--ds-main-white);
    }
}

.disabledBtn {
    background: transparent;
    border: 1px solid var(--ds-main-gray);
    cursor: default;
    gap: 6px;
    align-items: end;

    span {
        color: var(--ds-main-black);
    }

    img,
    span {
        position: relative;
        top: 2px;
    }
}

.earlyVersion {
    width: 96px;
    height: 20px;
    padding: 5px 6px 7px 6px;
    border-radius: 4px;
    background: var(--ds-main-light-gray);

    font-family: 'Favorit Pro', sans-serif;
    font-size: 12px;
    font-weight: 350;
    line-height: 8px;
    text-align: left;
    color: var(--ds-main-gray);
}

@media (max-width: 1359px) {
    .cardWrapper {
        gap: 0;
        height: 322px;

        &.productPage {
            gap: 48px;
            height: 300px;
        }
    }

    .contentWrapper {
        padding: 24px 0 22px 24px;
        width: 240px;

        .productPage & {
            width: 100%;
            padding: 24px 0;
        }
    }

    .imageWrapper {
        width: 239px;

        .productPage & {
            width: 100%;
            height: 100%;
        }
    }

    .textWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .productPage & {
            display: grid;
            gap: 14px;

            p {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    .btn {
        .productPage & {
            width: 220px;
        }
    }
}

@media (max-width: 1023px) {
    .cardWrapper {
        flex-direction: column;
        height: 480px;
        gap: 0;

        &.productPage {
            gap: 14px;
            height: max-content;
        }
    }

    .imageWrapper {
        width: 100%;
        height: 217px;
        border-bottom: 1px solid var(--ds-border-gray);

        .productPage & {
            height: 360px;
        }
    }

    .contentWrapper {
        width: 100%;
        height: calc(100% - 217px);
        padding: 26px 24px 24px;

        .productPage & {
            height: auto;
            padding: 0;
        }
    }

    .textWrapper {
        gap: 9px;

        .productPage & {
            gap: 10px;
        }
    }

    .btn {
        .productPage & {
            margin-top: 14px;
        }
    }
}

@media (max-width: 767px) {
    .contentWrapper {
        padding: 24px 23px;

        &__withBigText {
            padding: 24px 16px;
        }
    }

    .imageWrapper {
        .productPage & {
            height: 209px;
        }
    }

    .textWrapper {
        gap: 11px;
        .productPage & {
            gap: 13px;

            p {
                font-size: 14px;
                line-height: 18px;
            }

            img {
                height: 40px;
            }
        }
    }

    .btn {
        .productPage & {
            width: 100%;
        }
    }
}
