.wrapper {
    .info {
        width: 611px;
        padding-bottom: 24px;
        border-bottom: 1px solid #d8e1eb;
    }

    .topline {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        margin: 24px 0 0;

        span {
            line-height: 20px;
            font-size: 40px;
            margin-right: 26px;
            font-weight: 100;
        }

        .title {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: #818181;
            letter-spacing: 0.4px;
        }

        .titleOpen {
            color: #18191b;
        }
    }

    .list {
        margin: 15px 0 0 26px;
    }

    .text {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        color: var(--colors-board-primary, #555555);

        p {
            padding: 1px 0;
        }
    }

    ul {
        margin-left: 18px;
        list-style-type: square;
    }

    li {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        color: var(--colors-board-primary, #555555);
        line-height: 30px;

        &::marker {
            color: #ff0508;
        }
    }

    .imageWrapper {
        position: absolute;
        left: 662px;
        top: 52px;
    }
}

@media (max-width: 1359px) {
    .wrapper {
        border-bottom: 1px solid #d8e1eb;
        padding-bottom: 7px;

        .info {
            width: 100%;
            border: none;
        }

        .imageWrapper {
            position: relative;
            left: auto;
            top: auto;
            padding-bottom: 20px;
            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 1023px) {
    .wrapper {
        .info {
            padding-bottom: 20px;
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        .topline {
            span {
                font-size: 30px;
                margin-right: 19px;
            }

            .title {
                font-size: 18px;
                line-height: 22px;
            }
        }

        .list {
            margin: 14px 0 0 16px;
        }

        .text {
            font-size: 14px;
            line-height: 18px;

            p {
                padding: 3px 0;
            }
        }

        .info {
            padding-bottom: 5px;
        }

        li {
            font-size: 14px;
            line-height: 18px;
            margin: 8px 0 11px;
        }

        .imageWrapper {
            display: none;
        }
    }
}
