@import '../../../../../shared/commonStyles/media-css-variables';

.sliderButton {
    position: absolute;
    bottom: 0;
    right: 0;

    & > button {
        width: 60px;
        height: 60px;

        & > div {
            width: 22px;
            height: 22px;

            & > div {
                width: 22px;
                height: 22px;

                &:before {
                    width: 22px !important;
                    height: 22px !important;
                }
            }
        }

        & svg {
            width: 22px;
            height: 22px;
        }
    }
}

.exampleSection {
    padding: 100px 62px 100px 62px;
    gap: 59px;

    & > h2 {
        font-size: 40px;
        font-weight: 700;
        line-height: 100%; /* 40px */
        text-transform: uppercase;
        width: 300px;
    }

    .exampleList {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;

        & > div:first-child {
            gap: 24px;
            display: flex;
        }

        .hideBorder > .example {
            border-bottom: none;
        }
    }

    .example {
        width: 100%;
        border-bottom: 1px solid var(--ds-border-gray);
        height: 100%;

        & > div:first-child {
            display: flex;
            margin-bottom: 16px;
            height: 20px;

            & > h6 {
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                text-transform: uppercase;
                margin-right: 12px;
            }

            & > span {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                padding: 2px 8px;
                color: var(--ds-main-white);
                background: var(--ds-bg-yellow);
                border-radius: 4px;
                min-width: 103px;

                &.gray {
                    background-color: var(--ds-bg-blue-grey);
                }
                &.blue {
                    background-color: var(--ds-bg-blue);
                }
                &.orange {
                    background-color: #ff8000;
                }
            }
        }

        & > p {
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 24px;
            margin-bottom: 24px;
            min-height: 72px;
        }

        .extraInfoButton {
            padding: 12px 24px;
            border-radius: 6px;
            border: 1px solid var(--ds-main-gray);
            margin-bottom: 40px;
            width: 135px;
            background-color: var(--ds-main-white);

            & > span {
                font-size: 16px;
                font-style: normal;
                font-weight: 350;
                line-height: 18px;
                color: var(--ds-main-black);
            }
        }
    }
}

@media (max-width: $lg) {
    .exampleSection {
        padding: 100px 24px 100px 24px;
        gap: 59px;

        & > h2 {
            font-size: 32px;
            width: 230px;
        }

        .exampleList {
            & > div:first-child {
                gap: 18px;
            }
        }

        .example {
            & > p {
                min-height: 120px;
            }

            & > div:first-child {
                flex-direction: column-reverse;
                height: auto;
                gap: 12px;

                & > span {
                    min-width: 68px;
                    width: fit-content;
                }
            }
        }
    }
}

@media (max-width: $md) {
    .exampleSection {
        overflow: hidden;
        padding-top: 80px;
        padding-bottom: 80px;

        .example {
            & > div:first-child {
                flex-direction: row;
            }
        }
    }
}

@media (max-width: $sm) {
    .exampleSection {
        padding: 60px 18px;
        gap: 36px;
        overflow: auto;

        & > h2 {
            font-size: 20px;
            width: 145px;
        }

        .exampleList > div:first-child {
            max-height: 680px;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 18px;
        }

        .example {
            min-width: 320px;

            & > div:first-child {
                flex-direction: column-reverse;
                height: auto;

                & > h6 {
                    font-size: 16px;
                }

                & > span {
                    min-width: 91px;
                    font-size: 12px;
                    line-height: 18px;
                    padding: 1px 8px;
                }
            }

            & > p {
                font-size: 14px;
                min-height: auto;
            }

            .extraInfoButton {
                padding: 7px 18px;
                margin-bottom: 24px;
                width: 110px;

                & > span {
                    font-size: 14px;
                }
            }
        }
    }

    .sliderButton {
        position: absolute;
        bottom: 0;
        right: 0;

        & > button {
            width: 40px;
            height: 40px;

            & > div {
                width: 14px;
                height: 14px;

                & > div {
                    width: 14px;
                    height: 14px;

                    &:before {
                        width: 14px !important;
                        height: 14px !important;
                    }
                }
            }

            & svg {
                width: 14px;
                height: 14px;
            }
        }
    }
}
