.capabilityItemWrapper {
    width: 100%;
    height: 360px;
    gap: 24px;

    & > div {
        width: calc(50% - 12px);
        padding-right: 24px;
        gap: 12px;

        & > h4 {
            width: 601px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 34px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: -0.01em;
            text-align: left;
            text-transform: uppercase;
            color: var(--ds-main-black);
        }

        & > p {
            width: 601px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-dark-gray);
        }

        & > ul {
            padding-left: 24px;
            list-style: none;

            li {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                text-align: left;
                color: var(--ds-main-dark-gray);
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: -24px;
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    background-color: var(--ds-main-red);
                }

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }

        &.rightPos {
            padding-left: 125px;
        }
    }

    & > img {
        width: calc(50% - 12px);
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
    }
}

@media (max-width: 1359px) {
    .capabilityItemWrapper {
        height: 260px;
        gap: 48px;

        & > div {
            width: calc(50% - 24px);
            padding: 0 48px 0 0;

            & > h4 {
                width: 100%;
                font-size: 28px;
                line-height: 32px;
            }

            & > p {
                width: 100%;
            }

            &.rightPos {
                padding: 0;
            }
        }

        & > img {
            width: calc(50% - 24px);
        }
    }
}

@media (max-width: 1023px) {
    .capabilityItemWrapper {
        height: auto;
        flex-direction: column !important;
        gap: 24px;

        & > div {
            width: 100%;
            padding: 0;
        }

        & > img {
            width: 100%;
            height: 425px;
        }
    }
}

@media (max-width: 767px) {
    .capabilityItemWrapper {
        & > div {
            & > h4 {
                font-size: 18px;
                line-height: 20px;
            }

            & > p {
                font-size: 14px;
                line-height: 18px;
            }

            & > ul {
                padding-left: 16px;

                li {
                    font-size: 14px;
                    line-height: 18px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 4px;
                        left: -16px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
            }
        }

        & > img {
            height: 191px;
        }
    }
}
