.pageWrapper {
    display: flex;
    flex-direction: column;
    background-image: url('../../shared/images/png/bg2.png');
    background-size: contain;
    background-repeat: no-repeat;

    .description {
        background-image: url('../../shared/images/png/RiskRatesBG.png');
        margin: 39px 19px 0 36px;
    }

    .info {
        display: flex;
        margin: 39px 19px 0 36px;
        padding: 75px 0 0;

        h1 {
            width: 663px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: 0.4px;
            padding-right: 5%;
        }

        &_block {
            width: 866px;
            p {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 18px;
                font-weight: 350;
                line-height: 30px;
                margin-bottom: 22px;
                letter-spacing: 0.25px;
            }

            ul {
                list-style-type: square;
                margin: 20px 0 0 24px;
            }

            li {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                letter-spacing: 0.15px;
                margin-bottom: 22px;

                &::marker {
                    color: var(--ds-main-red);
                }
            }
        }
    }

    .documentation {
        display: flex;
        justify-content: space-between;
        padding: 65px 0 0;
        margin: 0 19px 0 38px;
        max-width: 1528px;

        &_text {
            width: 611px;

            h1 {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 30px;
                font-weight: 400;
                line-height: 36px;
                letter-spacing: 0.3px;
                margin-bottom: 29px;
            }

            span {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 24px;
                letter-spacing: 0.15px;
            }

            .link {
                color: var(--ds-main-red);
            }
        }

        .blocks {
            width: calc(100% - 611px - 50px);
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 12px;

            .block {
                width: calc(50% - 6px);
                max-width: 427px;

                .blockSize {
                    height: 194px;
                }
            }
        }
    }

    .tariffs {
        display: flex;
        background: var(--ds-bg-extra-light-gray);
        margin: 120px 0 0 0;
        padding: 76px 0 65px 37px;

        h1 {
            margin-top: 3px;
            width: 624px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 34px;
            font-weight: 700;
            line-height: 40px;
        }

        .riskRates {
            width: 60%;
            padding: 0px 20px 0 38px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            .price {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .cost {
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            span {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
            }

            .link {
                color: var(--ds-main-red);
            }
        }
    }

    .gainAccess {
        padding: 4px 0;
        & > div:first-of-type {
            width: 611px;
            padding: 90px 35px 0;
            margin-right: 86px;
        }
    }
}

@media (max-width: 1359px) {
    .pageWrapper {
        .description {
            margin: 25px 10px 0 25px;

            li {
                width: 440px;
            }
        }

        .info {
            flex-direction: column;
            margin: 10px 20px 0 25px;

            h1 {
                width: 100%;
            }

            &_block {
                width: 100%;
                margin-top: 20px;
            }
        }

        .documentation {
            padding: 0;
            margin: 104px 0 0 25px;
            flex-direction: column;

            &_text {
                width: 100%;

                h1 {
                    margin-bottom: 18px;
                }
            }

            .blocks {
                width: auto;
                margin-top: 30px;
                justify-content: center;

                .block {
                    width: calc(50% - 6px);
                    max-width: 640px;
                }

                .allBlocksButton {
                    width: 100%;
                }
            }
        }

        .tariffs {
            flex-direction: column;
            margin: 90px 0 0 0;
            padding: 77px 22px 66px 24px;

            .riskRates {
                width: auto;
                padding: 52px 0 0 0;
                gap: 9px;

                h2 {
                    margin-bottom: 34px;
                }
            }
        }

        .gainAccess {
            padding: 10px 20px 0 0;
            & > div:first-of-type {
                width: 611px;
                padding: 67px 32px 0 25px;
                margin-right: 65px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .pageWrapper {
        .description {
            margin: 25px 24px 0 23px;
        }

        .documentation {
            margin: 35px 24px 0 25px;
        }

        .tariffs {
            padding: 77px 24px 75px 24px;

            .riskRates {
                padding: 30px 0 0 0;
            }
        }

        .gainAccess {
            padding: 10px 20px 0 0;
            & > div:first-of-type {
                width: auto;
                padding: 57px 25px 12px;
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .pageWrapper {
        .description {
            margin: 18px 17px 0 17px;

            li {
                width: 100%;
                margin: 12px 0;
            }
        }

        .info {
            margin: 0 17px;
            width: 100%;

            h1 {
                width: 90%;
                font-size: 28px;
                font-weight: 400;
                line-height: 32px;
                padding: 0;
            }

            &_block {
                margin-top: 15px;
                padding-right: 36px;

                p {
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 26px;
                    margin-bottom: 22px;
                }

                ul {
                    margin: 10px 0 0 17px;
                }

                li {
                    font-size: 14px;
                    font-weight: 350;
                    line-height: 20px;
                    margin-bottom: 10px;
                }
            }
        }

        .documentation {
            margin: 60px 17px 0 20px;
            &_text {
                h1 {
                    font-size: 28px;
                    font-weight: 400;
                    line-height: 36px;
                }

                span {
                    font-size: 14px;
                    font-weight: 350;
                    line-height: 18px;
                }
            }

            .blocks {
                margin-top: 10px;
                .block {
                    width: 100%;
                    max-width: 640px;

                    .blockSize {
                        height: 214px;
                    }
                }
            }
        }

        .tariffs {
            margin: 80px 0 0 0;
            padding: 47px 0 20px 17px;

            h1 {
                width: 100%;
                margin-top: 3px;
                font-size: 28px;
                font-weight: 700;
                line-height: 40px;
            }

            .riskRates {
                padding: 38px 0 0;
                gap: 6px;

                h2 {
                    margin-bottom: 15px;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 20px;
                }

                .price {
                    flex-direction: column;
                    gap: 11px;
                }
            }
        }

        .gainAccess {
            padding: 10px 17px 0 0;
            & > div:first-of-type {
                padding: 30px 27px 19px 19px;

                p {
                    width: 100%;
                }
            }
            & > div:nth-of-type(2) {
                padding: 0 0 41px 18px;
            }
        }
    }
}
