.interactiveContainer {
    width: 100%;
    padding: 44px 36px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 24px;
}

@media (max-width: 1024px) {
    .interactiveContainer {
        flex-direction: column;
        justify-content: start;
    }
}

@media (max-width: 769px) {
    .interactiveContainer {
        padding: 30px 24px;
    }
}

@media (max-width: 375px) {
    .interactiveContainer {
        padding: 20px 12px;
    }
}
