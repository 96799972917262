.instrumentsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 57px 60px;
}

@media (max-width: 1359px) {
    .instrumentsWrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 46px 48px;
    }
}

@media (max-width: 767px) {
    .instrumentsWrapper {
        grid-template-columns: repeat(1, 1fr);
        gap: 48px;
    }
}
