.tableHeader {
    border-color: transparent;
    background-color: var(--ds-main-white);
}

.tableHeaderRow > th {
    border-bottom: 1px solid #636e80 !important;

    & > div {
        display: flex;
        flex-direction: row;
        align-items: right;
        gap: 2px;

        & > span > svg {
            margin-top: 3px;
        }
    }
}

.tableColumn {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    padding: 0;
    & * {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        color: var(--ds-dtsip-text-black-100);
    }

    position: relative;
    border-bottom: none;
    height: 32px;
    text-wrap: nowrap;
    margin: 0;

    color: var(--ds-main-black);
    margin-right: 2px;

    & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        height: auto;
        width: 100%;
        padding: 0 18px 0 0px;
        display: flex;
        height: 100%;
        align-items: center;

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }

        &.center {
            justify-content: center;
        }

        border-right: 2px solid var(--ds-main-white);
    }
}

.tableHeader {
    th {
        padding-bottom: 6px;
        padding-right: 18px;
        background-color: transparent;
        margin-right: 2px !important;
        margin-bottom: 0;
        border-bottom: none;

        & > div > span {
            font-family: 'Favorit Pro', sans-serif;
            font-weight: 350;
            font-size: 14px;
            letter-spacing: 0;
            text-align: left;
            color: #8a96a6 !important;
        }

        &:first-child {
            width: 32px;
        }

        border-right: 2px solid var(--ds-main-white);
    }
}

.content {
    tr {
        position: relative;
        background-color: transparent;

        td:first-child {
            width: 32px;
            padding: 0 8px;
            height: 32px;
            border-bottom: none;
        }

        td:nth-child(2) span {
            font-weight: 300;
            font-family: 'Favorit Pro', sans-serif;
        }
    }

    tr:nth-child(2n + 1) > td {
        background-color: rgba(215, 230, 245, 0.2);
    }
}
