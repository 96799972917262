.productCapabilitiesWrapper {
    gap: 36px;
}

@media (max-width: 1359px) {
    .productCapabilitiesWrapper {
        gap: 60px;
    }
}

@media (max-width: 1023px) {
    .productCapabilitiesWrapper {
        gap: 48px;
    }
}

@media (max-width: 767px) {
    .productCapabilitiesWrapper {
        gap: 50px;
    }
}
