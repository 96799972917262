@import '../../../../../../node_modules/primeicons/primeicons.css';

.wrapper {
    height: 300px;
    background-image: url('../../../../../shared/images/helpcenter/png/main_image.png');
    padding: 120px 60px 40px 60px;
    background-size: cover;

    & > h1 {
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px; /* 80% */
        letter-spacing: -1px;
        margin-bottom: 36px;
    }

    .searchIcon {
        width: 33px;
        height: 100%;
        padding: 8px;
        margin-top: -22px;
        margin-right: -10px;
    }

    & input {
        border-radius: 4px;
        width: 480px;
        height: 42px;
        padding: 12px 30px 12px 12px;
        border: 1px solid var(--ds-border-dark-gray);
        background: rgba(255, 255, 255, 0.5);
    }

    & > span {
        position: relative;

        & > div {
            position: absolute;
            width: 480px;
            top: 44px;
            z-index: 1000;
        }
    }
}

@media (max-width: 1360px) {
    .wrapper {
        padding: 105px 24px 36px 24px;
        height: 240px;
        background-image: url('../../../../../shared/images/helpcenter/png/main_image_1360px.png');

        & > h1 {
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -1px;
            margin-bottom: 18px;
        }
    }
}

@media (max-width: 1024px) {
    .wrapper {
        background-image: url('../../../../../shared/images/helpcenter/png/main_image_1024px.png');

        & input {
            width: 420px;
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        background-image: url('../../../../../shared/images/helpcenter/png/main_image_768px.png');
        padding: 101px 18px 30px 18px;
        height: 216px;

        & > h1 {
            font-size: 30px;
            line-height: 30px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: -0.5px;
            margin-bottom: 13px;
        }

        & input {
            width: 320px;
        }

        & > span {
            & > div {
                width: 320px;
            }
        }
    }
}
