.onboardingModalWrapper {
    width: 480px;
    padding: 40px 48px 48px;

    & > h2 {
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-transform: uppercase;
    }

    & > p {
        margin-bottom: 23px;
        font-size: 15px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
    }

    & > button {
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: 1px solid var(--ds-main-gray);
        border-radius: 6px;
        background: var(--ds-main-white);

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-black);
        }
    }
}

.pointsWrapper {
    gap: 6px;
    margin-bottom: 36px;
}

.point {
    gap: 8px;

    & > svg {
        flex-shrink: 0;
    }

    & > span {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        &.notConfirmed {
            color: var(--ds-main-blue);
            text-decoration: underline;
            text-decoration-skip-ink: none;
            text-underline-offset: 2px;
            cursor: pointer;
        }

        &.hasEmail {
            color: var(--ds-main-blue);
            text-decoration: underline dashed;
            text-decoration-skip-ink: none;
            text-underline-offset: 2px;
            cursor: pointer;
        }

        &.notHasEmail {
            color: var(--ds-main-gray);
            text-decoration: underline dashed;
            text-decoration-skip-ink: none;
            text-underline-offset: 2px;
        }
    }
}

.tooltipWrapper {
    box-shadow: 0 4px 12px 0 #3c5f8a33;
}

.tooltipText {
    top: 0;
}

.tooltipArrow {
    bottom: -4px !important;
}

.tooltip {
    width: 320px;
    padding: 8px 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

@media screen and (max-width: 360px) {
    .onboardingModalWrapper {
        width: 350px;
    }
}
