.custom-volat-surface-chart {
    position: relative;
    margin-top: 40px;
    margin-bottom: 80px;
    .modebar {
        opacity: 1;
        visibility: hidden;
    }

    #scene {
        .nums {
            background: white;

            .line {
                fill: #879199;
                font-family: 'Inter';
                font-weight: 400;

                & > text {
                    font-size: 14px;
                }
            }
        }
    }

    .infolayer {
        transform: translateX(-45px);

        .cbfills rect {
            rx: 4px;
            ry: 4px;
        }

        .cbtitleunshift {
            transform: translate(-50px, -12px);

            text {
                font-weight: bold !important;
            }
        }

        .cbaxis.crisp {
            transform: translate(6px, -10px);

            & text {
                text-align: right;
            }
        }
    }

    &__volatColorbar {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 90px;
        height: 178px;
        border: 1px solid #d8dfe6;
        border-radius: 6px;
        z-index: 0;
        background: transparent;
    }

    @media (max-width: 1599px) {
        width: 976px;
        left: -319px;
        #scene {
            margin-left: 250px;
        }
    }

    @media (max-width: 1502px) {
        &__volatColorbar {
            bottom: 3px;
        }
    }

    @media (max-width: 1023px) {
        width: 720px;
        left: 24px;
        #scene {
            margin-left: 150px;
        }
    }

    @media (max-width: 767px) {
        margin-top: 30px;
        margin-bottom: 141px;
        width: 324px;
        left: 18px;

        .js-plotly-plot {
            overflow: visible;
            width: 100%;

            .plot-container {
                width: 100%;

                & svg {
                    overflow: visible;
                }

                .svg-container {
                    width: 100% !important;
                }

                .gl-container {
                    width: 100%;
                    height: 100%;
                }

                canvas {
                    width: 324px !important;
                }
            }
        }

        .infolayer {
            transform: translate(-45px, 114px);
        }

        #scene {
            position: relative;
            margin-left: 0px;
            left: 50% !important;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            width: 324px !important;
        }

        .main-svg {
            overflow-y: visible;
        }

        &__volatColorbar {
            height: 158px;
            bottom: -114px;
        }
    }
}
