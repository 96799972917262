.blockDivider {
    width: 100%;
    border-bottom: 1px solid var(--ds-border-gray);
}

.blockTitle {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;

    & > i {
        color: var(--ds-main-gray);
    }

    & > p {
        color: var(--ds-main-black);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -1%;
        text-transform: uppercase;
    }
}

.p-accordion-header {
    z-index: -1;
}

.blockParamSearch {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
}

.blockParamSearchInput {
    width: 190px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    position: relative;

    & > div {
        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & [class~='p-multiselect-label'] {
        padding: 8px 18px;
        color: var(--ds-text-gray-incative);
    }

    & [class~='p-multiselect-trigger'] {
        width: 12px;
        margin-right: 13px;
    }

    & [class~='p-multiselect-panel'] {
        width: 100% !important;
    }

    & [class~='p-dropdown-panel'] {
        width: 100% !important;
    }
}

.dropdownGroup {
    font-weight: 700;
    color: var(--ds-main-black);
    flex-direction: column;
    flex: 1;
}

.dropdownGroupItem {
    font-size: 14px;
}

.dropdownItem {
    padding-left: 4px;
    font-weight: 300;
    font-size: 14px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--ds-border-gray);
}

.profitParam {
    padding-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;

    & > div {
        & > i {
            font-size: 12px;
        }

        & > input {
            padding: 8px;
            width: 118px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);
        }
    }
}

.inputLabel {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    color: var(--ds-text-black);
}

.dropdownInput {
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-text-gray-incative);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > [class~='p-dropdown-label'] {
        color: var(--ds-text-gray-incative) !important;
    }
}

.calendarInput {
    height: 36px;
    width: 100%;
    border-radius: 6px;
    border-right: none !important;

    & > input {
        border-right: none !important;

        min-width: 4rem;
        padding: 6px 12px 6px 14px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 16px;
    }

    & > button {
        border-left: none !important;
        background-color: var(--ds-main-white);
        color: var(--ds-main-blue);
        padding: 8px 0 !important;
        width: 36px;
    }
}

.listDownloadButton {
    width: 185px;
    background-color: var(--ds-main-white);
    color: var(--ds-main-black);
    padding: 6px 20px 6px 16px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span:first-child {
        padding: 0 5px;
        color: var(--ds-main-blue);
    }

    & > span:last-child {
        padding: 0 5px;
        font-size: 13px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        color: var(--ds-main-black);
    }
}

.extendedSearchLabel {
    cursor: pointer;
    background: white;
    border: 0;
    border-bottom: 1px dashed var(--ds-bg-dark-blue);
    padding: 0;
    display: flex;
    position: relative;
    color: var(--ds-bg-dark-blue);
    border-radius: 0px;
    height: 16px;

    & span {
        align-self: start;
        font-size: 14px;
        font-weight: 350;
        line-height: 16px;
        color: var(--ds-bg-dark-blue);
    }
}
.extendedSeachButtonWrapper {
    display: flex;
    gap: 2px;
    align-items: center;
}

.autoCompleteInput {
    width: 443px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > input {
        padding: 6px 12px 6px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        border: none;
    }

    & > button {
        border: none;
        background-color: var(--ds-main-white);
        color: var(--ds-main-light-gray);
    }
}

.profitInput {
    height: 36px;
    border-radius: 6px;
    color: var(--ds-main-gray);
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;

    & input {
        width: 118px;
        padding: 8px 18px;

        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.calendarContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.accordionHeader {
    display: flex;
    align-items: center;

    & label {
        margin-left: 8px;
        text-transform: uppercase;
        color: var(--ds-text-black);
    }
}

.isinInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
}

.selectContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.calendarInput {
    height: 36px;
    width: 100%;
    border-radius: 6px;

    & > input {
        min-width: 4rem;
        padding: 6px 12px 6px 14px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 16px;
    }

    & > button {
        border-left: none;
        background-color: var(--ds-main-white);
        color: var(--ds-main-blue);
        padding: 8px 0 !important;
        width: 36px;
    }

    & [class~='p-datepicker'] {
        width: 375px !important;
    }

    & [class~='p-icon'] {
        padding: 2px;
        width: 24px !important;
        height: 24px !important;
    }

    & td {
        padding: 0;
    }
}

.isinMultiselectHeader {
    padding: 8px 8px;
    display: flex;
    justify-content: space-between;
    gap: 4px;

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > input {
        &:hover {
            border-color: var(--ds-bg-dark-blue) !important;
        }
    }

    & > div {
        display: flex;
        gap: 4px;
        align-items: center;

        & > label {
            color: var(--ds-main-gray);
        }
    }
}

.rootCheckbox:not([data-disabled='true']) .multiSelectHeaderCheckbox:hover,
.rootCheckbox:not([data-disabled='true']):has(input:hover) .multiSelectHeaderCheckbox {
    border-color: var(--ds-bg-dark-blue) !important;
}

.multiSelectHeaderCheckbox[data-p-highlight='true'] {
    border-color: var(--ds-bg-dark-blue);
    background: var(--ds-bg-dark-blue);
}

.isInMultiSelectFilterInput {
    & input {
        height: 36px;

        color: var(--ds-main-gray);
        padding: 4px 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & svg {
        transform: translate(50%, -50%) !important;
        margin-right: 8px;
    }
}

.isinBlockParamSearchInput {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    position: relative;

    & > div {
        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & [class~='p-multiselect-label'] {
        padding: 8px 18px;
        color: var(--ds-text-gray-incative);
    }

    & [class~='p-multiselect-trigger'] {
        width: 12px;
        margin-right: 13px;
    }
}

.multiSelectCloseButton {
    display: none !important;
}

.pinButtonCount {
    margin-left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #ff5b01;
    color: var(--ds-main-white);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}
