.itemWrapper {
    gap: 18px;

    & > img {
        width: 60px;
        height: 60px;
    }

    & > div {
        padding-top: 16px;
        gap: 10px;

        & > h4 {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-black);
        }

        & > p {
            max-width: 523px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-dark-gray);
        }
    }
}

@media (max-width: 1023px) {
    .itemWrapper {
        flex-direction: column;

        & > div {
            padding-top: 0;

            & > p {
                max-width: auto;
            }
        }
    }
}

@media (max-width: 767px) {
    .itemWrapper {
        gap: 16px;

        & > div {
            gap: 8px;

            & > h4 {
                font-size: 18px;
                line-height: 20px;
            }

            & > p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
