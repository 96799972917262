.imageWrapper {
    width: 100%;
    height: 274px;
    background-image: url('../../../../../shared/images/personalAccount/png/main_image_1920.png');
    background-size: cover;
}

@media (max-width: 1359px) {
    .imageWrapper {
        background-image: url('../../../../../shared/images/personalAccount/png/main_image_1359.png');
    }
}

@media (max-width: 1023px) {
    .imageWrapper {
        background-image: url('../../../../../shared/images/personalAccount/png/main_image_1023.png');
    }
}

@media (max-width: 767px) {
    .imageWrapper {
        height: 210px;
        background-image: url('../../../../../shared/images/personalAccount/png/main_image_767.png');
    }
}
