.block {
    padding: 24px;
    border: 1px solid #d8e1eb;
    border-radius: 12px;
    width: 100%;
    height: 272px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .texts {
        display: flex;
        flex-direction: column;
    }

    .update {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 24px;
        color: #8a96a6;
    }

    .text {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        color: #18191b;
        letter-spacing: 0.2px;
        width: 88%;
    }

    .title {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
    }

    .note {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 350;
        color: #8a96a6;
    }

    .links {
        display: flex;
        justify-content: center;
        gap: 4px;

        a {
            cursor: pointer;
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            color: #14171f;
            border: 1px solid #d8dfe6;
            border-radius: 6px;
            padding: 6px 0;
            width: 40%;
            max-width: 187.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 187.5px;
            text-decoration: none;
        }

        .specialA {
            width: 100%;
            max-width: none;
        }
    }

    .downloads {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 11px;
        margin-top: 32px;

        a {
            font-size: 14px;
            color: var(--ds-bg-dark-blue);
            border-bottom: 1px solid var(--ds-bg-dark-blue);
            padding-bottom: 1px;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

@media (max-width: 1359px) {
    .block {
        height: 252px;

        .links {
            a {
                width: 50%;
                max-width: none;
            }
        }
    }
}

@media (max-width: 1023px) {
    .block {
        height: 292px;

        .links {
            a {
                padding: 6px 0px;
            }
        }
    }
}

@media (max-width: 767px) {
    .block {
        width: 100%;
        height: 302px;

        .update {
            font-size: 13px;
            line-height: 22px;
        }

        .text {
            width: 100%;
            font-size: 15px;
            line-height: 22px;
        }

        .title {
            font-size: 15px;
            line-height: 22px;
        }

        .note {
            font-size: 13px;
            line-height: 22px;
        }

        .links {
            flex-direction: column;
            align-items: center;

            a {
                width: 100%;
                max-width: none;
            }
        }
    }
}
