.p-dropdown-label {
  display: flex;
  padding: 11px 12px 11px 18px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Main-LightGrey, #d8e1eb);
  background: var(--Main-White, #fff);
  color: var(--Main-Black, #18191b);
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  width: 100%;
}

.p-float-label {
  label {
    left: 0;
    top: -1.4rem;
    color: var(--Main-Black, #18191b);
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    padding-bottom: 2px;
  }
}

.p-dropdown-label {
  color: var(--Main-Black, #18191b);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  border: none;
}

.p-select-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.p-dropdown-item {
  color: var(--Main-Black, #18191b);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  padding: 5px;
}

.p-dropdown-panel {
  padding: 5px;
}

.p-dropdown-trigger {
  width: 12px;
  margin-right: 13px;
}
