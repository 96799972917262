.searchWrapper {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    min-width: 250px;
    padding: 0 62px;
    background: var(--ds-main-white);
    min-height: 100px;
    z-index: 1001;

    & > div {
        width: 100%;
        border-top: 1px solid var(--ds-border-gray);
        padding: 24px 0 34px;

        & > div {
            width: 788px;
        }
    }
}

.inputWrapper {
    & > span {
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--ds-main-gray);
    }

    input {
        padding-right: 40px;

        &:is(span, input) {
            width: 788px;
            height: 42px;
        }

        &,
        &:hover,
        &:focus {
            border: 1px solid var(--ds-border-gray);
            font-size: 15px;
            line-height: 20px;
            font-weight: 300;
            color: var(--ds-main-black);
            box-shadow: none;
            padding-left: 18px;
        }

        &::placeholder {
            color: var(--ds-main-gray);
        }
    }
}

.bgWrapper {
    position: fixed;
    top: 136px;
    left: 0;
    opacity: 0.5;
    background: var(--ds-main-black);
    width: 100%;
    height: 100vh;
    z-index: 1000;
}

@media (min-width: 1933px) {
    .searchWrapper {
        border-radius: 0 0 6px 6px;
    }
}

@media (max-width: 1359px) {
    .searchWrapper {
        top: 60px;
        padding: 0 24px;

        & > div {
            border: none;
        }
    }

    .bgWrapper {
        top: 160px;
    }
}

@media (max-width: 1023px) {
    .searchWrapper {
        & > div {
            & > div {
                width: 100%;
            }
        }
    }

    .inputWrapper {
        input {
            &:is(span, input) {
                width: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    .searchWrapper {
        padding: 0 18px;
    }
}
