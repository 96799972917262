.opportunitiesWrapper {
    width: 100%;
    height: 540px;
    padding: 100px 62px 80px;
    gap: 24px;

    & > div {
        min-width: 50%;
    }

    h1 {
        width: 539px;
        height: 76px;
        font-size: 48px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 31px;
    }

    img {
        width: inherit;
    }

    .contentWrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            margin-bottom: 34px;
        }

        a {
            display: flex;
            width: fit-content;
            align-items: center;
            padding: 12px 24px;
            background-color: var(--ds-main-red);
            border-radius: 6px;
            border: none;
            height: 42px;
            text-decoration: none;

            & > span {
                text-decoration: none;
                font-size: 16px;
                font-style: normal;
                font-weight: 350;
                line-height: 18px;
                color: var(--ds-main-white);
            }
        }
    }
}

.navigationBlockWrapper {
    height: 33px;
    min-height: 33px;
    background: var(--ds-main-white);
    border-bottom: 1px solid var(--ds-border-gray);
    margin-bottom: 31px;

    & > div {
        gap: 18px;
    }

    span {
        cursor: pointer;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-gray);

        &.selected {
            padding: 0 0 8px 0;
            text-decoration: none;
            border-bottom: 2px solid var(--ds-main-red);
            color: var(--ds-main-black);
        }
    }
}

@media (max-width: 1359px) {
    .opportunitiesWrapper {
        height: 866px;
        padding: 80px 24px 60px;

        h1 {
            width: 449px;
            height: 70px;
            font-size: 40px;
            margin-bottom: 43px;
        }

        img {
            height: 360px;
            margin-bottom: 32px;
        }

        & > div {
            width: 100%;
        }
    }
}

@media (max-width: 1023px) {
    .opportunitiesWrapper {
        height: 786px;

        img {
            height: 270px;
            margin-bottom: 32px;
        }

        & > div {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .opportunitiesWrapper {
        height: 609px;
        padding: 80px 18px 40px;

        h1 {
            width: 270px;
            height: 43px;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 23px;
        }

        img {
            height: 180px;
        }

        .contentWrapper {
            p {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 20px;
            }

            a {
                padding: 9px 18px;
                height: 36px;
            }
        }
    }

    .navigationBlockWrapper {
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 30px;

        div {
            position: relative;
            width: max-content;
            gap: 12px;
        }
    }
}
