.cardWrapper {
    gap: 4px;
}

.cardInfoWrapper {
    gap: 18px;

    & > img:first-child {
        width: 72px;
        height: 100%;
    }

    & > div {
        height: 100%;
        max-width: 150px;
        min-width: 66px;
        gap: 6px;
        padding: 4px 0;

        & > span:first-child {
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 18px;
            color: var(--ds-main-gray);
        }

        & > span:last-child {
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.menuBtn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: transparent;
    border: none;
}

.menuPanel {
    padding: 18px 36px 18px 18px;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    button {
        background: transparent;
        border: none;
        gap: 8px;

        span {
            padding-top: 6px;
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
        }

        &.defaultBtn {
            span {
                color: var(--ds-main-blue);
            }
        }

        &.deleteBtn {
            span {
                color: var(--ds-main-gray);
            }
        }
    }
}

.defaultCartDescription {
    background: var(--ds-bg-pale-blue);
    width: fit-content;
    padding: 5px 8px;
    border-radius: 4px;

    font-family: 'Favorit Pro', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: var(--ds-main-gray);
}

.notificationModalWrapper {
    width: 480px;
    padding: 48px;

    & > h2 {
        margin-bottom: 19px;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-transform: uppercase;
    }

    & > p {
        margin-bottom: 32px;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }

    & > button {
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-black);

        & > span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 1023px) {
}

@media (max-width: 767px) {
    .cardInfoWrapper {
        margin-right: 0;

        & > *:last-child {
            margin-right: 0;
        }

        & > img:first-child {
            width: 54px;
            height: 36px;
        }

        & > div {
            & > span:first-child {
                font-size: 12px;
                font-weight: 350;
                line-height: 18px;
            }

            & > span:last-child {
                font-size: 14px;
                font-weight: 350;
                line-height: 18px;
            }
        }
    }

    .menuBtn {
        margin-left: auto;
    }

    .notificationModalWrapper {
        width: 324px;
        padding: 32px 18px;
    }
}
