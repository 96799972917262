.pageBg {
  &:before {
    content: "";
    width: 100%;
    min-width: 100%;
    height: calc(100% - 250px);
    position: absolute;
    z-index: -1;

    background-image: url("../../shared/images/png/ScreenersPageBg.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  & > button {
    margin: auto 0 -44px auto;
    padding: 10px;
    z-index: 1;
    background-color: var(--ds-main-blue);
  }
}

.pageTitle {
  padding: 42px 35px 16px;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.screenerLogo {
  margin-right: 20px;
}

.tabView {
  margin: 0 32px 0;

  & > div, & > div ul {
    background: transparent;
  }

  & > div a {
    padding: 14px;
    border: none;
    background: transparent;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }

  & > div li:last-child {
    background-color: var(--ds-main-red);
  }

  & th {
    padding: 35px 0 10px 20px;

    font-size: 14px;
    font-weight: 350;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    background-color: transparent;

    &:last-child {
      padding: 35px 12px 12px;
    }

    & span:last-child {
      margin-left: auto;
    }
  }
}
