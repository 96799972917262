.table {
    width: 100%;
    overflow-x: auto;

    & [class*='p-datatable-wrapper'] {
        width: 100%;
        overflow-x: auto;
    }

    & th {
        padding: 0px 20px;
        background-color: var(--ds-main-white);

        & div {
            display: flex;
            gap: 4px;
            justify-content: space-between;
        }
    }

    & [class*='p-datatable-tbody'] {
        & tr:nth-child(odd) {
            background-color: #d8e1eb;
        }

        & tr:nth-child(even) {
            background-color: var(--ds-main-white);
        }

        & tr > td:nth-child(2) {
            min-width: 166px;
        }
        & tr > td:nth-child(3) {
            min-width: 116px;
        }
        & tr > td:nth-child(4) {
            padding: 0;
            text-align: center;
            min-width: 106px;
        }
    }

    & [class*='p-column-title'] {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 16px;
        word-wrap: break-word;
        color: #8a96a6;
    }
}

.customPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    span {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            background: rgba(216, 225, 235, 0.2);
            border: 1px solid #d8e1eb;
            width: 36px;
            height: 36px;
            padding: 0;
            margin: 0;
            border-radius: 4px;
            min-width: 36px;
            max-width: 36px;
        }
        button {
            min-width: 36px;
            max-width: 36px;
            height: 36px;
            background: rgba(216, 225, 235, 0.2);
            border: 1px solid #d8e1eb;
            border-radius: 4px;
            padding: 0;
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
    }

    button {
        height: 36px;
        text-align: center;
        background: rgba(216, 225, 235, 0.2);
        border: 1px solid #d8e1eb;
        border-radius: 4px;
        padding: 0;
        margin: 0;

        span {
            color: rgba(138, 150, 166, 1);
            text-align: center;
            padding: 6px 20px;
        }
    }

    .currentPage {
        background: rgba(99, 110, 128, 1);
        color: rgba(255, 255, 255, 1);
        border: 1px solid transparent;
    }
}
