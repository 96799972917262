.feedback {
    display: inline-block;
    position: sticky;
    float: right;
    right: 96px;
    bottom: 24px;
    margin-bottom: 24px;
    box-shadow: 3px 6px 0 0 rgba(60, 95, 138, 0.1);
    border-radius: 6px;

    &.absolute {
        margin-top: -72px;
        bottom: 72px;
        height: 0;
    }

    button {
        padding: 12px 24px 12px 24px;
        background: var(--ds-main-black);
        border: none;
        border-radius: 6px;
    }
}

.buttonContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    svg {
        position: relative;
        top: 2px;
    }

    p {
        position: relative;
        top: 2px;
        color: var(--ds-main-white);
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
}

@media (max-width: 1359px) {
    .feedback {
        right: 48px;
    }
}

@media (max-width: 1023px) {
    .feedback {
        right: 24px;
        bottom: 21px;

        button {
            padding: 16px 30px 16px 20px;
        }
    }
}
