.inputLabel {
    color: var(--ds-dtsip-text-black-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    margin-left: 8px;
}

.dropdownInput {
    width: 316px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-text-gray-incative);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.multiSelectListItem,
.p-disabled {
    opacity: 1;
}

.blockParamSearchInput {
    width: 316px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    position: relative;

    & > div {
        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & [class~='p-multiselect-label'] {
        padding: 8px 18px;
        color: var(--ds-text-gray-incative);
    }

    & [class~='p-multiselect-trigger'] {
        width: 12px;
        margin-right: 13px;
    }

    & [class~='p-multiselect-panel'] {
        width: 100% !important;
    }

    & [class~='p-dropdown-panel'] {
        width: 100% !important;
        padding: 4px 0px 4px 4px;
    }
}

.dateRangeBlock {
    width: 316px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    & > i {
        font-size: 8px;
    }
}

.calendarInput {
    height: 36px;
    width: 100%;
    border-radius: 6px;

    & > input {
        border-right: none !important;
        min-width: 4rem;
        padding: 6px 0px 6px 12px !important;

        font-size: 14px;
        font-style: normal;
        font-weight: 350 !important;
        line-height: 16px;
    }

    & > button {
        border-left: none !important;
        background-color: var(--ds-main-white);
        color: var(--ds-main-blue);
        padding: 8px 0 !important;
        width: 36px;
    }

    & [class~='p-datepicker'] {
        width: 375px !important;
        transform: translate(-50%, 0%) !important;
    }

    & [class~='p-icon'] {
        padding: 2px;
        width: 24px !important;
        height: 24px !important;
    }

    & td {
        padding: 0;
        width: fit-content;
        height: fit-content;
        & span {
            height: 36px;
            width: 36px;
        }
    }
}

.inputBoundary {
    input {
        width: 58px;
        height: 36px;
        border-radius: 6px;
        padding: 8px 4px;

        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        text-align: center;
    }
}

.selectsContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.datesContainer {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.numbersContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.radioButtonsSection {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.radioButtonGroupContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.radioButtonGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.radioButtonInput {
    display: flex;
}

.checkBox {
    width: 18px;
    height: 18px;

    & > [class~='p-radiobutton-icon'] {
        height: 8px;
    }
}

.checkBoxInput {
    width: 18px;
    height: 18px;
}

.radioButton {
    display: flex;
    align-items: center;

    & > div {
        width: 18px;
        height: 18px;
        border-radius: 50%;
    }

    & > [data-p-checked='true'] > .checkBox {
        border-radius: 50%;
        background: var(--ds-bg-dark-blue);
        box-sizing: border-box;
        width: 18px;
        height: 18px;
        border: 5px solid var(--ds-bg-dark-blue);
    }

    &:not(.p-disabled):has(.checkBoxInput:hover) > [data-p-checked='false'] > .checkBox {
        border-color: var(--ds-bg-dark-blue);
        border-radius: 50%;
    }
}

.selectWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.numbersInpustWrapper {
    gap: 2px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.numbersControllersWrapper {
    width: 146px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.blockDivider {
    width: 100%;
    border-bottom: 1px solid var(--ds-border-gray);
}

.multiSelectHeader {
    padding: 8px 8px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > div {
        display: flex;
        gap: 4px;
        align-items: center;

        & > label {
            color: var(--ds-main-gray);
        }
    }
}

.multiSelectCloseButton {
    display: none !important;
}

.multiSelectFilterInput {
    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.multiSelectList {
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.multiSelectListItem {
    display: flex;
    padding: 5px;

    & > div {
        display: none;
    }

    & > span {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .calendarInput {
        & > input {
            font-size: 14px !important;
        }
    }
}

@media screen and (max-width: 575px) {
    .blockParamSearchInput {
        width: 100%;
    }
    .dateRangeBlock {
        width: 100%;
    }

    .selectWrapper {
        flex-direction: column;
        gap: 4px;
        margin-bottom: 8px;
        align-items: flex-start;
    }

    .datesContainer {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    .radioButtonGroupContainer {
        flex-direction: column;
    }
    .dropdownInput {
        width: 100%;
    }
    .calendarInput {
        width: 100%;

        & [class~='p-datepicker'] {
            width: 355px !important;
            transform: translate(-7%, 0%) !important;
            z-index: 10000 !important;
        }
    }
}

.rootCheckbox:not([data-disabled='true']) .multiSelectHeaderCheckbox:hover,
.rootCheckbox:not([data-disabled='true']):has(input:hover) .multiSelectHeaderCheckbox {
    border-color: var(--ds-bg-dark-blue) !important;
}

.multiSelectHeaderCheckbox[data-p-highlight='true'] {
    border-color: var(--ds-bg-dark-blue);
    background: var(--ds-bg-dark-blue);
}

@media screen and (max-width: 475px) {
    .radioButton {
        gap: 4px;
    }
    .dropdownInput {
        max-width: 100%;
        width: 100%;

        & > span {
            font-size: 13px;
        }

        & [class~='p-dropdown-trigger'] {
            width: 18px;
        }
    }
    .blockParamSearchInput {
        & [class~='p-multiselect-trigger'] {
            width: 18px;
        }

        & > span {
            font-size: 13px;
        }
    }

    .numbersControllersWrapper {
        width: 140px;
        gap: 0;
    }

    .inputLabel {
        margin-left: 0;
        text-align: left;
    }
}

@media screen and (max-width: 375px) {
    .numbersInpustWrapper {
        flex-direction: row;
        align-items: flex-start;
    }
    .calendarInput {
        & > input {
            font-size: 13px !important;
        }
        & [class~='p-datepicker'] {
            width: 320px !important;
            transform: translate(-2%, 0%) !important;
        }
    }
}

@media screen and (max-width: 340px) {
    .numbersInpustWrapper {
        flex-direction: column;
        align-items: flex-start;
    }
}
