.imgArticleBlockWrapper {
    width: 973px;
    height: fit-content;
    gap: 56px 37px;

    & > div {
        width: calc(50% - 18.5px);

        &:nth-child(5n) {
            width: 100%;
        }
    }
}

@media (max-width: 1359px) {
    .imgArticleBlockWrapper {
        width: 642px;
        gap: 48px 26px;

        & > div {
            width: calc(50% - 13px);
        }
    }
}

@media (max-width: 1023px) {
    .imgArticleBlockWrapper {
        width: 346px;
        gap: 48px;

        & > div {
            width: 100%;
        }
    }
}
