.notificationWrapper {
    width: 372px;
    overflow-y: auto;
    scrollbar-color: var(--ds-main-dark-gray);

    ::-webkit-scrollbar {
        width: 7px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--ds-main-light-gray);
        border-radius: 100px;
    }
}

body:has(.notificationWrapper) {
    overflow: hidden !important;
}

.maskWrapper {
    top: 136px !important;
    height: calc(100% - 136px) !important;
}

.loadMoreIcon {
    color: var(--ds-main-dark-gray);
}

.loadMoreButton {
    width: 100%;
    padding: 9px 18px;
    background-color: var(--ds-main-light-gray);
    border-color: var(--ds-main-light-gray);
}

.footer {
    background-color: var(--ds-main-white);
    padding: 16px 20px 16px 36px;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 112px;
}

.contentWrapper {
    height: 100%;
    overflow-y: auto;
}

@media (max-width: 1359px) {
    .maskWrapper {
        top: 96px !important;
    }
}

@media (max-width: 1023px) {
    .notificationWrapper {
        width: 360px;
    }

    .maskWrapper {
        top: 96px !important;
    }
}
