.gainAccessWrapper {
    & > .titleContainer {
        padding: 88px 60px 0 62px;

        & > h2 {
            width: 360px;
            margin-bottom: 6px;

            font-size: 34px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: -1%;
            text-transform: uppercase;
            text-align: left;
            color: var(--ds-main-black);
        }

        & > p {
            width: 452px;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
            color: var(--ds-main-dark-gray);
        }

        & > span {
            width: 452px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-main-black);
            text-align: left;
        }
    }

    & > .formWrapper {
        flex: 1;
        padding: 60px 0 120px;
    }
}

@media (max-width: 1359px) {
    .gainAccessWrapper {
        & > .titleContainer {
            padding: 65px 60px 0 48px;

            & > h2 {
                width: 159px;
                margin-bottom: 9px;

                font-size: 28px;
                line-height: 30px;
            }

            & > p,
            & > span {
                width: 269px;
            }
        }

        & > .formWrapper {
            padding: 60px 0 113px;
        }
    }
}

@media (max-width: 1023px) {
    .gainAccessWrapper {
        flex-direction: column;

        & > .titleContainer {
            padding: 65px 0 26px 24px;

            & > h2 {
                width: 284px;
            }

            & > p,
            & > span {
                width: 569px;
            }
        }

        & > .formWrapper {
            padding: 0 0 80px 24px;
        }
    }
}

@media (max-width: 767px) {
    .gainAccessWrapper {
        & > .titleContainer {
            padding: 38px 18px 33px;

            & > h2 {
                width: 284px;
            }

            & > p,
            & > span {
                width: 324px;
                font-size: 14px;
            }
        }

        & > .formWrapper {
            padding: 0 18px 41px;
        }
    }
}
