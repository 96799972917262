.pageWrapper {
    display: flex;
    flex-direction: column;
    background-image: url('../../shared/images/png/bg2.png');
    background-size: contain;
    background-repeat: no-repeat;

    .description {
        background-image: url('../../shared/images/png/MarkersBG.png');
        margin: 39px 19px 0 36px;
    }

    .items {
        position: relative;
        padding: 52px 0 60px;
        margin: 39px 19px 0 36px;
    }

    .formats {
        display: flex;
        justify-content: space-between;
        padding: 70px 0 0;
        margin: 0 19px 0 36px;
        max-width: 1528px;
        &_left {
            width: 611px;
        }

        &_title {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: 0.3px;
        }

        .texts {
            margin: 23px 0 30px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            p {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                color: var(--ds-main-black);
                letter-spacing: 0.15px;
            }
        }

        .links {
            display: flex;
            flex-wrap: wrap;
            gap: 36px 91px;

            a {
                width: 240px;
                font-family: 'Favorit Pro', sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 12px;
                letter-spacing: 0.5px;
                color: var(--ds-bg-dark-blue);
                text-decoration: underline;
                white-space: nowrap;
                cursor: pointer;
            }
        }

        .info {
            margin-top: 43px;
            p {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 24px;
                color: var(--ds-text-gray-incative);
                letter-spacing: 0.2px;
                .link {
                    color: var(--ds-main-red);
                }
            }
        }

        .blocks {
            width: calc(100% - 611px - 50px);
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: -13px;

            .block {
                width: calc(50% - 6px);
                max-width: 427px;
            }

            .allBlocksButton {
                margin-top: 12px;
                width: 100%;
                height: 36px;
                padding: auto;
                border-radius: 6px;
                background: var(--ds-bg-dark-blue);
                font-family: 'Inter', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-white);
                border: none;
                cursor: pointer;
            }
        }
    }

    .tariffs {
        display: flex;
        background: var(--ds-bg-extra-light-gray);
        margin: 120px 0 0 0;
        padding: 81px 0 78px 37px;
        h1 {
            width: 624px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 34px;
            font-weight: 700;
            line-height: 40px;
        }
        .markings {
            width: 900px;
            padding: 0 0 0 37px;
            h2 {
                margin-bottom: 20px;
                &:nth-of-type(2) {
                    margin: 59px 0 26px;
                }
            }
        }
        .extreme {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 22px 0 11px;

            span {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 16px;
                text-align: right;
                color: var(--ds-main-gray);
            }

            p {
                display: none;
            }
        }
    }

    .gainAccess {
        padding: 56px 0 0;
        & > div:first-of-type {
            width: 611px;
            padding: 66px 35px 0;
            margin-right: 86px;
        }
    }
}

@media (max-width: 1359px) {
    .pageWrapper {
        .items {
            padding: 28px 0 60px;
        }

        .formats {
            flex-direction: column;
            padding: 55px 0 0;

            &_left {
                width: 100%;
                margin-right: 50px;
            }

            .links {
                gap: 0 48px;

                a {
                    width: auto;
                }
            }

            .blocks {
                width: auto;
                margin-top: 41px;
                justify-content: center;

                .block {
                    width: calc(50% - 6px);
                    max-width: 640px;
                }

                .allBlocksButton {
                    width: 100%;
                }
            }
        }

        .tariffs {
            flex-direction: column;
            margin: 90px 0 0 0;
            padding: 81px 19px 75px 25px;

            .markings {
                width: auto;
                padding: 76px 0 0 0;

                h2 {
                    margin-bottom: 26px;
                }
            }
        }

        .gainAccess {
            padding: 19px 20px 0 0;
            & > div:first-of-type {
                width: 611px;
                padding: 63px 0 0 25px;
                margin-right: 65px;
                h2 {
                    width: 290px;
                    margin-bottom: 23px;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .pageWrapper {
        .description {
            margin: 25px 24px 0 23px;
        }

        .items {
            margin: 39px 24px 0 25px;
        }

        .formats {
            padding: 23px 0 0;
            margin: 0 24px 0 25px;

            .links {
                gap: 30px 48px;

                a {
                    width: 221px;
                }
            }
        }

        .tariffs {
            padding: 81px 25px 75px 25px;

            .markings {
                padding: 76px 0 0 0;

                h2 {
                    margin-bottom: 26px;
                }
            }
        }

        .gainAccess {
            padding: 26px 20px 0 0;
            & > div:first-of-type {
                width: auto;
                padding: 57px 25px 50px;
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .pageWrapper {
        .description {
            margin: 18px 17px 0 17px;
        }

        .items {
            margin: 29px 17px 0 20px;
        }

        .formats {
            margin: 0 17px 0 17px;

            &_left {
                margin: 0;
            }

            &_title {
                font-size: 28px;
                line-height: 36px;
            }

            .texts {
                margin: 21px 20px 24px 0;
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .links {
                gap: 22px 0;
            }

            .info {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 11px;
                p {
                    line-height: 20px;
                }
            }

            .blocks {
                .block {
                    width: 100%;
                    max-width: 640px;
                }
            }
        }

        .tariffs {
            margin: 77px 0 0;
            padding: 54px 16px 45px 16px;

            h1 {
                width: 100%;
                font-size: 28px;
                line-height: 40px;
            }

            .markings {
                padding: 51px 0 0 0;

                h2 {
                    font-size: 20px;
                    line-height: 24px;

                    &:nth-of-type(2) {
                        margin: 51px 0 24px;
                    }
                }
            }

            .extreme {
                margin: 16px 0 4px;
                position: relative;

                span {
                    display: none;
                }

                p {
                    position: absolute;
                    bottom: -40px;
                    display: block;
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 14px;
                    font-weight: 350;
                    line-height: 16px;
                    color: var(--ds-main-gray);
                }
            }

            div:nth-of-type(2) {
                gap: 21px;
            }
        }

        .gainAccess {
            padding: 10px 17px 0 0;
            & > div:first-of-type {
                padding: 30px 27px 18px 19px;

                h2 {
                    margin-bottom: 10px;
                }

                p {
                    width: 100%;
                }
            }
            & > div:nth-of-type(2) {
                padding: 0 0 41px 18px;
            }
        }
    }
}
