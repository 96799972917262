.technicPageWrapper {
  position: relative;
  height: 600px;
  width: 100%;
  margin-bottom: 108px;
  background:
    url('../../shared/images/png/technicSquaresBg.png') top left / auto 100% no-repeat,
    url('../../shared/images/png/technicBg.png') center / 100% 100% no-repeat;
}

.pageContent {
  padding: 158px 0 0 114px;
  width: 1100px;

  h1 {
    font-size: 80px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 48px;
    padding-left: 6px;
    width: 542px;
  }
}

@media (max-width: 1359px) {
  .technicPageWrapper {
    height: 480px;
  }

  .pageContent {
    padding: 141px 46px;
    width: 800px;

    h1 {
      font-size: 42px;
      line-height: 42px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      width: 438px;
      padding-left: 2px;
    }
  }
}

@media (max-width: 1023px) {
  .technicPageWrapper {
    margin-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .technicPageWrapper {
    display: block;
    height: 576px;
    margin-bottom: 180px;
    background:
      url('../../shared/images/png/technicSquaresBgSm.png') top left / 100% auto no-repeat,
      url('../../shared/images/png/technicBgSm.png') center / 100% 100% no-repeat;
  }

  .pageContent {
    padding: 33px 18px;
    width: 100%;

    h1 {
      font-size: 30px;
      line-height: 30px;
      letter-spacing: -1px;
      margin-bottom: 15px;
    }

    p {
      font-size: 15px;
      width: calc(100% - 72px);
    }
  }
}
