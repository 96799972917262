.singleBondTemplate {
    background-color: #f7fafd;
    width: 100%;
    height: 106px;
    display: flex;
    align-items: center;
    gap: 32px;
    padding: 32px 36px 32px 36px;
    border-radius: 12px;

    & div {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & > p:nth-child(1) {
            padding: 0 8px 0 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
            color: #8a96a6;
        }

        .templateSubHeader500 {
            padding: 0 8px 0 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--ds-dtsip-text-black-100);
        }

        .templateSubHeader300 {
            padding: 0 8px 0 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
            color: var(--ds-dtsip-text-black-100);
        }
    }
}
