.wrapper {
    padding: 48px 0 0 0;
    width: 720px;
    //min-height: 627px;

    & > * {
        padding: 0 48px;
    }

    & > div {
        margin-bottom: 27px;
        width: 100%;

        & > h6 {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            text-transform: uppercase;
            margin-right: 18px;
            margin-bottom: 0;
        }

        & > h6:only-child {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            white-space: nowrap;
        }

        & > span {
            height: 18px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-top: 1px;
            padding: 1px 8px;
            background-color: var(--ds-bg-yellow);
            border-radius: 4px;
            color: var(--ds-main-white);

            &.gray {
                background-color: var(--ds-main-gray);
            }
            &.blue {
                background-color: var(--ds-bg-blue);
            }
        }
    }

    & p {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 30px;
    }

    & h6 {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 13px;
    }

    & ul {
        font-size: 16px;
        font-style: normal;
        font-weight: 350;
        line-height: 16px;
        margin-bottom: 30px;

        & > li {
            list-style: none;
            margin-bottom: 12px;
            position: relative;
            line-height: 24px;
            padding-left: 18px;
            color: var(--ds-main-black);
        }

        & > li:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: var(--ds-main-red);
        }

        &:last-child {
            margin-bottom: 40px;
        }
    }

    & > .blueBlock {
        height: 72px;
        padding: 24px 48px 24px 48px;
        gap: 30px;
        width: 100%;
        background: var(--ds-bg-pale-blue);
        margin: 0;

        & > div {
            & > svg {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
            & > a {
                font-size: 14px;
                font-style: normal;
                font-weight: 350;
                line-height: 25px;
                text-decoration: underline;
                color: var(--ds-main-blue);
            }
        }
    }
}

.disabledLink {
    pointer-events: none;
}

@media (max-width: 767px) {
    .wrapper {
        width: 340px;

        & p {
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 24px;
        }

        & h6 {
            font-size: 16px;
            line-height: 24px;
        }

        & ul {
            & > li {
                font-size: 14px;
                line-height: 18px;
            }
        }

        & > div {
            & > span {
                max-width: 120px;
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
            }
        }

        & > .blueBlock {
            flex-wrap: wrap;
            padding: 18px;
            gap: 12px;
            height: 108px;
        }
    }
}
