@import '../../../../../../node_modules/primeicons/primeicons.css';

.wrapper {
    height: 300px;
    background-image: url('../../../../../shared/images/helpcenter/png/main_image.png');
    padding: 107px 60px 40px 60px;
    background-size: cover;

    & > h1 {
        font-weight: 700;
        line-height: 64px;
        letter-spacing: -1px;
        font-size: 72px;
    }

    & > div {
        height: 20px;
        margin-bottom: 20px;
        cursor: pointer;

        & > svg {
            height: 18px;
            width: 18px;
            margin-right: 10px;
        }

        & > p {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0;
        }
    }
}

@media (max-width: 1360px) {
    .wrapper {
        padding: 105px 24px 36px 24px;
        height: 240px;
        background-image: url('../../../../../shared/images/helpcenter/png/main_image_1360px.png');

        & > h1 {
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -1px;
            margin-bottom: 18px;
        }

        & > div {
            & > p {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .wrapper {
        background-image: url('../../../../../shared/images/helpcenter/png/main_image_1024px.png');
    }
}

@media (max-width: 767px) {
    .wrapper {
        background-image: url('../../../../../shared/images/helpcenter/png/main_image_768px.png');
        padding: 36px 0 0 18px;
        height: 216px;

        & > h1 {
            font-size: 30px;
            line-height: 30px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: -0.5px;
            margin-bottom: 13px;
        }

        & > div {
            margin-bottom: 10px;
        }
    }
}
