.accordionWrapper {
    & .content {
        border: none;
    }

    & .headerWrapper {
        margin-bottom: 16px;

        & > a {
            background-color: var(--ds-main-white);
            border: none;

            & > svg {
                display: none;
            }
        }
    }

    & ul {
        & > li {
            font-size: 15px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0;
            margin-bottom: 14px;
            list-style: none;
            position: relative;
            padding-left: 17px;
            margin-left: 23px;
            color: var(--ds-main-dark-gray);
            cursor: pointer;

            & > a {
                text-decoration: none;
            }
        }

        & > li:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--ds-main-blue);
        }
    }

    & .p-accordion-content {
        border: none !important;
    }

    .navigationItem {
        font-size: 15px;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: 0;
        margin-bottom: 14px;
        list-style: none;
        position: relative;
        padding-left: 17px;
        margin-left: 23px;
        color: var(--ds-main-dark-gray);
        cursor: pointer;

        & > a {
            text-decoration: none;
        }

        &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--ds-main-blue);
        }
    }
}

@media (max-width: 767px) {
    .accordionWrapper {
        & ul {
            & > li {
                font-size: 14px;
            }
        }
    }
}
