.userDataWrapper {
    padding: 48px;
    width: min(480px, 100%);

    & > h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 32px;
        text-transform: uppercase;
    }

    & > .userData {
        width: 100%;
        margin-bottom: 32px;

        & > div {
            margin-bottom: 16px;
            & > span:first-child {
                display: block;
                flex: 1;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                color: var(--ds-main-gray);
                white-space: nowrap;
            }
            & > span:last-child {
                display: block;
                font-size: 15px;
                font-weight: 300;
                line-height: 20px;
                text-align: end;
            }
        }
    }

    & > div {
        & > button {
            padding: 12px 24px;
            border-radius: 6px;
            background: var(--ds-main-black);
            border: none;
            margin-right: 40px;

            & > span {
                font-size: 16px;
                font-style: normal;
                font-weight: 350;
                line-height: 18px;
                color: var(--ds-main-white);
            }
        }

        & > span {
            display: block;
            color: var(--ds-main-gray);
            text-decoration: underline dashed;
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            cursor: pointer;
        }
    }
}

.removeDataModal {
    width: 480px;

    & > div {
        & > div {
            & > h2 {
                margin-bottom: 16px;
            }
            & > p {
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
            }
        }
    }
}

.noticeModal {
    & > div {
        & > div {
            & > h2 {
                text-transform: uppercase;
            }
            & > button {
                background: var(--ds-main-black);
                border: none;
                padding: 12px 24px;
                border-radius: 6px;

                & > span {
                    color: var(--ds-main-white);
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .removeDataModal {
        width: 300px;

        & > div > div {
            padding: 24px;
        }
    }

    .userDataWrapper {
        & > div {
            & > button {
                width: 150px;
                padding: 12px 6px;
                margin-right: 20px;
            }
        }
    }
}
