.descriptionNavigationWrapper {
    margin: 150px 62px 90px;
    gap: 74px;
}

.titleWrapper {
    gap: 5px;

    & > h2 {
        width: 976px;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 56px;
        font-weight: 500;
        line-height: 60px;
        text-align: left;
        text-transform: uppercase;
        color: #000;
    }

    & > p {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #000;
    }
}

.contentWrapper {
    height: 696px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 30px 24px;
}

@media (max-width: 1359px) {
    .descriptionNavigationWrapper {
        margin: 120px 48px;
        gap: 80px;
    }

    .titleWrapper {
        gap: 8px;

        & > h2 {
            width: 100%;
            font-size: 46px;
            line-height: 48px;
        }

        & > p {
            font-weight: 300;
        }
    }

    .contentWrapper {
        height: 804px;
        gap: 30px 48px;
    }
}

@media (max-width: 1023px) {
    .descriptionNavigationWrapper {
        margin: 80px 24px;
        gap: 60px;
    }

    .titleWrapper {
        gap: 16px;

        & > h2 {
            font-size: 36px;
            line-height: 40px;
        }

        & > p {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .contentWrapper {
        height: 966px;
        gap: 24px 48px;
    }
}

@media (max-width: 767px) {
    .descriptionNavigationWrapper {
        margin: 60px 18px;
        gap: 40px;
    }

    .titleWrapper {
        gap: 10px;

        & > h2 {
            font-size: 24px;
            line-height: 27px;
            letter-spacing: -0.01em;
        }

        & > p {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .contentWrapper {
        height: auto;
        gap: 48px;
        display: flex;
        flex-direction: column;
    }
}
