.instrumentItemWrapper {
    gap: 28px;

    & > img {
        width: 60px;
        height: 60px;
    }

    & > div {
        gap: 20px;

        & > h4 {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: -0.01em;
            text-align: left;
            text-transform: uppercase;
        }

        & > p {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-dark-gray);
        }
    }
}

@media (max-width: 1359px) {
    .instrumentItemWrapper {
        & > div {
            & > h4 {
                font-size: 28px;
                line-height: 32px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .instrumentItemWrapper {
        & > div {
            & > h4 {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media (max-width: 767px) {
    .instrumentItemWrapper {
        gap: 16px;

        & > div {
            gap: 8px;

            & > h4 {
                font-size: 16px;
                line-height: 18px;
            }

            & > p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
