.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: var(--ds-main-black);
  margin-bottom: 8px;
}

.subtitle {
  font-size: 14px;
  font-weight: 350;
  line-height: 20px;
  color: #636e80;
}

.list {
  border-radius: 12px;
  border: 1px solid var(--ds-border-gray);
  margin: 6px 0 12px;
  padding: 16px 20px;
  cursor: pointer;
}

.listChecked {
  background-color: var(--ds-bg-dark-blue);
  opacity: 0.1;
  border: 2px solid var(--ds-main-blue);
}

.checkbox > div:last-child {
  background-color: var(--ds-main-blue) !important;
  border-color: var(--ds-main-blue) !important;
}

.isFavouriteIcon {
  cursor: pointer;
}

.dialog {
  & > :first-child > div:first-child {
    padding: 48px 48px 32px;
    font-size: 24px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }

  & > :nth-child(2) {
    padding: 0 48px;
  }
}

.dialogFooter {
  padding: 0 48px 48px 48px;
  display: flex;
  justify-content: space-between;

  & button {
    padding: 16px 44px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;

    &.secondaryButton {
      background-color: var(--ds-main-gray);
      border-color: var(--ds-main-gray);
    }
  }
}

.listWrapper {
  margin-top: 16px;
}
