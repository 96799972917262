.editor {
    // max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;

    a {
        cursor: pointer;
    }

    & ul {
        margin-left: 20px;
        & ul {
            margin-left: 20px;
            & ul {
                margin-left: 20px;
                & ul {
                    margin-left: 20px;
                    & ul {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.menu_bar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.editable_tiptap {
    border: 1px solid #ccc;
    border-radius: 5px;

    table {
        border-collapse: collapse;
    }

    td {
        border: 1px solid #ddd;
    }
}

.bubble_menu {
    background-color: #ffffff;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    gap: 5px;
}
