.footerAdititonalContainer {
    display: flex;
    justify-content: center;
    background: var(--ds-main-black);
    margin-top: auto;

    & > :first-child {
        width: 100%;
        max-width: 1930px;
    }
}

.footerWrapper {
    display: grid;
    grid-template-areas:
        'links social'
        'logo logo'
        'info support';
    grid-template-columns: 1fr 329px;
    grid-template-rows: 47px 42px 1fr;
    gap: 23px;

    padding: 42px 62px 48px 62px;
}

.footerHeader {
    grid-area: links;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: auto;
}

.headerInfo {
    display: flex;
    align-items: center;
    gap: 36px;

    a {
        text-underline-offset: 4px;
        text-decoration-color: var(--ds-main-gray);

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.02em;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

.socialWrapper {
    grid-area: social;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: auto;

    &_icon {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 100%;
        background: var(--ds-main-white);

        &__centered > svg {
            margin-right: 2px;
        }
    }
}

.logoWrapper {
    grid-area: logo;

    //margin-bottom: 20px;
}

.infoWrapper {
    grid-area: info;

    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
        color: var(--ds-main-gray);
    }
}

.supportWrapper {
    grid-area: support;

    display: flex;
    margin-top: 22px;
    align-items: flex-start;
}

.support {
    &_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #868e96;
        background-color: #495057;
        display: flex;
        justify-content: center;

        margin-right: 16px;

        & > svg {
            margin-top: 11px;
        }
    }

    &_call,
    &_schedule {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        letter-spacing: 0.02em;
        text-align: left;
    }

    &_call {
        font-weight: 400;
        line-height: 24px;
        color: var(--ds-main-white);

        & > div {
            text-align: end;

            span {
                color: inherit;
                text-decoration: underline;
                text-underline-offset: 2px;
                text-decoration-color: rgba(255, 255, 255, 0.5);
            }
        }
    }

    &_schedule {
        font-weight: 350;
        line-height: 18px;
        color: var(--ds-main-gray);
    }
}

a.disabled {
    pointer-events: none;
}

@media (max-width: 1359px) {
    .footerWrapper {
        padding: 29px 24px 30px;

        grid-template-areas:
            'links social'
            'support support'
            'logo logo'
            'info info';
        grid-template-rows: 78px 1fr 1fr;
        gap: 13px;
    }

    .footerHeader {
        align-items: baseline;
    }

    .headerInfo {
        flex-direction: column;
        align-items: baseline;
        gap: 1px;

        & > a > p {
            font-size: 13px;
            font-weight: 350;
            line-height: 24px;
        }
    }

    .socialWrapper {
        margin-top: 7px;
        margin-bottom: auto;

        a button {
            width: 32px;
            height: 32px;
        }
    }

    .logoWrapper {
        margin-bottom: 6px;
        margin-top: 7px;
    }

    .infoWrapper {
        p {
            font-size: 13px;
        }
    }
}

@media (max-width: 1024px) {
    .footerWrapper {
        padding: 30px 24px;
        grid-template-rows: auto auto 46px 1fr;
        gap: 20px;
    }

    .supportWrapper {
        margin-top: 0;
    }
    .infoWrapper {
        padding-right: 80px;
    }
}

@media (max-width: 767px) {
    .footerWrapper {
        padding: 48px 18px 72px;
        grid-template-areas:
            'social'
            'links'
            'support'
            'logo'
            'info';
        grid-template-columns: 1fr;
        grid-template-rows: 32px auto 32px 1fr;
        gap: 10px;
    }

    .footerHeader {
        flex-direction: column;
        gap: 21px;
        margin-bottom: 19px;
    }

    .logoWrapper {
        margin-top: 24px;
        margin-bottom: 0;

        svg {
            width: 116px;
            height: 36px;
        }
    }

    .socialWrapper {
        justify-content: flex-start;
        margin-top: 0;

        &_icon {
            width: 32px;
            height: 32px;

            & > svg {
                width: 15px;
            }
        }
    }

    .infoWrapper {
        max-width: 84.5%;
        padding-right: 50px;
    }

    .headerInfo {
        gap: 0;

        & > a > p {
            line-height: 22px;
        }
    }

    .support {
        &_icon {
            width: 32px;
            height: 32px;
            margin-right: 8px;

            & > svg {
                width: 16.8px;
                height: 12px;
                margin-top: 9px;
            }
        }

        &_call,
        &_schedule {
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.59px;
        }

        &_call {
            margin-bottom: 3px;
        }
    }
}
