.tabViewContainer {
  margin-bottom: 60px;
  margin-top: 25px;
}

.tabPanel {
  margin-right: 36px;
}

.headerTemplate {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.26px;
  text-transform: uppercase;
  margin-bottom: 22px;
  color: var(--ds-main-gray);
  position: relative;

  &.active {
    color: var(--ds-main-black);
  }

  &.withNew {
    &:after {
      content: "";
      position: absolute;
      top: 2px;
      right: -4px;
      width: 6px;
      height: 6px;
      background-color: var(--ds-main-red);
      border-radius: 50%;
    }
  }

  &.active.withNew {
    &:after {
      display: none;
    }
  }
}

.tabInkBar {
  background-color: var(--ds-main-black);
}
