.checkboxWrapper {
    & > div {
        position: relative;
        gap: 8px;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
        }

        svg {
            position: relative;
            cursor: pointer;
        }
    }
}

.overlayPanelWrapper {
    position: fixed;
    width: 280px;
    padding: 18px 36px 18px 18px;
    background: var(--ds-main-white);
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    z-index: 1000;

    p {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        text-align: left;
        color: var(--ds-main-dark-gray);

        span {
            font-weight: 500;
        }
    }
}
