.capabilityItemWrapper {
    width: 452px;
    gap: 18px;

    img {
        width: 60px;
        height: 60px;
    }
}

.contentWrapper {
    padding-top: 16px;
    gap: 10px;

    h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-black);
    }

    p {
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-dark-gray);
    }
}

@media (max-width: 1359px) {
    .capabilityItemWrapper {
        width: 100%;
    }

    .contentWrapper {
        padding-top: 15px;

        h4 {
            font-size: 20px;
            line-height: 20px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
        }
    }
}

@media (max-width: 1023px) {
    .capabilityItemWrapper {
        flex-direction: column;
        gap: 15px;
    }

    .contentWrapper {
        padding-top: 0;
    }
}

@media (max-width: 767px) {
    .capabilityItemWrapper {
        gap: 16px;
    }

    .contentWrapper {
        gap: 5px;

        h4 {
            font-size: 18px;
        }

        p {
            font-size: 14px;
        }
    }
}
