.productOpportunityWrapper {
    height: 608px;
    padding: 96px 62px 90px 62px;

    & > .titleContainer {
        min-width: 512px;

        & > h2 {
            width: 285px;
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
            letter-spacing: -1%;
            text-transform: uppercase;
        }
    }

    & > .listWrapper {
        flex: 1;

        & > div {
            width: 600px;
            margin-bottom: 38px;
            & > svg {
                min-width: 60px;
                min-height: 60px;
                margin-right: 18px;
            }
            & > div {
                & > p {
                    margin-top: 16px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 24px;
                    margin-bottom: 10px;
                }
                & > span {
                    display: block;
                    font-weight: 350;
                    font-size: 16px;
                    width: 520px;
                    line-height: 24px;
                    color: var(--ds-main-dark-gray);
                }
            }
        }
    }
}

@media (max-width: 1359px) {
    .productOpportunityWrapper {
        height: 472px;
        padding: 70px 48px 60px 48px;

        & > .titleContainer {
            min-width: 329px;

            & > h2 {
                width: 236px;
                font-size: 28px;
                line-height: 32px;
            }
        }

        & > .listWrapper {
            & > div {
                & > div {
                    & > p {
                        font-size: 20px;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }
                    & > span {
                        font-size: 15px;
                        width: 520px;
                        line-height: 20px;
                    }
                }
            }
        }

    }
}

@media (max-width: 1023px) {
    .productOpportunityWrapper {
        height: 672px;
        padding: 54px 24px 59px 24px;

        & > .titleContainer {
            min-width: 302px;

            & > h2 {
                width: 236px;
            }
        }

        & > .listWrapper {
            & > div {
                flex-direction: column;
                width: 420px;
                & > div {
                    & > p {
                        margin-top: 15px;
                        margin-bottom: 10px;
                    }
                    & > span {
                        width: 420px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .productOpportunityWrapper {
        height: 736px;
        padding: 33px 18px 59px 18px;
        flex-direction: column;

        & > .titleContainer {
            min-width: 100%;
            margin-bottom: 32px;
            & > h2 {
                width: 183px;
                font-weight: 700;
                font-size: 22px;
                line-height: 24px;
            }
        }

        & > .listWrapper {
            & > div {
                flex-direction: column;
                width: 100%;
                margin-bottom: 35px;
                & > div {
                    & > p {
                        margin-top: 16px;
                        margin-bottom: 5px;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 20px;
                    }
                    & > span {
                        width: 100%;
                        font-weight: 350;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}



