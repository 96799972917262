.linkWrapper {
    text-decoration: none;
}

.articleCardWrapper {
    gap: 24px;

    &.borderTop {
        border-top: 1px solid var(--ds-border-gray);
        padding: 27px 0 0;
    }

    img {
        width: 100%;
        height: 236px;
        border-radius: 6px;
        object-fit: cover;
    }
}

.contentWrapper {
    gap: 16px;
}

.infoWrapper {
    gap: 10px;

    .categoryWrapper {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        text-align: left;
        color: var(--ds-main-blue);
    }

    .dateWrapper {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: var(--ds-main-gray);
    }
}

.mainWrapper {
    gap: 14px;

    &.nonImage {
        gap: 16px;
    }

    h4 {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        text-align: left;
        color: var(--ds-main-dark-gray);
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.divider {
    width: 1px;
    height: 11px;
    background: var(--ds-main-gray);
}

.timeWrapper {
    gap: 6px;
    margin-top: 12px;

    .icon {
        color: #a7b1ba;

        &::before {
            font-size: 10px;
        }
    }

    .text {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: var(--ds-main-gray);
    }
}

@media (max-width: 1359px) {
    .articleCardWrapper {
        img {
            height: 146px;
        }
    }
}

@media (max-width: 1023px) {
    .articleCardWrapper {
        img {
            height: 184px;
        }
    }
}

@media (max-width: 767px) {
    .articleCardWrapper {
        img {
            height: 152px;
        }
    }

    .mainWrapper {
        h4 {
            font-size: 16px;
            line-height: 20px;
        }
    }
}
