.changePageWrapper {
    width: 100%;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid var(--ds-border-gray);

    & > div {
        cursor: pointer;
        max-width: 40%;

        & > div {
            margin-right: 10px;
            width: 100%;

            & > p {
                font-size: 16px;
                font-weight: 350;
                line-height: 20px;
                letter-spacing: 0;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            & > span {
                font-size: 14px;
                font-weight: 350;
                line-height: 20px;
                letter-spacing: 0;
                color: var(--ds-main-gray);
            }
        }

        & > div.iconWrapper {
            border-radius: 4px;
            width: 36px;
            min-width: 36px;
            height: 36px;
            background-color: var(--ds-bg-light-gray);

            & > svg {
                transform: rotate(180deg);
                width: 18px;
                height: 18px;

                &.rotate {
                    transform: rotate(0deg);
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .changePageWrapper {
        & > div {
            & > div {
                & > p {
                    height: 14px;
                    font-size: 14px;
                    line-height: 14px;
                }
                & > span {
                    font-size: 10px;
                    line-height: 16px;
                }

                &.onRight {
                    margin-left: auto;
                }
            }

            & > div.iconWrapper {
                width: 30px;
                min-width: 30px;
                height: 30px;
            }
        }
    }
}
