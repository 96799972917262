.cardTemplate {
    .cardIcon {
        margin-right: 18px;
        width: 52px;

        svg {
            width: 100%;
        }
    }

    .cardType {
        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 18px;
    }

    .cardNumber {
        color: var(--Main-Black);
        font-size: 16px;
        font-style: normal;
        font-weight: 350;
        line-height: 24px;
    }
}

span.withOutCard {
    color: var(--ds-main-gray);
}

@media (max-width: 1359px) {
    .cardTemplate {
        .cardNumber {
            span:first-child {
                font-size: 11px;
                margin-right: 4px;
            }
        }
    }
}
