.wrapper {
    width: 451px;

    & a {
        text-decoration: none;
    }

    & > .titleLink {
        display: block;
        margin-bottom: 35px;

        & > img {
            max-width: 100%;
            height: 36px;
            margin-right: 15px;
        }
    }

    & > .questionsWrapper {
        width: 100%;
        margin-bottom: 20px;

        & > a {
            margin-bottom: 20px;
            width: 421px;

            &:last-child {
                margin-bottom: 0;
            }

            & > *:first-child {
                width: 8px;
                height: 12px;
                margin-right: 16px;
            }

            & > span {
                width: 389px;
                font-size: 16px;
                font-style: normal;
                font-weight: 350;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    & > .questionLink {
        font-size: 15px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        color: var(--ds-main-blue);
    }
}

@media (max-width: 1359px) {
    .wrapper {
        width: 464px;

        & > .titleLink {
            margin-bottom: 28px;
        }

        & > .questionsWrapper {
            margin-bottom: 17px;

            & > a {
                margin-bottom: 19px;
                width: 100%;

                & span {
                    width: 436px;
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .wrapper {
        width: 336px;

        & > .questionsWrapper {
            & > a {
                margin-bottom: 17px;
                width: 100%;

                & span {
                    width: 308px;
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        width: calc(100vw - 36px);

        & > .titleLink {
            margin-bottom: 22px;

            & > img {
                max-width: 100%;
                height: 30px;
            }
        }
        & > .questionsWrapper {
            & > a {
                margin-bottom: 18px;
                & span {
                    width: 100%;
                    font-size: 14px;
                }
            }
        }
    }
}
