.headerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerLogo {
    width: 220px;
    height: 36px;
}

.tabWrapper {
    display: flex;
    align-items: center;
}

.tabItem {
    display: flex;
    align-items: center;
    gap: 0 4px;
    text-transform: uppercase;
}

.icon {
    padding-top: 4px;
}

.inactiveTab {
    color: var(--ds-main-black);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
}

.activeTab {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 18px;
    display: inline-block;
    position: relative;

    &:before {
        margin-top: 55px;
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        background: var(--ds-bg-dark-blue);
    }
}

.disabledLink {
    opacity: 0.5;
    cursor: not-allowed;
}

.burgerButton {
    background: var(--ds-main-white);
    border: none;
    height: 50px;
    width: 50px;
    margin-right: 14px;
}

.activeTabMobile {
    display: inline-block;
    position: relative;
    text-decoration: underline;
    & > span {
        font-weight: 500;
        line-height: 18px;
    }
}

.inactiveTabMobile {
    color: var(--ds-main-black);
    font-size: 18px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
}

.mobileMenu {
    height: 100%;
    list-style: none;
    margin-bottom: 32px;

    & > li {
        height: 32px;
        margin-top: 8px;

        & > span {
            font-size: 18px;
            font-weight: 350;
        }
    }
}

.tabTextMobile {
    font-size: 13px;
    padding-right: 10px;
}

.rootik {
    transform: translate(0%, -25px);
}

@media (max-width: 1359px) {
    .activeTab {
        &:before {
            margin-top: 35px;
        }
    }
}

@media screen and (max-width: 768px) {
    .tabItem {
        height: 34px;
    }
}

@media screen and (max-width: 475px) {
    .tabItem {
        height: 34px;
    }

    .rootik {
        transform: translate(50%, -50%);
    }
}
