.carousel {
    width: 100%;
    display: flex;

    &_inner {
        position: relative;

        .prev,
        .next {
            position: absolute;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            border: none;
            cursor: pointer;
            z-index: 1;
            width: 30px;
            height: 30px;

            &:hover {
                background-color: rgba(100, 100, 100, 0.5);
            }
        }

        .prev {
            left: 0;
        }

        .next {
            left: 30px;
        }
    }

    &_images {
        display: flex;
        position: relative;
        flex: 1;
        height: 100%;
        overflow: hidden;

        &_inner {
            width: 100%;
            height: 100%;
            display: flex;
            transition: transform 0.5s ease;

            img {
                object-fit: cover;
                flex-shrink: 0;
            }
        }
    }
}

.position_options {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
    label {
        cursor: pointer;
    }
}
