.p-calendar {
  height: 42px;
  width: 100%;

  .p-inputtext {
    padding-left: 18px;
    color: var(--Main-Black, #18191b);
    font-variant-numeric: lining-nums proportional-nums;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .p-button {
    background-color: transparent;
    color: #388af7;
    border: 1px solid #d8dfe6;
  }
}

.p-inputtext:enabled:focus {
  border: 1px solid #d8dfe6;
}

.p-inputtext:hover {
  border: 1px solid #d8dfe6;
}

.p-datepicker {
  padding: 10px;
}

.p-float-label {
  font-size: 14px;
}
