.modal {
    width: 720px;
    padding: 43px 48px 48px 48px;
    overflow: hidden;

    @media screen and (max-width: 769px) {
        width: 400px;
        padding: 20px 24px 24px 24px;
    }

    @media screen and (max-width: 451px) {
        width: 300px;
        padding: 16px 20px 20px 20px;
    }
}

.header {
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-right: 12px;

    @media screen and (max-width: 769px) {
        font-size: 22px;
    }

    @media screen and (max-width: 451px) {
        font-size: 18px;
    }
}

.imageWrapper {
    margin-bottom: 20px;
    height: max-content;
}

.image {
    width: 100%;
    max-height: 300px;

    @media screen and (max-width: 769px) {
        max-height: 200px;
    }

    @media screen and (max-width: 451px) {
        max-height: 150px;
    }
}
.contentText {
    font-size: 16px;

    @media screen and (max-width: 769px) {
        font-size: 14px;
    }
}

.buttonLink {
    text-decoration: none;
}

.button {
    padding: 12px 24px;
    background-color: var(--ds-main-red);
    font-size: 16px;
    margin-top: 50px;

    @media screen and (max-width: 769px) {
        font-size: 14px;
    }

    @media screen and (max-width: 769px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
