.wrapper {
    width: 453px;
    height: 404px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > .topPart {
        background: var(--ds-bg-pale-blue);
        padding: 36px 48px 27px 48px;

        & > img {
            width: 315px;
            height: 45px;
        }
    }

    & > .bottomPart {
        padding: 32px 48px;

        .infoContainer {
            gap: 8px;

            & > p {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                text-align: left;
            }

            & > div {
                gap: 1px;
            }

            .itemWrapper {
                & > span:first-child {
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 14px;
                    font-weight: 350;
                    line-height: 24px;
                    text-align: left;
                    color: var(--ds-main-gray);
                }

                & > span {
                    gap: 4px;
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 15px;
                    font-weight: 350;
                    line-height: 24px;
                    text-align: right;

                    span {
                        font-weight: 300;
                        text-align: left;
                        color: var(--ds-main-gray);
                    }
                }
            }
        }

        .buttonContainer {
            margin-top: 32px;
            gap: 12px;

            & > div {
                gap: 12px;
            }

            & button,
            & a {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 42px;
                padding: 12px 24px 12px 24px;
                border: none;
                border-radius: 6px;
                text-decoration: none;

                span {
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 18px;
                    text-align: center;
                    color: var(--ds-main-white);
                }

                &.redBtn {
                    background: var(--ds-main-red);
                }

                &.blackBtn {
                    width: 142px;
                    background: var(--ds-main-black);
                }

                &.greyBtn {
                    background: #ced7e0;
                    height: 36px;
                    padding: 6px 18px 6px 10px;

                    span {
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .wrapper {
        width: 348px;
    }
}

@media (max-width: 767px) {
    .wrapper {
        width: 300px;
        height: 334px;
        // margin-right: 12px;

        & > .topPart {
            height: 72px;
            padding: 24px 18px 18px;

            & > img {
                width: 270px;
                height: 30px;
            }
        }

        & > .bottomPart {
            padding: 18px;

            .infoContainer {
                & > p {
                    font-size: 15px;
                    line-height: 24px;
                }

                .itemWrapper {
                    & > span:first-child {
                        font-size: 13px;
                    }

                    & > span {
                        font-size: 13px;
                    }
                }
            }

            .buttonContainer {
                margin-top: 18px;
            }
        }
    }
}
