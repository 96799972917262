.descriptionBlockWrapper {
    margin: 150px 62px 0;
    gap: 130px;
}

.titleWrapper {
    gap: 20px;

    & > h2 {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 56px;
        font-weight: 500;
        line-height: 60px;
        text-align: left;
        text-transform: uppercase;
        color: var(--ds-main-black);

        span {
            color: var(--ds-main-red);
        }
    }

    & > p {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-black);
    }
}

.contentWrapper {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 80px 24px;
}

@media (max-width: 1359px) {
    .descriptionBlockWrapper {
        margin: 120px 48px 0;
        gap: 80px;
    }

    .titleWrapper {
        gap: 16px;

        & > h2 {
            font-size: 46px;
            line-height: 50px;
        }
    }

    .contentWrapper {
        gap: 72px 48px;
    }
}

@media (max-width: 1023px) {
    .descriptionBlockWrapper {
        margin: 80px 24px 0;
        gap: 60px;
    }

    .titleWrapper {
        gap: 12px;

        & > h2 {
            font-size: 36px;
            line-height: 40px;
        }
    }

    .contentWrapper {
        gap: 48px;
    }
}

@media (max-width: 767px) {
    .descriptionBlockWrapper {
        margin: 60px 18px 0;
        gap: 40px;
    }

    .titleWrapper {
        gap: 12px;

        & > h2 {
            font-size: 17px;
            line-height: 21px;
            letter-spacing: -0.01em;

            span {
                letter-spacing: -0.01em;
            }
        }

        & > p {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .contentWrapper {
        grid-template-columns: 1fr;
    }
}
