.advantagesWrapper {
    margin: 86px 63px 0;
    gap: 60px 100px;
    flex-wrap: wrap;
}

@media (max-width: 1359px) {
    .advantagesWrapper {
        margin: 120px 48px 0;
        gap: 48px;
    }
}

@media (max-width: 1023px) {
    .advantagesWrapper {
        margin: 56px 24px 0;
        gap: 48px;
    }
}

@media (max-width: 767px) {
    .advantagesWrapper {
        margin: 37px 18px 0;
        gap: 38px;
    }
}
