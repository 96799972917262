.productsWrapper {
    width: 980px;
    gap: 24px;
    overflow: hidden;
}

@media (max-width: 1599px) {
    .productsWrapper {
        width: 100%;
        gap: 24px;
    }
}

@media (max-width: 1023px) {
    .productsWrapper {
        width: 100%;
        gap: 24px;
    }
}
