.carouselWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.imagesWrapper {
    transition: transform 0.5s ease;
    height: 100%;

    img {
        width: 100%;
        object-fit: cover;
        flex-shrink: 0;
    }
}

.sliderWrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.sliderButton {
    position: absolute;
    bottom: 0;
    left: 0;
}

@media (max-width: 1023px) {
    .carouselWrapper {
        margin: -80px auto 0;
        width: calc(100% - 48px);
    }
}

@media (max-width: 767px) {
    .carouselWrapper {
        margin: 0;
        width: 100%;
    }
}
