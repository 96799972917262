.notConfirmed {
    width: 100%;
    & > .notConfirmedEmailContainer {
        width: 100%;
        gap: 8px;
        & > .inputContainer {
            position: relative;
            flex: 1;

            & > i {
                position: absolute;
                top: 9px;
                left: 12px;
            }
        }

        & > button {
            height: 42px;
            width: 42px;
            background-color: var(--ds-main-white);
            border: 1px solid var(--ds-bg-light-gray);
            padding: 9px;
            border-radius: 6px;

            &.incorrect {
                background-color: var(--ds-bg-light-gray);
            }
        }
        & .emailInput {
            padding: 9px 18px 9px 48px;
            height: 42px;
            width: 100%;
            font-size: 15px;
            font-weight: 300;
            line-height: 24px;

            &:disabled {
                background-color: var(--ds-bg-extra-light-gray);
                opacity: 1;
            }
        }
    }

    .notConfirmedMessage {
        margin-top: 16px;
        & > p {
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            color: var(--ds-main-dark-gray);
        }

        & > span {
            display: block;
            color: var(--ds-main-blue);
            text-decoration: underline dashed;
            text-underline-offset: 2px;
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            cursor: pointer;
        }
    }
}

.noticeEmailModal {
    width: 480px;
    height: 270px;
    overflow: hidden;

    div {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        width: 100%;
    }

    p {
        width: 80%;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 26px;
    }

    button {
        border: none;
        height: 42px;
        background-color: var(--ds-main-black);

        & > span {
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 767px) {
    .noticeEmailModal {
        width: calc(100% - 60px);
        height: auto;
    }

    .notConfirmed {
        .notConfirmedMessage {
            & > span {
                text-align: end;
            }
        }
    }
}
