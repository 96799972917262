.tariffBlockWrapper {
    padding: 73px 62px 157px;
    background: var(--ds-bg-extra-light-gray);

    & > h2 {
        margin-top: 15px;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }

    .tariffItem {
        width: 964px;
        height: 72px;
        border-bottom: 1px solid var(--ds-border-gray);
        background-color: var(--ds-bg-extra-light-gray);

        &.open {
            border-bottom: none;
        }

        & > h6 {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        & > svg {
            width: 24px;
            height: 24px;
        }
    }
}

.accordionHeader {
    & > a {
        border: none;
        & > svg {
            display: none;
        }
    }
}

.accordionContent {
    border: none;
    border-bottom: 1px solid var(--ds-border-gray);
    background-color: var(--ds-bg-extra-light-gray);
    width: 964px;
}

@media (max-width: 1359px) {
    .tariffBlockWrapper {
        padding: 60px 48px 120px;

        & > h2 {
            margin-top: 0;
            font-size: 28px;
            line-height: 30px;
        }

        .tariffItem {
            width: 100%;
        }
    }

    .accordionContent {
        width: 100%;
    }
}

@media (max-width: 1023px) {
    .tariffBlockWrapper {
        padding: 65px 24px 80px;

        .tariffItem {
            & > h6 {
                font-size: 22px;
            }
        }
    }
}

@media (max-width: 767px) {
    .tariffBlockWrapper {
        padding: 38px 18px 60px;

        .tariffItem {
            height: 52px;

            & > h6 {
                font-size: 20px;
            }
        }
    }
}
