.ProseMirror {
    padding: 5px;
    min-height: 500px;
    img {
        object-fit: cover;
    }
}

.tiptap_editor {
    table {
        width: 100%;
        margin: 10px 0;
        th,
        td {
            text-align: left;
            vertical-align: top;
            padding: 8px;
            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.2rem;
            }
        }
    }

    th {
        border: none;
        font-weight: normal;
    }

    th:first-child {
        background-color: #f5f7fa;
    }

    th:nth-child(2) {
        color: #575e66;
        padding-left: 10px;
        border-left: 1px solid #d8e1eb;
        background-color: #f5f7fa;
    }

    .file-upload-wrapper {
        padding: 4px 10px;
        border: 1px solid #ccc;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px;
        svg {
            display: none;
        }
    }

    .file-upload-wrapper:hover {
        background-color: #f0f0f0;
    }

    code {
        background: #f5f7fa;
        padding: 5px;
    }

    pre {
        code {
            display: block;
            padding: 20px;
            width: calc(100% - 40px);
        }
    }

    .p-fileupload-choose {
        gap: 5px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        font-size: 14px;
        svg {
            height: 11px;
        }
    }

    .p-button-danger {
        border: none;
        background: none;
    }

    .p-fileupload-row {
        margin: 10px 0;
        div {
            width: auto;
        }
    }
}
