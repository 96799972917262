.wrapper {
    height: 460px;
    margin: 0 0 39px 0;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 18px;

    h1 {
        width: 50%;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 40px;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
        color: #18191b;
        letter-spacing: 1px;
    }

    p {
        width: 674px;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 18px;
        font-weight: 350;
        line-height: 30px;
        color: #18191b;
        margin: 22px 0 39px 0;
        letter-spacing: 0.15px;
    }

    ul {
        list-style-type: square;
        margin: 20px 18px 46px;
    }

    li {
        width: 580px;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 18px;
        font-weight: 350;
        line-height: 20px;
        margin-bottom: 9px;
        letter-spacing: 0.2px;

        &::marker {
            color: #ff0508;
        }
    }

    .links {
        display: flex;
        gap: 12px;
        a {
            text-decoration: none;
        }
    }

    .getAccessLink {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        height: 36px;
        padding: 6px 24px;
        gap: 6px;
        border-radius: 6px;
        background: #2f9ceb;
        color: #ffffff;
        cursor: pointer;
    }

    .tariffsLink {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        height: 36px;
        padding: 6px 24px;
        gap: 6px;
        border-radius: 6px;
        background: #ffffff;
        color: #18191b;
        cursor: pointer;
    }
}

@media (max-width: 1359px) {
    .wrapper {
        h1 {
            width: 70%;
        }
    }
}

@media (max-width: 1023px) {
    .wrapper {
        padding: 45px 50px 55px;
        height: 410px;

        h1 {
            width: 100%;
            font-size: 32px;
            line-height: 36px;
        }

        p {
            width: 65%;
            font-size: 16px;
            line-height: 24px;
            margin: 17px 0 41px;
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        padding: 35px 18px 58px 31px;
        height: 448px;

        h1 {
            font-size: 30px;
            line-height: 30px;
        }

        p {
            width: 100%;
            font-size: 15px;
            line-height: 20px;
            margin: 24px 0 44px;
        }

        li {
            font-size: 15px;
            line-height: 20px;
        }
    }
}
