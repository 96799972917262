.complianceServicesWrapper {
    padding: 90px 62px 62px;
}

.titleWrapper {
    margin-bottom: 50px;

    & > h2 {
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }
}

.contentWrapper {
    gap: 64px;
}

@media (max-width: 1359px) {
    .complianceServicesWrapper {
        padding: 70px 48px 60px;
    }

    .titleWrapper {
        gap: 35px;

        & > h2 {
            font-size: 28px;
            line-height: 32px;
        }
    }

    .contentWrapper {
        gap: 50px;
    }
}

@media (max-width: 1023px) {
    .complianceServicesWrapper {
        padding: 60px 24px 40px;
    }

    .titleWrapper {
        gap: 30px;
        margin-bottom: 40px;
    }
}

@media (max-width: 1023px) {
    .complianceServicesWrapper {
        padding: 35px 0 20px 18px;
    }

    .titleWrapper {
        gap: 15px;
        margin-bottom: 42px;
        overflow: hidden;
    }

    .contentWrapper {
        padding-right: 18px;
        gap: 40px;
    }
}
