.filtersContainer {
    min-width: 337px;
    max-width: 337px;
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--ds-border-gray);
    padding: 32px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    background: var(--ds-main-white);
    height: fit-content;
}

.filterModal {
    width: 560px;
    padding: 48px;
    display: flex;
    flex-direction: column;

    text-align: left;
    gap: 32px;

    & > h3 {
        align-self: start;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        color: var(--ds-text-black);
    }
}

.filterModalHeight {
    height: auto;
    overflow: hidden;
}

.blockDivider {
    width: 100%;
    border-bottom: 1px solid var(--ds-border-gray);
}

.buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.showButton {
    height: 36px;
    border-radius: 6px;
    border: none;
    width: 100%;
    background-color: var(--ds-bg-dark-blue);

    & span {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-main-white);
    }
}

.clearButton {
    height: 36px;
    max-width: 91px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & span {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-text-black);
    }
}

.clearMobileButton {
    height: 36px;
    max-width: 91px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & span {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-text-black);
    }
}

.filtersButton {
    border: 0;
    background-color: #ffffff;

    & span {
        font-size: 18px;
        font-weight: 400;
        color: var(--ds-text-black);
    }
}

@media (max-width: 1024px) {
    .filterModal {
        width: 720px;
        padding: 32px 36px;
        gap: 30px;
    }

    .filtersContainer {
        flex-direction: row;
        height: 72px;
        margin: 0 auto;
        max-width: 720px;
        min-width: 324px;
        width: 100%;
        text-align: center;
        border: 1px solid #388af7;
    }

    .showButton {
        max-width: 91px;
    }
}

@media (max-width: 767px) {
    .filtersContainer {
        border-radius: 12px;
        border: 1px solid #388af7;
        padding: 24px 36px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        & > h2 {
            color: var(--ds-main-black);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        & > button {
            background-color: var(--ds-main-white);
            padding: 6px 12px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & span {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }
    }

    .filterModal {
        width: 90vw;
        gap: 30px;
        padding: 32px 36px;
    }
}

@media (max-width: 475px) {
    .clearMobileButton {
        display: none;
    }

    .filtersContainer {
        border-radius: 6px;
        padding: 8px 16px;
        border: 1px solid #388af7;
        height: 40px;

        & > {
            color: var(--ds-text-black);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .filtersButton {
        & > span {
            font-size: 16px;
        }
    }

    .filterModal {
        width: 324px;
        gap: 24px;
        padding: 32px 16px;
    }

    .buttonsContainer {
        justify-content: center;
    }

    .showButton {
        // flex-grow: 0;
        max-width: 190px;
    }
}

.mobileButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.redDot {
    display: none;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--ds-main-red);
    position: absolute;
    right: 0;
    top: 0;
    margin-left: 4px;
}

.filtersButtonWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    padding-right: 8px;
}

@media (max-width: 1025px) {
    .redDot {
        display: block;
    }
}

@media (max-width: 400px) {
    .filterModal {
        width: 324px;
    }
}
