@import '../../../../../../shared/commonStyles/media-css-variables';

.headerWrapper {
    width: 100%;
    height: 500px;
    background:
        url('../../../../../../shared/images/svg/landingHeader/cubesXl.svg') center left / auto 100% no-repeat,
        url('../../../../shared/images/png/LandingPageBgXl.png') center right / auto 100% no-repeat;
}

.contentWrapper {
    padding: 134px 0 0 62px;

    & > p {
        margin-bottom: 34px;
        font-size: 18px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
    }

    & > button {
        height: 56px;
        background: var(--ds-main-red);
        border: none;
        border-radius: 6px;
        padding: 16px 30px 16px 20px;
        gap: 8px;

        span {
            margin-top: 4px;
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

.logoWrapper {
    margin: 36px 0 22px;
    position: relative;
    width: fit-content;

    & > svg {
        width: 515.5px;
        height: 60px;
    }

    &::after {
        content: 'ДЕМО ВЕРСИЯ';
        position: absolute;

        width: 87px;
        height: 18px;

        top: 0;
        right: -90px;
        padding: 4px 6px 4px 6px;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: var(--ds-main-blue);

        display: block;

        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        text-align: left;
        white-space: nowrap;
        color: var(--ds-main-white);
    }
}

@media (max-width: $lg) {
    .headerWrapper {
        height: 400px;
        background:
            url('../../../../../../shared/images/svg/landingHeader/cubesLg.svg') center left / auto 100% no-repeat,
            url('../../../../shared/images/png/LandingPageBgLg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 83.5px 0 0 48px;

        & > svg {
            margin: 30px 0 20px;
            width: 408px;
            height: 41px;
        }

        & > p {
            width: 389px;
            margin-bottom: 28px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .logoWrapper {
        & > svg {
            width: auto;
            height: auto;
        }
    }
}

@media (max-width: $md) {
    .headerWrapper {
        background:
            url('../../../../../../shared/images/svg/landingHeader/cubesMd.svg') center left / auto 100% no-repeat,
            url('../../../../shared/images/png/LandingPageBgLg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 69.5px 0 0 24px;
    }
}

@media (max-width: $sm) {
    .headerWrapper {
        height: 524px;
        background:
            url('../../../../../../shared/images/svg/landingHeader/cubesSm.svg') left bottom / 144px 288px no-repeat,
            url('../../../../shared/images/png/LandingPageBgSm.png') right bottom / 100% 288px no-repeat;
    }

    .contentWrapper {
        padding: 36px 18px 41px;

        & > p {
            width: 100%;
            font-size: 15px;
            margin-bottom: 27px;
        }

        & > button {
            height: 48px;
            padding: 12px 20px 12px 12px;
        }
    }

    .logoWrapper {
        margin: 0 0 14px;

        & > svg {
            width: 225px;
            height: 25px;
        }

        &::after {
            width: 68px;
            height: 14px;
            right: -70px;
            font-size: 8px;
            padding: 2px 4px;
            line-height: 11px;
        }
    }
}
