.investorproBtn {
    width: 284px;
    height: 56px;
    margin: 80px 36px 32px;
    padding: 16px 24px 16px 24px;
    border: none;
    border-radius: 6px;
    background: var(--ds-main-black);

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: var(--ds-main-white);
    }
}

.investorproLink {
    width: 100%;
    padding: 0 32px;
    a {
        display: inline-flex;
        width: 100%;
        justify-content: center;
        background: var(--ds-main-black);
        text-decoration: none;
        padding: 16px 24px 16px 24px;
        margin: 80px 0px 32px 0px;
        border: none;
        border-radius: 6px;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--ds-main-white);
        }
    }
}
