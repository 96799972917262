.sessionEmailExpiredPageWrapper {
    position: relative;
    height: 600px;
    width: 100%;
    margin-bottom: 108px;
    background:
        url('../../../shared/images/png/technicSquaresBg.png') top left / auto 100% no-repeat,
        url('../../../shared/images/png/technicBg.png') center / 100% 100% no-repeat;
}

.pageContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 156px 0 0 62px;
    width: 480px;
    gap: 48px;

    .textBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        & > h1 {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 42px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: -0.02em;
            text-align: left;
            text-transform: uppercase;
        }

        & > span {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            text-align: left;
            color: var(--ds-main-black);
        }
    }

    & > button {
        width: 207px;
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-black);

        span {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 1023px) {
    .sessionEmailExpiredPageWrapper {
        height: 480px;
        width: 100%;
        margin-bottom: 178px;
        background:
            url('../../../shared/images/png/technicSquaresBg.png') top left / auto 100% no-repeat,
            url('../../../shared/images/png/technicBg.png') center / 100% 100% no-repeat;
    }

    .pageContent {
        margin: 145px 0 0 25px;
        width: 350px;
        gap: 24px;

        .textBlock {
            & > h1 {
                font-size: 24px;
                line-height: 24px;
                letter-spacing: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .sessionEmailExpiredPageWrapper {
        display: block;
        height: 576px;
        margin-bottom: 0;
        background:
            url('../../../shared/images/png/technicSquaresBgSm.png') top left / 100% auto no-repeat,
            url('../../../shared/images/png/technicBgSm.png') center / 100% 100% no-repeat;
    }

    .pageContent {
        margin: 0;
        padding: 35px 18px;
        width: 100%;

        .textBlock {
            & > h1 {
                letter-spacing: -1px;
            }

            & > span {
                font-size: 15px;
            }
        }
    }
}
