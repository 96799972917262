.navigationBlockWrapper {
    position: sticky;
    top: 0;
    width: 100%;
    height: 48px;
    padding: 17px 62px 0 62px;
    background: var(--ds-main-white);
    border-bottom: 1px solid var(--ds-border-gray);
    z-index: 100;

    & > div {
        gap: 36px;
    }

    span {
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: left;
        text-transform: uppercase;
        text-decoration: underline dashed;
        text-underline-offset: 2px;
        text-decoration-color: var(--ds-main-gray);
        color: var(--ds-main-gray);

        &.selected {
            padding: 0 0 13px 0;
            text-decoration: none;
            border-bottom: 2px solid var(--ds-main-red);
            color: var(--ds-main-black);
        }
    }
}

@media (max-width: 1359px) {
    .navigationBlockWrapper {
        margin-left: 48px;
        padding: 17px 62px 0px 0px;

        & > div {
            gap: 28px;
        }
    }
}

@media (max-width: 1023px) {
    .navigationBlockWrapper {
        width: 720px;
        margin: 0px 24px;
        overflow-x: auto;
        overflow-y: hidden;
        mask-image: linear-gradient(to right, black 0%, black calc(100% - 50px), transparent 100%);
        -webkit-mask-image: linear-gradient(to right, black 0%, black calc(100% - 50px), transparent 100%);

        div {
            position: relative;
            width: max-content;
            gap: 20px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: calc(100vw - 48px);
                width: 48px;
                height: 48px;
                background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            }
        }
    }
}

@media (max-width: 767px) {
    .navigationBlockWrapper {
        width: 100%;
        height: 38px;
        margin: 0;
        padding: 10px 18px 0;

        div {
            gap: 12px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: calc(100vw - 38px);
                width: 38px;
                height: 38px;
                background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            }
        }

        span {
            font-size: 10px;

            &.selected {
                padding: 0 0 10px 0;
            }
        }
    }
}
