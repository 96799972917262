.manageProductBlockWrapper {
    gap: 24px;
}

.returnBlock {
    gap: 10px;
    cursor: pointer;
    width: fit-content;

    span {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
    }
}

.productWrapper {
    width: 100%;
    gap: 24px;

    .logoWrapper {
        height: 108px;
        background: var(--ds-bg-pale-blue);
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        padding: 36px 48px 27px;

        img {
            width: 315px;
            height: 45px;
        }
    }

    .contentWrapper {
        gap: 40px;
        padding: 40px 48px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;

        & > div {
            gap: 24px;

            & > p {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 18px;
                font-weight: 700;
                line-height: 28px;
                text-align: left;
            }

            .rowWrapper {
                padding: 16px 0;
                border-bottom: 1px solid var(--ds-border-gray);

                &:first-child {
                    border-top: 1px solid var(--ds-border-gray);
                }

                & > span {
                    gap: 4px;
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 24px;
                    text-align: left;

                    span {
                        color: var(--ds-main-gray);
                    }

                    &.redSpan {
                        color: var(--ds-main-red);
                    }
                }
            }
        }

        .buttonWrapper {
            gap: 12px;

            button {
                height: 42px;
                padding: 12px 24px 12px 24px;
                border: none;
                border-radius: 6px;

                span {
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 18px;
                    text-align: center;
                    color: inherit;
                }

                &.redBtn {
                    background: var(--ds-main-red);
                    color: var(--ds-main-white);
                }

                &.whiteBtn {
                    background: var(--ds-main-white);
                    color: var(--ds-main-black);
                    border: 1px solid var(--ds-main-gray);
                }
            }
        }
    }
}

@media (max-width: 1359px) {
    .manageProductBlockWrapper {
        margin-bottom: 85px;
    }
}

@media (max-width: 767px) {
    .productWrapper {
        width: calc(100vw - 36px);
        gap: 14px;

        .logoWrapper {
            height: 72px;
            padding: 24px 18px 18px;

            img {
                width: 210px;
                height: 30px;
            }
        }

        .contentWrapper {
            gap: 24px;
            padding: 24px 18px;

            & > div {
                gap: 18px;

                & > p {
                    font-size: 15px;
                    line-height: 24px;
                }

                .rowWrapper {
                    padding: 10px 0;

                    & > span {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
