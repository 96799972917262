.saveButtonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.saveButtons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    margin-top: 20px;

    .saveButton {
        background-color: var(--ds-bg-dark-blue);
        padding: 6px 12px;
        width: fit-content;
        height: 36px;
        border-radius: 6px;
        border: none;
        gap: 8px;

        & span {
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-main-white);
        }
    }

    .clearButton {
        background-color: var(--ds-main-white);
        padding: 6px 12px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid var(--ds-border-gray);

        & span {
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-main-black);
        }
    }
}
