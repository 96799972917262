.productsWrapper {
  margin: 92px 0 105px;

  & > div:not(:last-child) {
    padding: 0 62px 24px;
  }

  h3 {
    padding: 0 0 28px 60px;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0;
    text-align: left;
  }
}

.cardWrapper {
  padding: 0 62px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
}

.footerWrapper {
  margin-top: 22px;
  padding-left: 62px;

  p {
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: -0.1px;
    text-align: left;
    color: var(--ds-main-gray);
  }

  a {
    text-decoration-color: var(--ds-main-blue);
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: var(--ds-main-blue);
  }
}

@media (max-width: 1359px) {
  .productsWrapper {
    margin: 100px 0 80px;

    h3 {
      padding: 0 0 10px 24px;
      font-size: 32px;
      line-height: 64px;
      letter-spacing: -1px;
    }

    & > div:not(:last-child) {
      padding: 0 24px 24px;
    }
  }

  .cardWrapper {
    padding: 0 24px;
  }
}

@media (max-width: 1023px) {
  .productsWrapper {
    & > div:not(:last-child) {
      padding: 0 24px 75px;
    }
  }
}

@media (max-width: 767px) {
  .productsWrapper {
    margin: 74px 0 80px;

    h3 {
      padding: 0 0 2px 18px;
      font-size: 24px;
      line-height: 64px;
    }

    & > div:not(:last-child) {
      padding: 0 18px 44px;
    }
  }

  .cardWrapper {
    display: inline-flex;
    flex-direction: column;
    padding: 0 18px;
    gap: 12px;
  }
}
