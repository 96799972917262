.dataUploadContainer {
    min-height: 100vh;
    padding: 48px 36px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 40px;

    & > h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--ds-main-black);
    }

    .dataUpload {
        width: 100%;
        border-radius: 12px;
        border: 1px solid var(--ds-border-gray);
        padding: 32px 36px;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 30px;
    }

    .toggleButtons {
        border-radius: 5px;
        border: 1px solid var(--ds-border-gray);
        display: flex;
        align-items: stretch;
        width: fit-content;

        .toggleActive {
            background-color: var(--ds-dtsip-text-black-100);

            & span {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-white);
            }
        }

        .toggleInactive {
            background-color: var(--ds-main-white);

            & span {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }

        .toggleLeft {
            width: 246px;
        }

        .toggleRight {
            width: 215px;
        }
    }

    .toggleButton {
        border-radius: 5px;
        padding: 0px 4px;
        border: none;
        width: 100%;
        height: 34px;
    }

    .mainBlock {
        width: 100%;
        display: flex;
        align-items: start;
        gap: 30px;

        & > div {
            & > p {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                color: var(--ds-main-black);
            }
        }

        .autoCompleteInput {
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & > input {
                padding: 6px 12px 6px 18px;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                border: none;
            }

            & > button {
                border: none;
                background-color: var(--ds-main-white);
                color: var(--ds-main-light-gray);
            }

            & > [class~='p-autocomplete-panel'] {
                height: 300px;
                overflow-y: scroll;
            }
        }

        .listDownloadButton {
            background-color: var(--ds-main-white);
            color: var(--ds-main-black);
            padding: 6px 20px 6px 16px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & > span:first-child {
                padding: 0 5px;
                color: var(--ds-main-blue);
            }

            & > span:last-child {
                padding: 0 5px;
                font-size: 13px;
                font-style: normal;
                font-weight: 350;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }
    }

    .isinBlockParamSearchInput {
        width: 100%;
        height: 36px;
        border-radius: 6px;
        border: 1px solid var(--ds-border-gray);
        position: relative;

        & > div {
            color: var(--ds-main-gray);
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
        }

        & > span {
            color: var(--ds-text-black);
            padding: 8px 0px 8px 18px;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
        }

        & [class~='p-multiselect-label'] {
            padding: 8px 18px;
            color: var(--ds-text-black);
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
        }

        & [class~='p-multiselect-trigger'] {
            width: 12px;
            margin-right: 13px;
        }
    }

    .dateBlock {
        gap: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // width: 100%;
        & > p {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            color: var(--ds-main-black);
        }
    }

    .blockDivider {
        width: 100%;
        border-bottom: 1px solid var(--ds-border-gray);
    }
}

.calendarInput {
    height: 36px;
    width: 100%;
    border-radius: 6px;

    min-width: 145px;
    max-width: 150px;
    width: 100%;

    & > input {
        border-right: none !important;

        padding: 6px 12px 6px 12px !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 16px;
    }

    & > button {
        border-left: none !important;
        background-color: var(--ds-main-white);
        color: var(--ds-main-blue);
        padding: 8px 0 !important;
        width: 36px;
    }

    & [class~='p-datepicker'] {
        width: 345px !important;
    }

    & [class~='p-icon'] {
        padding: 2px;
        width: 24px !important;
        height: 24px !important;
    }

    & td {
        padding: 0;
        width: fit-content;
        height: fit-content;

        & span {
            height: 36px;
            width: 36px;
        }
    }
}

.translateCenter {
    & [class~='p-datepicker'] {
        transform: translate(-15%, 0%) !important;
    }
}

.multiSelect {
    width: 433px;
    height: 36px;
    padding: 8px 0px 8px 18px;
}

.dropDown {
    width: 100%;
    height: 36px;
    padding: 0px 0px 0px 18px;
    border: 1px solid var(--ds-border-gray);

    & [class~='p-dropdown-label'] {
        color: var(--ds-text-black);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        padding: 2px 0px 0px 0px;
    }
}

.multiSelectHeader {
    padding: 8px 8px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.multiSelectFilterInput {
    width: 100%;

    & input {
        height: 36px;
    }

    & svg {
        transform: translate(50%, -50%) !important;
        margin-right: 8px;
    }
}

.multiSelectList {
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.multiSelectListItem {
    margin-left: 8px;
    display: flex;
    gap: 8px;

    & > div {
        display: none;
    }

    & > span {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.multiSelectListItem,
.p-disabled {
    opacity: 1;
}

.instrumentsSelectWrapper {
    max-width: 433px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
}

.multiSelectListItemGroup {
    margin-left: 4px;
    margin-right: 4px;
    border-bottom: 1px solid var(--ds-main-light-gray);
}

.selectedItem {
    background-color: var(--ds-bg-extra-light-gray);
    color: var(--ds-dtsip-text-black-100);
    padding: 3px 0 3px 8px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--ds-border-gray);
    cursor: auto;

    & > span {
        padding: 0 8px 0 0;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }

    & > i {
        cursor: pointer;
        padding: 7px 10px;
        border-left: 1px solid var(--ds-border-gray);
    }
}

.multiSelectCloseButton {
    display: none !important;
}

.dropdownGroup {
    font-weight: 700;
    color: var(--ds-main-black);
    flex-direction: column;
    flex: 1;
}

.dropdownGroupItem {
    font-size: 14px;
}

.dropdownItem {
    padding-left: 4px;
    font-weight: 300;
    font-size: 14px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--ds-border-gray);
}

@media (max-width: 769px) {
    .dataUploadContainer {
        padding: 24px 16px;

        .dataUpload {
            padding: 16px 16px;
        }

        .toggleButton {
            height: 34px;
        }

        .mainBlock {
            display: flex;
            flex-direction: column;

            .autoCompleteInput {
                width: 228px;
            }
        }
    }

    .multiSelect {
        width: 100%;
    }
}

@media (max-width: 475px) {
    .dataUploadContainer {
        .toggleButton {
            height: 46px;
        }

        .toggleButtons {
            border-radius: 5px;
            border: 1px solid var(--ds-border-gray);
            display: flex;
            align-items: stretch;
            width: 100%;

            .toggleActive {
                & span {
                    font-size: 13px;
                    line-height: 13px;
                }
            }

            .toggleInactive {
                flex-grow: 1;

                & span {
                    font-size: 13px;
                    line-height: 13px;
                }
            }

            .toggleLeft {
                min-width: 153px;
            }

            .toggleRight {
                min-width: 136px;
            }
        }
    }

    .calendarInput {
        min-width: 130px;
        & > input {
            padding: 6px 8px !important;
            font-size: 13px !important;
        }
    }

    .singleBondTemplate {
        height: auto;
        flex-direction: column;
        gap: 8px;

        padding: 12px 18px;

        & div {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
        }
    }
}

@media (max-width: 360px) {
    .dataUploadContainer {
        .mainBlock {
            .autoCompleteInput {
                width: 150px;
            }
        }
    }
}

.calendarHeader {
    & button {
        margin: 0;
        padding: 8px;
    }
}
