.filterBlockWrapper {
    gap: 10px 6px;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: auto hidden;
}

.filterItem {
    position: relative;
    height: 40px;
    padding: 13px 18px 11px;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    cursor: pointer;
    white-space: nowrap;

    font-family: 'Favorit Pro', sans-serif;
    font-size: 16px;
    font-weight: 350;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;
    color: var(--ds-main-gray);

    &.active {
        background: var(--ds-main-black);
        border: none;
        color: var(--ds-main-white);
        text-decoration-line: none;
    }

    &:not(.active)::after {
        content: '';
        position: absolute;
        bottom: 9px;
        left: 18px;
        width: calc(100% - 36px);
        border-bottom: 1px dashed var(--ds-main-gray);
    }
}

@media (max-width: 767px) {
    .filterBlockWrapper {
        gap: 6px;
        flex-wrap: nowrap;
        width: calc(100% - 18px);
        padding-bottom: 10px;
    }
}
