.duplicateIISBlockWrapper {
    margin: 8px 0 33px;
}

.titleWrapper {
    margin-bottom: 20px;

    span {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 14px;
        text-align: left;
        color: var(--ds-main-gray);

        &:first-child {
            width: 194px;
            margin-right: 61px;
        }

        &:nth-child(2) {
            width: 192px;
            margin-right: 64px;
        }
    }
}

.contentWrapper {
    gap: 24px;

    .itemWrapper {
        p {
            font-family: 'Inter', sans-serif;
            font-size: 22px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--ds-main-black);

            span {
                padding-left: 4px;
                font-family: 'Favorit Pro', sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 20px;
                text-align: right;
                color: var(--ds-main-black);
            }

            &:first-child {
                width: 194px;
                margin-right: 61px;
            }

            &:nth-child(2) {
                width: 192px;
                margin-right: 64px;
            }

            &.recommendation {
                padding: 7px 0 4px;
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                text-align: left;
                color: var(--ds-main-black);
            }
        }
    }
}

@media (max-width: 1023px) {
    .duplicateIISBlockWrapper {
        margin: 8px 0 30px;
    }

    .titleWrapper {
        span {
            &:first-child,
            &:nth-child(2) {
                margin-right: 34px;
            }
        }
    }

    .contentWrapper {
        .itemWrapper {
            p {
                &:first-child,
                &:nth-child(2) {
                    margin-right: 34px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .duplicateIISBlockWrapper {
        padding: 10px 0 30px;
    }

    .titleWrapper {
        margin-bottom: 14px;
        gap: 24px;

        span {
            &:first-child,
            &:nth-child(2) {
                width: 100%;
                margin-right: 0;
            }
        }
    }

    .contentWrapper {
        gap: 24px;

        .itemWrapper {
            p {
                font-size: 20px;

                span {
                    font-size: 16px;
                }

                &:first-child {
                    width: calc(50% - 12px);
                    margin-right: 24px;
                }

                &:nth-child(2) {
                    width: calc(50% - 12px);
                    margin-right: 0;
                }

                &.recommendation {
                    padding: 8px 0 0 0;
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--ds-main-dark-gray);
                }
            }
        }
    }
}
