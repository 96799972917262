.currency {
  color: var(--main-grey);
  font-size: 12px;
}

.positiveNumber {
  color: green;
}

.negativeNumber {
  color: red;
}

.table {
  min-height: calc(100vh - 540px);
}

td {
  padding: 12px 20px;

  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: var(--main-grey);
  height: 45px;
}

.tickerColumn {
  width: 145px;
}

.nameColumn {
  width: 295px;
}

.capitalizationColumn {
  width: 145px;
  text-align: end;
}

.lastCostColumn {
  width: 205px;
  text-align: end;
}

.deltaColumn {
  width: 205px;
  text-align: end;
}

.tradingVolumeColumn {
  width: 145px;
  text-align: end;
}

.dividendYieldColumn {
  width: 230px;
  text-align: end;
}

.isFavouriteColumn {
  width: 36px;
  padding: 12px;
}
