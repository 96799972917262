.markDescriptionWrapper {
    gap: 36px;

    .markDescription {
        gap: 12px;

        div {
            width: 36px;
            height: 36px;
            border-radius: 4px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        p {
            font-family: Roboto, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
        }
    }
}

@media (max-width: 767px) {
    .markDescriptionWrapper {
        flex-direction: column;
        gap: 16px;
    }
}
