.sliderWrapper {
    height: 500px;
    position: relative;
}

.textBlock {
    position: relative;
    min-width: 574px;
    max-width: 574px;
    background: linear-gradient(277.23deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);
    padding: 64px 37px 64px 62px;

    & > div {
        overflow: hidden;

        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            transition: transform 0.5s ease;
        }
    }
}

.textItem {
    flex-shrink: 0;
    width: 100%;

    & > p {
        color: var(--ds-main-gray);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 4px;
    }

    & > h2 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        letter-spacing: -1%;
        margin-bottom: 14px;
    }

    & > span {
        display: block;
        font-weight: 350;
        font-size: 16px;
        line-height: 24px;
        color: var(--ds-main-dark-gray);
    }

    & > ul {
        list-style: none;
        & > li {
            font-weight: 350;
            font-size: 16px;
            line-height: 24px;
            color: var(--ds-main-dark-gray);
            position: relative;
            margin-left: 10px;
            padding-left: 23px;

            &:before {
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: var(--ds-main-red);
            }
        }
    }
}

.dotsList {
    z-index: 1;
    position: absolute;
    bottom: 24px;
    left: 62px;
    display: flex;
    gap: 10px;
    align-items: center;
    height: 12px;

    & > div {
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 1px solid var(--ds-main-gray);
        border-radius: 50%;
        cursor: pointer;

        &.activeDot {
            width: 12px;
            height: 12px;
            border: none;
            background-color: var(--ds-main-red);
        }
    }
}

.imageBlock {
    position: relative;
    flex: 1;
    height: 100%;
    overflow: hidden;

    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        transition: transform 0.5s ease;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            flex-shrink: 0;
        }
    }
}

.sliderButton {
    position: absolute;
    bottom: 0;
    left: calc(574px - 60px);
}

@media (max-width: 1359px) {
    .sliderWrapper {
        height: 405px;
    }

    .textBlock {
        min-width: 377px;
        max-width: 377px;
        padding: 47px 24px 48px 47px;
    }

    .textItem {
        & > p {
            font-weight: 700;
            font-size: 15px;
            line-height: 16px;
            margin-bottom: 7px;
        }

        & > h2 {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 19px;
            letter-spacing: normal;
        }

        & > span {
            font-weight: 350;
            font-size: 15px;
            width: 305px;
            line-height: 20px;
        }
    }

    .dotsList {
        left: 48px;
    }

    .sliderButton {
        left: calc(377px - 60px);
    }
}

@media (max-width: 1023px) {
    .sliderWrapper {
        height: 624px;
        flex-direction: column-reverse;
    }

    .textBlock {
        height: 264px;
        min-width: 100%;
        max-width: 100%;
        padding: 35px 24px;
    }

    .textItem {
        & > h2 {
            width: 430px;
        }

        & > span {
            width: 430px;
        }
    }

    .imageBlock {
        width: 100%;
        height: 360px;
    }

    .sliderButton {
        top: 300px;
        left: 24px;
    }

    .dotsList {
        top: calc(360px - 36px);
        right: 24px;
        left: auto;
        width: fit-content;
    }
}

@media (max-width: 767px) {
    .sliderWrapper {
        height: 420px;
    }

    .textBlock {
        height: 220px;
        padding: 28px 18px;
    }

    .textItem {
        & > p {
            width: 100%;
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 8px;
        }

        & > h2 {
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 14px;
            width: 100%;
        }

        & > span {
            font-size: 14px;
            width: 100%;
            line-height: 20px;
        }
    }

    .dotsList {
        top: auto;
        right: 18px;
        bottom: 234px;

        & > div {
            width: 6px;
            height: 6px;

            &.activeDot {
                width: 8px;
                height: 8px;
            }
        }
    }

    .imageBlock {
        height: 200px;
    }

    .sliderButton  {
        top: 160px;
        left: 18px;
    }
}
