.searchWrapper {
  width: 100%;

  & > svg {
    top: 14px;
  }
}

.searchInput {
  width: 100%;
  padding: 12px 40px 12px 16px;
}
