.inputContainer {
  min-width: 384px;
  margin-bottom: 15px;

  & > label {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 5px;
  }

  & > span > i {
    width: 24px;
    height: 24px;
    // need to remove in future and fix it
    margin-top: -8px;
    margin-right: -9px;
    cursor: pointer;
    color: var(--ds-primary-main-black-200);
  }

  .input {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    padding: 9px 30px 9px 18px;
    height: 42px;
    width: 100%;
  }
}
