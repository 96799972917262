.dotWrapper {
    padding: 0 15px;
    margin-top: 7px;
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--ds-main-light-gray);
}

.dotActive {
    background-color: var(--ds-main-red);
}

.card {
    min-width: 100%;
    display: flex;
    padding: 19px 29px 14px 0;
    font-size: 15px;
    color: var(--ds-main-black);
    line-height: 20px;
    font-weight: 350;
}

.clickableCard {
    cursor: pointer;
}

.cardFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.smallText {
    color: var(--ds-main-gray);
    margin: 10px 0 14px 0;
    font-size: 14px;
}

.cardContent {
    max-width: 300px;
    border-bottom: 1px solid var(--ds-main-light-gray);
    flex: 1;

    @media screen and (max-width: 769px) {
        max-width: unset;
        width: 100%;
    }
}

.extraContent {
    margin-top: 15px;
}

.button {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
    padding: 9px 18px;
    background-color: var(--ds-main-red);
}

.imageWrapper {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.image {
    width: 300px;
    max-height: 150px;
    border-radius: 6px;
    box-sizing: border-box;
    aspect-ratio: 1/2;

    @media (max-width: 426px) {
        max-width: 100%;
    }
}

.buttonLink {
    text-decoration: none;
}
