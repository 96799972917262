.wrapper {
    padding: 0 62px 112px 62px;
    margin-top: 80px;

    & > h2 {
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
        margin: 66px 0 22px;
    }

    & > div {
        width: 100%;
        display: grid;
        gap: 56px 60px;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .productsWithoutGuid {
        margin-bottom: 75px;
    }
}

@media (max-width: 1359px) {
    .wrapper {
        padding: 0 24px 43px 24px;
        margin-top: -6px;
        & > h2 {
            margin-bottom: 25px;
            font-size: 32px;
            font-weight: 400;
            line-height: 48px;
        }

        & > div {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            gap: 52px 60px;
        }

        .productsWithoutGuid {
            margin: 30px 0 75px;
        }
    }
}

@media (max-width: 1023px) {
    .wrapper {
        & > h2 {
            margin-bottom: 28px;
        }

        & > div {
            gap: 71px 60px;
        }

        .productsWithoutGuid {
            margin: 30px 0 84px;
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        padding: 0 18px 95px 18px;
        & > h2 {
            margin-bottom: 35px;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        & > div {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
            gap: 44px;
        }

        .productsWithoutGuid {
            margin: 30px 0 70px;
        }
    }
}
