.APIList {
  background-color: var(--ds-bg-extra-light-gray);

  & > p {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 23px;

    & > a {
      text-decoration: none;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      color: var(--ds-main-blue);
    }
  }

  & > button {
    padding: 12px 24px;
    background-color: var(--ds-main-black);
    border-radius: 6px;
    border: none;
    margin-top: 24px;
    margin-bottom: 36px;

    & > span {
      color: var(--ds-main-white);
      font-size: 16px;
      font-weight: 350;
      line-height: 18px;
    }
  }
}

@media (max-width: 767px) {
  .APIList {
    & > p {
      font-size: 16px;

      & > a {
        font-size: 16px;
      }
    }
  }
}
