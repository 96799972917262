.isFavouriteIcon {
  cursor: pointer;
}

.dialog {
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    top: 178px;
    right: 0;
    left: 0;
    background: var(--ds-main-light-gray);
  }
}

.tabWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: calc(100% - 2rem);
}

.tabHeader {
  border-color: transparent;
}

.sortWrapper {
  background-color: #F2F7FE;
}

.p-tabview .p-tabview-nav {
  border-color: transparent;
}
