.titleWrapper {
    cursor: pointer;
    margin-bottom: 26px;
    & > svg {
        width: 24px;
        height: 24px;
        margin-right: 24px;

        &.hide {
            transform: rotate(180deg);
        }
    }
    & > p {
        font-size: 24px;
        font-style: normal;
        font-weight: 350;
        line-height: 24px;
    }
}
.contentContainer {
    width: 100%;
    visibility: visible;
    margin-left: 48px;
    position: relative;
}
.greyLine {
    height: 1px;
    width: 100%;
    background-color: var(--ds-border-gray);
    margin-top: 48px;
    margin-bottom: 40px;
}

@media (max-width: 1599px) {
    .contentContainer {
        margin-left: 0;
    }
    .greyLine {
        margin-top: 36px;
    }
}

@media (max-width: 767px) {
    .greyLine {
        margin-top: 20px;
        margin-bottom: 35px;
        width: calc(100vw - 36px);
    }

    .titleWrapper {
        margin-bottom: 16px;
        & > svg {
            margin-right: 8px;
            width: 18px;
            height: 18px;
        }
        & > p {
            font-size: 18px;
            font-style: normal;
            font-weight: 350;
            line-height: 18px;
        }
    }
}
