.chartContainer {
    width: 734px;
    height: 440px;
    margin-bottom: 80px;
    position: relative;

    .chartAxisTitle {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #576273;
        position: absolute;
        z-index: 10;

        &.xAxis {
            top: 468px;
            left: 22px;
            transform: translateY(-100%);
        }

        &.yAxis {
            right: -10px;
            top: 25px;
            transform: translateX(-100%);
        }
    }

    & > .hidden {
        opacity: 0;
    }

    & > .tooltipWrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--ds-main-white);
        transform: translateY(-50%);
        z-index: 11;

        &.alignRight {
            transform: translateY(-50%) translateX(-100%);
        }

        & > .tooltip {
            width: 240px;
            height: 70px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 3px 0px 3px 8px;
            gap: 3px;
            border: 1px solid var(--ds-border-gray);
            border-radius: 4px;
            box-shadow: 4px 4px 0 0 #4065801a;
            background: white;

            & > .textWrapper {
                display: flex;
                flex: 6;
                flex-direction: column;
                justify-content: space-between;

                & > .lineWrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;

                    & > .title {
                        font: Favorit Pro;
                        color: var(--ds-main-gray);
                    }

                    & > .normalText {
                        font: Inter;
                        color: var(--ds-dtsip-text-black-100);
                    }

                    & > .boldText {
                        font: Inter;
                        font-weight: 700;
                    }
                }
            }
        }

        & > .pinWrapper {
            display: flex;
            flex: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // border-left: 1px solid var(--ds-border-gray);
        }
    }
}

@media (max-width: 1599px) {
    .chartContainer {
        width: 655px;
        height: 460px;
    }
}

@media (max-width: 1023px) {
    .chartContainer {
        width: 720px;
    }
}

@media (max-width: 767px) {
    .chartContainer {
        width: 324px;
        height: 458px;
        margin-bottom: 85px;

        & > .tooltipWrapper {
            width: 166px;
            height: 60px;
            padding: 0px 0px 0px 6px;

            & > .tooltip {
                & > .textWrapper {
                    & > .lineWrapper {
                        font-size: 9px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
