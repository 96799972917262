.disclaimerWrapper {
    padding: 80px 62px;
    display: grid;
    gap: 18px;
    background: #f0f3f7;

    & > p {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: var(--main-dark-gray);
    }
}

@media (max-width: 1363px) {
    .disclaimerWrapper {
        padding: 80px 24px 80px 24px;
    }
}

@media (max-width: 767px) {
    .disclaimerWrapper {
        padding: 60px 18px 60px 18px;
        & > p {
            font-size: 13px;
        }
    }
}
