.riskRatesBlockWrapper {
    padding-bottom: 33px;

    & > span {
        display: block;
        width: 100%;
        height: 28px;
        margin-left: auto;
        margin-right: 0;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 14px;
        text-align: right;
        color: var(--ds-main-gray);
    }

    & > div {
        margin-top: 14px;
        gap: 30px;
    }
}

@media (max-width: 767px) {
    .riskRatesBlockWrapper {
        & > span {
            width: 100%;
            height: auto;
            margin: 0;
            text-align: left;
        }

        & > div {
            margin-top: 17px;
            gap: 20px;
        }
    }
}
