.priceFooterWrapper {
    position: relative;
    padding: 31px 36px 30px;
    height: auto;
    gap: 28px;

    & > div {
        height: 24px;
    }

    button {
        width: 100%;
        height: 56px;
        padding: 16px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-red);
        gap: 8px;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
            color: var(--ds-main-white);
            display: contents;
        }
    }
}

.active {
    height: 56px !important;
    background: var(--ds-bg-green);
    cursor: default;
    display: flex;
    align-items: center;
    border-radius: 6px;
    justify-content: center;
    & > span {
        color: var(--ds-main-white);
    }
}

.discountPriceWrapper {
    position: relative;

    & > .priceWrapper {
        position: relative;
        top: 2px;
    }
}

.oldPriceWrapper {
    position: absolute;
    top: -17px;
    gap: 4px;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    color: #a8aeba;

    &:after {
        content: '';
        position: absolute;
        top: 9px;
        width: 100%;
        height: 1px;
        background: #a8aeba;
    }

    span {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        text-align: right;
        color: inherit;
    }
}

.priceWrapper {
    gap: 4px;
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
    color: var(--ds-main-black);

    span {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: right;
    }
}

.discountWrapper {
    height: 24px;
    padding: 2px 8px 3px;
    border: 1px solid var(--ds-main-red);
    border-radius: 4px;
    gap: 8px;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0;
    text-align: right;
    text-transform: uppercase;
    color: var(--ds-main-red);

    span {
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;
        text-align: right;
        color: inherit;
    }
}

.discountDateWrapper {
    position: absolute;
    bottom: 4px;
    font-family: 'Favorit Pro', sans-serif;
    font-size: 12px;
    font-weight: 350;
    line-height: 20px;
    text-align: left;
    color: var(--ds-main-dark-gray);
}

@media (max-width: 767px) {
    .priceFooterWrapper {
        button {
            height: 48px;
            padding: 12px 24px;
        }
    }

    .active {
        height: 48px !important;
        background: var(--ds-bg-green);
        cursor: default;
        display: flex;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        & > span {
            color: var(--ds-main-white);
        }
    }
}
