.mainLogoContainer {
    background-image: url('../../../../../shared/images/algopack/AlgopackMainBack1920.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 71px 62px 0 62px;
    width: 100%;
    height: 500px;

    .tabsWrapper {
        gap: 5px;
        margin-bottom: 11px;

        & > div {
            height: 36px;
            padding: 6px 12px 6px 6px;
            gap: 8px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: var(--ds-main-dark-gray);
            }
        }
    }

    & > p {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        width: 600px;

        & {
            margin-bottom: 8px;
        }
        & + p {
            margin-bottom: 48px;
        }
    }

    .getAccessButton {
        padding: 16px 30px 16px 20px;
        gap: 8px;
        background-color: var(--ds-main-red);
        border-radius: 6px;
        border: none;
        height: 56px;

        & > span {
            display: block;
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 24px;
            color: var(--ds-main-white);
        }
    }
}

.authBtns {
    gap: 12px;

    a {
        display: flex;
        width: fit-content;
        align-items: center;
        padding: 16px 30px 16px 20px;
        gap: 12px;
        background-color: var(--ds-main-red);
        border-radius: 6px;
        border: none;
        height: 56px;
        text-decoration: none;

        &.startLink {
            background-color: var(--ds-main-white);
            border: 1px solid var(--ds-border-gray);

            span {
                color: var(--ds-main-black);
            }
        }

        & > span {
            text-decoration: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 18px;
            color: var(--ds-main-white);
        }
    }
}

.header {
    width: 654px;
    height: 72px;
    margin-bottom: 32px;

    & > svg {
        width: 654px;
        height: 72px;
        margin-right: 40px;
    }
}

@media (max-width: 1359px) {
    .mainLogoContainer {
        background-image: url('../../../../../shared/images/algopack/AlgopackMainBack1024.png');
        padding: 60px 48px 0 48px;
        height: 400px;

        & > p {
            font-size: 16px;
            line-height: 20px;
            width: 515px;

            & {
                margin-bottom: 1em;
            }

            & + p {
                margin-bottom: 36px;
            }
        }
    }

    .header {
        height: 48px;

        & > svg {
            width: 438px;
            height: 48px;
            margin-right: 26px;
        }
    }

    .tabsWrapper {
        margin-bottom: 13px;
    }
}

@media (max-width: 1023px) {
    .mainLogoContainer {
        padding: 52px 48px 0 48px;

        .header {
            height: 36px;

            & > svg {
                width: 328.5px;
                height: 36px;
            }
        }
    }
}

@media (max-width: 767px) {
    .mainLogoContainer {
        background-image: url('../../../../../shared/images/algopack/AlgopackMainBack360.png');
        padding: 30px 18px;
        height: 571px;

        & > p {
            font-size: 15px;
            width: 324px;
            & + p {
                margin-bottom: 30px;
            }
        }

        .header {
            height: 30px;
            max-width: 100%;
            margin-bottom: 20px;

            & > svg {
                width: 274px;
                height: 30px;
                margin-right: 17px;
            }
        }

        .getAccessButton {
            padding: 12px 20px 12px 14px;
            height: 48px;
            gap: 6px;
            & > svg {
                font-size: 14px;
                width: 20.03px;
                height: 19.13px;
            }
            & > span {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0;
            }
        }
    }

    .authBtns {
        flex-direction: column;
        gap: 6px;

        a {
            width: 100%;
            padding: 12px 20px;
            height: 48px;
            gap: 6px;
            justify-content: center;

            &.startLink {
                padding: 12px 20px 12px 14px;
            }

            & > svg {
                font-size: 14px;
                width: 20.03px;
                height: 19.13px;
            }

            & > span {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0;
            }
        }
    }
}
