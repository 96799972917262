.checkbox {
    height: 24px;
    width: 24px;

    & > div:last-child {
        border-radius: 4px;
        box-shadow: none;
        border: 1px solid var(--ds-border-gray);
        height: 24px;
        width: 24px;
    }

    &.checked {
        & > div:last-child {
            border: none;
            background: var(--ds-main-black);
        }
    }
}
