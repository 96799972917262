.msgWrapper {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > span {
        color: var(--ds-dtsip-text-black-100);
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: center;
    }
}
