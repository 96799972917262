.linkWrapper {
    text-decoration: none;
}

.bigImageCardWrapper {
    width: 100%;
    height: 428px;
    border-radius: 6px;
}

.contentWrapper {
    gap: 16px;
    padding: 189px 48px 0;
}

.infoWrapper {
    span:last-child {
        color: var(--ds-main-white);
    }

    div {
        background: var(--ds-main-white);
    }
}

.mainWrapper {
    gap: 12px;

    h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: var(--ds-main-white);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p {
        font-size: 14px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: var(--ds-main-white);
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.timeWrapper {
    gap: 6px;
    margin-top: 10px;

    .icon {
        color: var(--ds-main-white);

        &::before {
            font-size: 10px;
        }
    }

    .text {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: var(--ds-main-white);
    }
}

@media (max-width: 1359px) {
    .bigImageCardWrapper {
        height: 340px;
    }

    .contentWrapper {
        padding: 111px 38px 0;
    }
}

@media (max-width: 1023px) {
    .bigImageCardWrapper {
        height: 378px;
    }

    .contentWrapper {
        padding: 155px 26px 0;
    }

    .mainWrapper {
        h4 {
            font-size: 18px;
            line-height: 20px;
        }
    }
}
