.loadBlock {
    padding: 24px;
    width: 100%;
    height: 100%;

    & > svg {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
    }

    & > p {
        font-family:
            Favorit Pro,
            sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0;
    }

    & > p.blue {
        margin-bottom: 16px;
        color: var(--ds-main-blue);
    }
    & > span {
        display: block;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0;
        color: var(--ds-main-gray);
        margin-bottom: 16px;
    }
}

.withLoadingBlock {
    padding: 24px;
    width: 100%;
    height: 100%;
    background-color: var(--ds-bg-light-blue);

    & > svg {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
        animation: rotateFrame linear 1s infinite;
    }

    & > p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        color: var(--ds-main-gray);
    }
}

.withErrorBlock {
    padding: 24px;
    width: 100%;
    height: 100%;
    background-color: var(--ds-bg-light-red);

    & > svg {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
    }

    & > p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 12px;
        color: var(--ds-main-gray);
    }

    & > span {
        display: block;
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        text-decoration: underline;
        text-decoration-style: dashed;
        color: var(--ds-main-blue);
    }
}

.withSuccessBlock {
    padding: 24px;
    width: 100%;
    height: 100%;
    background-color: var(--ds-bg-light-green);

    & > svg {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
    }

    & > p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        width: 167px;
        margin-bottom: 12px;
        color: var(--ds-main-black);
    }

    & > span {
        display: block;
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        text-decoration: underline;
        text-decoration-style: dashed;
        color: var(--ds-main-blue);
    }
}

@keyframes rotateFrame {
    0% {
        transform: rotate(-360deg);
    }
}
