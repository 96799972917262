.addEmailWrapper {
    width: 480px;
    padding: 43px 48px 48px;

    & > h2 {
        margin-bottom: 19px;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-transform: uppercase;
    }

    & > p {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
    }

    & > button {
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-black);

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

.inputWrapper {
    width: 100%;
    height: 42px;
    margin-bottom: 50px;

    & > span {
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--ds-main-light-gray);
    }

    input {
        width: 100%;
        height: 100%;
        padding: 9px 12px 9px 18px;
        border: 1px solid #d8dfe6;
        border-radius: 6px;

        &::placeholder {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 15px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
        }
    }
}
