.priceCenterContainer {
    position: relative;

    &:before {
        content: '';
        width: 100%;
        min-width: 100%;
        height: calc(100% - 250px);
        position: absolute;
        z-index: -1;

        background-image: url('./img/bg.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.navContainer {
    background: var(--ds-main-white);
    height: 80px !important;
}

@media screen and (max-width: 769px) {
    .navContainer {
        height: 60px !important;
    }
}
