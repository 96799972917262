.modalWrapper {
    width: 340px;
    padding: 32px 18px;
    gap: 24px;
    & > h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        text-transform: uppercase;
    }

    & > p {
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        white-space: pre-line;
    }

    & > button {
        padding: 7px 18px;
        min-width: 96px;
        width: fit-content;
        border-radius: 6px;
        border: 1px solid var(--ds-main-gray);
        background-color: var(--ds-main-white);

        & > span {
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 18px;
            color: var(--ds-main-black);
        }
    }
}
