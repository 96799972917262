.telephoneInputWrapper {
  display: flex;

  input {
    padding: 18px 16px 15px 18px;
    width: 257px;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid var(--ds-border-gray);
    border-radius: 0 6px 6px 0;
    background: var(--ds-main-white);
    outline: none;
    font-size: 15px;

    &::placeholder {
      font-family: "Inter", sans-serif;
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: var(--ds-main-gray);
    }
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 42px;
  box-sizing: border-box;
  border: 1px solid var(--ds-border-gray);
  background: var(--ds-main-white);
  border-right: none;
  border-radius: 6px 0 0 6px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    overflow: hidden;
  }
}
