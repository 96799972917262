.headerWrapper {
    width: 100%;
    height: 500px;
    background:
        url('../../../../shared/images/svg/landingHeader/cubesXl.svg') center left / auto 100% no-repeat,
        url('../../../../shared/images/cki/headerBgXl.png') center right / auto 100% no-repeat;
}

.contentWrapper {
    padding: 154px 0 0 62px;

    & > h2 {
        width: 637px;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
        text-transform: uppercase;
        color: var(--ds-main-black);
    }

    & > p {
        width: 637px;
        margin-top: 24px;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-black);
    }
}

.buttonWrapper {
    margin-top: 32px;
    gap: 12px;

    button {
        display: flex;
        height: 56px;
        padding: 16px 30px 16px 20px;
        border-radius: 6px;
        gap: 8px;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
            color: inherit;
            margin-top: 4px;
        }
    }

    .redBtn {
        color: var(--ds-main-white);
        background: var(--ds-main-red);
        border: none;
    }

    .whiteBtn {
        color: var(--ds-main-black);
        background: var(--ds-main-white);
        border: 1px solid var(--ds-border-gray);
        padding: 18px 24px;
    }
}

@media (max-width: 1359px) {
    .headerWrapper {
        height: 400px;
        background:
            url('../../../../shared/images/svg/landingHeader/cubesLg.svg') center left / auto 100% no-repeat,
            url('../../../../shared/images/cki/headerBgLg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 104px 0 0 48px;

        & > h2 {
            width: 590px;
        }
    }
}

@media (max-width: 1023px) {
    .headerWrapper {
        height: 360px;
        background:
            url('../../../../shared/images/svg/landingHeader/cubesMd.svg') center left / auto 100% no-repeat,
            url('../../../../shared/images/cki/headerBgLg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 79px 0 0 24px;

        & > h2 {
            width: 425px;
            font-size: 32px;
            line-height: 32px;
        }

        & > p {
            width: 425px;
        }
    }
}

@media (max-width: 767px) {
    .headerWrapper {
        height: 625px;
        background:
            url('../../../../shared/images/svg/landingHeader/cubesSm.svg') left bottom / 144px 288px no-repeat,
            url('../../../../shared/images/cki/headerBgSm.png') right bottom / auto 288px no-repeat;
    }

    .contentWrapper {
        padding: 36px 18px 40px;

        & > h2 {
            width: 100%;
            font-size: 24px;
            line-height: 24px;
        }

        & > p {
            width: 100%;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .buttonWrapper {
        flex-direction: column;

        button {
            justify-content: center;
            width: fit-content;
            height: 48px;
            padding: 12px 20px 12px 12px;
            gap: 8px;

            span {
                font-size: 14px;
                line-height: 24px;
                display: contents;
            }
        }

        .redBtn {
            width: 190px;
        }

        .whiteBtn {
            padding: 14px 24px;
        }
    }
}
