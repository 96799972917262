.pageWrapper {
    display: flex;
    flex-direction: column;
    background-image: url('../../shared/images/png/bg2.png');
    background-size: contain;
    background-repeat: no-repeat;

    .description {
        background-image: url('../../shared/images/png/IisDuplicatesBG.png');
        margin: 39px 19px 0 36px;
    }

    .items {
        position: relative;
        padding: 52px 0 60px;
        margin: 39px 19px 0 36px;

        h1 {
            max-width: 590px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: 0.3px;
            margin: 22px 0 35px;
        }
    }

    .documentation {
        display: flex;
        justify-content: space-between;
        padding: 96px 0 0;
        margin: 0 19px 0 36px;
        max-width: 1528px;

        &_left {
            width: 611px;
        }

        h1 {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: 0.7px;
        }

        .text {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            color: var(--ds-main-black);
            letter-spacing: 0.1px;
            margin: 24px 0 30px;
        }

        .links {
            display: flex;
            flex-direction: column;
            gap: 36px;
            a {
                width: 240px;
                font-family: 'Favorit Pro', sans-serif;
                font-size: 14px;
                font-weight: 350;
                line-height: 12px;
                letter-spacing: 0.4px;
                color: var(--ds-bg-dark-blue);
                text-decoration: underline;
                white-space: nowrap;
                cursor: pointer;
            }
        }

        .info {
            margin-top: 43px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 14px;
            font-weight: 350;
            line-height: 24px;
            color: var(--ds-text-gray-incative);
            letter-spacing: 0.2px;
            .link {
                color: var(--ds-main-red);
            }
        }

        .blocks {
            width: calc(100% - 611px - 50px);
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: -13px;

            .block {
                width: calc(50% - 6px);
                max-width: 427px;

                .blockSize {
                    height: 272px;
                }
            }

            .allBlocksButton {
                margin-top: 12px;
                width: 100%;
                height: 36px;
                padding: auto;
                border-radius: 6px;
                background: var(--ds-bg-dark-blue);
                font-family: 'Inter', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-white);
                border: none;
                cursor: pointer;
            }
        }
    }

    .tariffs {
        display: flex;
        background: var(--ds-bg-extra-light-gray);
        margin: 110px 0 0 0;
        padding: 71px 0 50px 37px;

        h1 {
            margin-top: 3px;
            width: 624px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 34px;
            font-weight: 700;
            line-height: 40px;
        }

        .iisDuplicates {
            width: 60%;
            padding: 0px 20px 0 38px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            .price {
                width: 100%;
                display: flex;
                justify-content: space-between;

                h3 {
                    margin-right: 5px;
                }

                .cost {
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            span {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
            }

            .link {
                color: var(--ds-main-red);
            }
        }
    }

    .gainAccess {
        padding: 4px 0;
        & > div:first-of-type {
            width: 611px;
            padding: 90px 35px 0;
            margin-right: 86px;
        }
    }
}

@media (max-width: 1359px) {
    .pageWrapper {
        .description {
            margin: 25px 10px 0 25px;
        }

        .items {
            padding: 28px 0 60px;
            margin: 33px 33px 0 25px;

            h1 {
                max-width: none;
            }
        }

        .documentation {
            flex-direction: column;
            margin: 30px 20px 0 25px;
            padding: 0;

            &_left {
                width: 100%;
            }

            h1 {
                width: 100%;
            }

            .text {
                margin: 28px 0 30px;
            }

            .blocks {
                width: auto;
                margin-top: 38px;
                justify-content: flex-start;

                .block {
                    width: calc(50% - 6px);
                    max-width: 640px;
                }
            }
        }

        .tariffs {
            padding: 77px 22px 66px 24px;
            margin: 90px 0 0 0;

            h1 {
                width: 28%;
            }

            .iisDuplicates {
                width: 72%;
                padding: 0;
                gap: 18px;

                h2 {
                    margin-bottom: 10px;
                }
            }
        }

        .gainAccess {
            padding: 10px 20px 0 0;
            & > div:first-of-type {
                width: auto;
                padding: 68px 97px 12px 25px;
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 1023px) {
    .pageWrapper {
        .description {
            margin: 25px 24px 0 23px;
        }

        .items {
            margin: 34px 24px 0 25px;
        }

        .documentation {
            margin: 25px 24px 0 25px;

            .text {
                margin: 23px 0 29px;
            }
        }

        .tariffs {
            padding: 77px 24px 70px 24px;

            h1 {
                width: 32.5%;
            }

            .iisDuplicates {
                width: 67.5%;
                padding: 0;
                gap: 18px;

                h2 {
                    margin-bottom: 12px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .pageWrapper {
        .description {
            margin: 18px 17px 0 17px;
        }

        .items {
            margin: 22px 17px 0 20px;

            h1 {
                font-size: 26px;
                line-height: 34px;
                margin: 22px 0 29px;
            }
        }

        .documentation {
            margin: 18px 17px 0 19px;

            h1 {
                font-size: 28px;
                line-height: 36px;
            }

            .text {
                margin: 18px 0 24px;
                font-size: 14px;
                line-height: 18px;
            }

            .links {
                gap: 25px;
                a {
                    width: 100%;
                    white-space: normal;
                    letter-spacing: 0.2px;
                }
            }

            .blocks {
                .block {
                    width: 100%;
                    max-width: 640px;
                }
            }
        }

        .tariffs {
            width: 100%;
            padding: 48px 28px 40px 26px;
            margin: 80px 0 0 0;
            flex-direction: column;

            h1 {
                width: 100%;
                margin-top: 3px;
                font-size: 28px;
                font-weight: 700;
                line-height: 40px;
            }

            .iisDuplicates {
                width: 100%;
                padding: 53px 0 0;
                h2 {
                    margin-bottom: 8px;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 20px;
                }

                span {
                    font-size: 16px;
                }
            }
        }

        .gainAccess {
            padding: 10px 17px 0 0;
            & > div:first-of-type {
                padding: 30px 27px 19px 19px;

                p {
                    width: 100%;
                }
            }
            & > div:nth-of-type(2) {
                padding: 0 0 41px 18px;
            }
        }
    }
}
