.headerComponent {
    &.active {
        & > p {
            color: var(--ds-main-blue);
        }
    }

    & > p {
        width: 286px;
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: 0;
        margin-right: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > svg {
        width: 18px;
        height: 18px;
    }
}

.rotateToBottom {
    transform: rotate(270deg);
}

.rotateToTop {
    transform: rotate(90deg);
}

@media (max-width: 1023px) {
    .headerComponent {
        & > p {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .headerComponent {
        & > p {
            font-size: 15px;
        }
    }
}
