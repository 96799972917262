.container {
  height: 46px;
  border-radius: 6px;
  border: 1px solid var(--ds-border-gray);
  padding: 5px 16px 5px 5px;
  gap: 10px;
  margin-bottom: 6px;
  background-color: var(--ds-bg-extra-light-gray);

  & > div {
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--ds-main-blue);
    background-color: var(--ds-main-blue);
    padding: 12px 18px 13px 18px;

    & > span {
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: var(--ds-main-white);
    }
  }

  & > p {
    font-size: 15px;
    font-style: normal;
    font-weight: 350;
    line-height: 24px;
  }
}

@media (max-width: 767px) {
  .container {
    height: 34px;

    & > div {
      height: 26px;
      padding: 8px;

      & > span {
        font-size: 13px;
      }
    }

    & > p {
      font-size: 13px;
    }
  }
}
