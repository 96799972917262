.controlsWrapper {
    height: 190px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 32px 36px 32px 36px;
    margin: 60px 36px 40px 36px;
    border: 1px solid var(--ds-border-gray);
    background-color: var(--ds-main-white);
    border-radius: 12px;
    caret-color: transparent;
    gap: 6px;

    .selectorWrapper {
        gap: 0px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .labelSelector {
            padding: 8px 18px 8px 18px;
            border-radius: 6px;
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 15px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            display: flex;
            align-items: center;
        }

        .activeSelector {
            background-color: var(--ds-dtsip-text-black-100);
            border-color: (--ds-dtsip-text-black-100);
            color: var(--ds-main-white);
        }
    }

    .dropdownsBlockWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .dropdownWrapper {
            .titleWrapper {
                font-family: Favorit Pro;
                font-size: 14px;
                font-weight: 300;
                line-height: 16px;
                text-align: left;
            }

            .dropdown {
                width: 496px;
            }
        }

        .divider {
            width: 60px;
            height: 0px;
            gap: 0px;
            transform: rotate(90deg);
            border-bottom: 1px solid var(--ds-border-gray);
        }

        .calendarWrapper {
            width: 160px;

            .calendarInput {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
            }
        }
    }
}

@media (max-width: 1599px) {
    .controlsWrapper {
        width: 320px;
        height: 388px;
        padding: 24px 28px 24px 28px;
        margin: 40px 0px 40px 24px;

        .selectorWrapper {
            flex-direction: column;
            width: 264px;
            .labelSelector {
                text-align: center;
            }
        }

        .dropdownsBlockWrapper {
            flex-direction: column;
            align-items: start;
            .dropdownWrapper {
                .dropdown,
                .calendarWrapper {
                    width: 264px;
                }
            }

            .divider {
                display: none;
            }
        }
    }
}
@media (max-width: 1023px) {
    .controlsWrapper {
        width: 720px;
        height: 280px;
        .selectorWrapper {
            flex-direction: row;
            width: 434px;
            .labelSelector {
                text-align: start;
            }
        }
        .dropdownsBlockWrapper {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
            .dropdownWrapper {
                .dropdown {
                    width: 309px;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .controlsWrapper {
        width: 324px;
        height: 332px;
        padding: 8px 18px 8px 18px;
        margin: 40px 18px 30px;

        .selectorWrapper {
            width: 292px;
            flex-direction: row;
            .labelSelector {
                text-align: center;
            }
        }
        .dropdownsBlockWrapper {
            flex-direction: column;
            align-items: start;
            .dropdownWrapper {
                .titleWrapper {
                    font-size: 13px;
                }
                .dropdown,
                .calendarWrapper {
                    width: 292px;

                    .calendar {
                        height: 36px;
                        width: 160px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
    .volatCalendarPanel {
        width: 375px !important;
        top: 435px !important;
        left: 10px !important;
        transform: translate(-5%, 36px) !important;
        z-index: 1000 !important;
    }

    .calendarDropdownButton > [class~='p-icon'] {
        padding: 2px;
        width: 24px !important;
        height: 24px !important;
    }

    .calendarTableBody > tr > td {
        padding: 0;
    }
}

.calendarHeader {
    & button {
        margin: 0;
        padding: 8px;
    }
}
