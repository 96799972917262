.cancelProductModalWrapper {
    width: 480px;
    padding: 48px;
    gap: 32px;

    .descriptionWrapper {
        & > h2 {
            font-family: 'Inter', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 30px;
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 9px;
        }

        & > p {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 15px;
            font-weight: 300;
            line-height: 20px;
            text-align: left;

            &:not(:last-child) {
                margin-bottom: 7px;
            }
        }
    }

    .amountWrapper {
        gap: 8px;

        & > div {
            & > span {
                gap: 4px;
                font-family: 'Favorit Pro', sans-serif;
                font-size: 15px;
                font-weight: 350;
                line-height: 24px;
                text-align: right;

                &:first-child {
                    font-size: 14px;
                    text-align: left;
                    color: var(--ds-main-gray);
                }

                span {
                    font-weight: 300;
                    text-align: left;
                    color: var(--ds-main-gray);
                }
            }
        }
    }

    .paymentWrapper {
        gap: 12px;

        & > p {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
        }
    }

    .btnWrapper {
        gap: 12px;

        button {
            height: 42px;
            padding: 12px 24px 12px 24px;
            border: none;
            border-radius: 6px;

            span {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 18px;
                text-align: left;
                color: inherit;
            }

            &.blackBtn {
                background: var(--ds-main-black);
                color: var(--ds-main-white);
            }

            &.whiteBtn {
                background: var(--ds-main-white);
                color: var(--ds-main-black);
                border: 1px solid var(--ds-main-gray);
            }
        }
    }
}

@media (max-width: 767px) {
    .cancelProductModalWrapper {
        width: 324px;
        padding: 43px 24px 24px;
        gap: 32px;
    }
}
