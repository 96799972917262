.headerWrapper {
    width: 100%;
    // padding: 25px 36px;
}

.headerPanelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
}

.headerLinksBlock {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerNavigationList {
        padding-right: 28px;
    }

    .navigationList {
        display: flex;
        gap: 36px;
        list-style-type: none;

        .navigationItem {
            text-transform: uppercase;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.26px;

            .navigationLink {
                color: var(--ds-dtsip-text-black-100);
                transition: transform 0.3s linear;
            }

            .navigationLink:hover {
                font-weight: 700;
            }
        }
    }
}

// .iconsBtnWrapper {
//   display: flex;
//   gap: 12px;
// }

// @media (max-width: 1359px) {
//   .headerWrapper {
//     padding: 0 24px;
//   }

//   .headerNavigationList {
//     display: none;
//   }

//   .navigationList {
//     flex-direction: column;
//     align-items: baseline;
//     gap: 10px;
//   }
// }
