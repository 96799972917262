.confirmContainer {
  padding: 48px;

  & > .backButtonContainer {
    margin-bottom: 8px;
      cursor: pointer;

    & > svg {
      margin-right: 12px;
    }
    & > * {
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: 20px;
    }
  }

  & > h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  & > div.buttonContainer {
    margin-top: 48px;
    & > *:first-child {
      background-color: var(--ds-main-black);
      color: var(--ds-main-white);
      display: flex;
      min-width: 96px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      border: none;
      margin-right: 12px;
      font-family: "FavoritPro", sans-serif;
      font-weight: 350;

      & > span {
        color: var(--ds-main-white);
        font-weight: 350;
      }
    }

    & > *:last-child {
      color: var(--ds-main-black);
      background-color: var(--ds-main-white);
      display: flex;
      min-width: 96px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      border-color: var(--ds-main-black);
      font-family: "FavoritPro", sans-serif;
      font-weight: 350;

      & > span {
        color: var(--ds-main-black);
        font-weight: 350;
      }
    }
  }
}
