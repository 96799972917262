.authorizeModalWrapper {
    width: 480px;
    padding: 43px 48px;
    gap: 50px;
}

.contentWrapper {
    gap: 19px;

    h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-transform: uppercase;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }
}

.buttonsWrapper {
    gap: 8px;

    button {
        width: calc(50% - 4px);
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: none;
        border-radius: 6px;

        span {
            margin-top: 4px;
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: center;
            color: var(--ds-main-white);
        }

        &.redBtn {
            background: var(--ds-main-red);
        }

        &.blackBtn {
            background: var(--ds-main-black);
        }
    }
}

@media (max-width: 767px) {
    .authorizeModalWrapper {
        width: 324px;
        padding: 32px 18px;
        gap: 24px;
    }

    .contentWrapper {
        h2 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .buttonsWrapper {
        gap: 8px;

        button {
            width: 100%;
        }
    }
}
