.curvesWrapper {
    width: 100%;
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
    background-image: url('../../shared/images/png/bg.png');
    background-size: contain;
    background-repeat: no-repeat;

    .blockWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
}

@media (max-width: 1599px) {
    .curvesWrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 40px;
        gap: 40px;

        .blockWrapper {
            width: 100%;
            justify-content: space-between;
            flex-direction: row;
            align-items: start;
            gap: 18px;
        }
    }
}

@media (max-width: 1023px) {
    .curvesWrapper {
        gap: 40px;

        .blockWrapper {
            gap: 30px;
            align-items: center;
            flex-direction: column;
        }
    }
}

@media (max-width: 767px) {
    .curvesWrapper {
        gap: 30px;
        padding-left: 18px;
        padding-right: 18px;

        .blockWrapper {
            gap: 18px;
            align-items: center;
            flex-direction: column;
        }
    }
}
