.advantageItemWrapper {
    width: calc(50% - 50px);
    gap: 18px;

    & > img {
        width: 60px;
        height: 60px;
    }

    .titleWrapper {
        gap: 10px;
        padding-top: 16px;

        & > h4 {
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }

        & > p {
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-dark-gray);
        }
    }
}

@media (max-width: 1359px) {
    .advantageItemWrapper {
        width: calc(50% - 24px);

        .titleWrapper {
            padding-top: 0;
        }
    }
}

@media (max-width: 1023px) {
    .advantageItemWrapper {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .advantageItemWrapper {
        width: 100%;
        gap: 16px;

        .titleWrapper {
            gap: 8px;

            & > h4 {
                font-size: 18px;
                line-height: 20px;
            }

            & > p {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
