.pageWrapper {
    display: flex;
    flex-direction: column;
    background-image: url('../../shared/images/png/bg.png');
    background-size: contain;
    background-repeat: no-repeat;

    .hidden {
        visibility: hidden;
        height: 500px;
    }

    .contentWrapper > div {
        display: flex;
        flex-direction: row;

        .tableWrapper {
            width: 720px;
            margin: 30px 24px 30px 24px;
        }
    }
}

.toast {
    div {
        display: flex;
        align-items: center;
    }

    padding: 12px 8px 12px 14px;

    background-color: white;

    color: var(--ds-main-black);
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;

    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;

    box-shadow: 0px 2px 10px 0px rgba(47, 156, 235, 0.2);

    button {
        svg {
            color: #636e80;
        }
    }
}

.toastIcon {
    display: flex !important;
    align-items: center;
    justify-content: center;

    margin-right: 16px;

    width: 32px;
    height: 32px;

    font-size: 18px;
    color: #636e80;

    border-radius: 100px;
    background-color: var(--ds-main-light-gray);
}

@media (max-width: 1599px) {
    .pageWrapper {
        flex-direction: row;
        .hidden {
            height: 1000px;
        }
        .contentWrapper > div {
            flex-direction: row;
            flex-wrap: wrap;
            .tableWrapper {
                width: 616px;
                margin: 40px 24px 60px 40px;
            }
        }
    }
}
@media (max-width: 1023px) {
    .pageWrapper {
        flex-direction: column;
        .hidden {
            height: 500px;
        }
        .contentWrapper > div {
            flex-direction: column;
            .tableWrapper {
                width: 720px;
                margin: 30px 24px;
            }
        }
    }
}
@media (max-width: 767px) {
    .pageWrapper {
        .contentWrapper > div {
            .tableWrapper {
                width: 324px;
                margin: 30px 18px;
            }
        }
    }
}
