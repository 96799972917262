.formContainer {
    height: 42px;
    gap: 12px;

    & > input {
        height: 42px;
        width: 42px;
        padding: 6px;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        border-radius: 6px;
        border: 1px solid var(--ds-border-gray);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
