.productCapabilitiesWrapper {
    margin: 150px 62px;
    gap: 90px;
}

@media (max-width: 1359px) {
    .productCapabilitiesWrapper {
        margin: 120px 48px;
        gap: 60px;
    }
}

@media (max-width: 1023px) {
    .productCapabilitiesWrapper {
        margin: 80px 24px;
    }
}

@media (max-width: 767px) {
    .productCapabilitiesWrapper {
        margin: 60px 18px;
        gap: 50px;
    }
}
