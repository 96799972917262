.standartText {
    font-family:
        Roboto Mono,
        monospace;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #18191b;
    word-break: break-all;
}

.greenSpan {
    font-family:
        Roboto Mono,
        monospace;
    color: #3a9e4a;
}

.redSpan {
    font-family:
        Roboto Mono,
        monospace;
    color: #ff0508;
}

.boxBlock {
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    .standartText {
        font-size: 12px;
        line-height: 16px;
    }
}
