.basketContainer {
  border-radius: 6px;
  border: 1px solid var(--ds-border-gray);
  width: 351px;

  .productsContainer {
    padding: 36px 36px 26px 36px;

    .title {
      color: var(--ds-main-black);
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 21px;
    }

    .productItem {
      color: var(--ds-main-gray);
      font-size: 16px;
      font-weight: 350;
      line-height: 20px;
      margin-bottom: 10px;
    }

    .price {
      :nth-child(1) {
        color: var(--ds-main-black);
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      :nth-child(2) {
        color: var(--ds-main-gray);
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }

  .totalContainer {
    height: 70px;
    background: var(--ds-bg-pale-blue);
    padding: 24px 36px;
    justify-content: space-between;

    :first-child {
      color: var(--ds-main-black);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    .totalPrice {
      :first-child {
        color: var(--ds-main-black);
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
      }

      :last-child {
        color: var(--ds-main-gray);
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .basketContainer {
    width: 475px;
  }

  .totalContainer {
    :first-child {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

@media (max-width: 1024px) {
  .basketContainer {
    width: 347px;
  }
}

@media (max-width: 767px) {
  .basketContainer {
    width: 100%;
    max-width: 323px;

    .productsContainer {
      padding: 20px 18px 13px 18px;

      .title {
        font-size: 16px;
        margin-bottom: 11px;
      }

      .productItem {
        font-size: 14px;
        margin-bottom: 3px;
      }

      .price {
        :nth-child(1) {
          font-size: 15px;
          font-weight: 400;
        }

        :nth-child(2) {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }

    .totalContainer {
      height: 60px;
      padding: 18px 20px;
      justify-content: flex-start;

      :first-child {
        font-size: 16px;
        font-weight: 500;
      }

      .totalPrice {
        margin-left: 12px;

        :first-child {
          color: var(--ds-main-black);
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }

        :last-child {
          color: var(--ds-main-gray);
          font-size: 15px;
          font-weight: 300;
          line-height: 20px;
        }
      }
    }
  }
}
