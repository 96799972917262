.trashContainer {
  cursor: pointer;

  span {
    color: var(--ds-main-blue);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 18px;
    border-bottom: 1px dashed var(--ds-main-blue);
    margin-right: 8px;
  }
}

@media (max-width: 767px) {
  .trashContainer {
    span {
      font-size: 12px;
    }
  }
}
