.paymentContainer {
  width: 351px;

  .cardsSection {
    margin-top: 34px;
    margin-bottom: 12px;

    > div:first-child {
      color: var(--ds-main-black);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 11px;
    }
  }

  .buttonSection {
    .button {
      border-radius: 6px;
      background: var(--ds-main-red);
      width: 351px;
      padding: 16px 24px;
      gap: 12px;
      border: none;

      > span {
        color: var(--ds-main-white);
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 1359px) {
  .paymentContainer {
    width: 476px;

    .buttonSection {
      .button {
        width: 476px;
      }
    }
  }

  .cardsSection {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .paymentContainer {
    width: 348px;

    .buttonSection {
      .button {
        width: 348px;
      }
    }
  }
}

@media (max-width: 767px) {
  .paymentContainer {
    width: 324px;

    .buttonSection {
      .button {
        width: 324px;
      }
    }
  }

  .cardsSection {
    margin-top: 19px !important;

    & > div {
      font-size: 16px;
    }

    > div:first-child {
      margin-bottom: 17px;
    }
  }
}
