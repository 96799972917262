.apiKeyModalWrapper {
    width: 480px;
    padding: 48px;
    gap: 32px;

    h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-transform: uppercase;
    }
}

.contentWrapper {
    gap: 16px;
}

.inputWrapper {
    gap: 8px;

    & > label {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: var(--ds-main-black);
    }

    & > input {
        height: 42px;
        padding: 9px 12px 9px 18px;
        border: 1px solid #d8dfe6;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-black);
        text-overflow: ellipsis;
    }
}

.buttonsWrapper {
    gap: 16px;

    button {
        height: 42px;
        padding: 12px 24px;
        border: none;
        border-radius: 6px;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: center;
            color: inherit;
        }

        &.blackBtn {
            gap: 6px;
            padding: 9px 24px 9px 12px;
            background: var(--ds-main-black);
            color: var(--ds-main-white);
        }

        &.whiteBtn {
            background: var(--ds-main-white);
            color: var(--ds-main-black);
            border: 1px solid var(--ds-main-gray);
        }
    }
}

.questionWrapper {
    gap: 8px;

    a {
        text-decoration: none;
    }

    span {
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        color: var(--ds-main-blue);
    }
}

@media (max-width: 767px) {
    .apiKeyModalWrapper {
        width: 326px;
        padding: 24px;
    }
}
