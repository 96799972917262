.apiDataSection {
    padding: 80px 62px;
    gap: 0px;
    background-color: var(--ds-bg-extra-light-gray);

    & > h4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 125% */
        text-transform: uppercase;
        width: 400px;
        margin-top: 24px;
    }

    .apiItem {
        width: 725px;
        height: 72px;
        padding: 24px 0;
        border-bottom: 1px solid var(--ds-border-gray);
        background-color: var(--ds-bg-extra-light-gray);

        &.open {
            border-bottom: none;
        }

        & > h6 {
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        & > svg {
            width: 24px;
            height: 24px;
        }
    }
}

.accordionHeader {
    & > a {
        border: none;
        & > svg {
            display: none;
        }
    }
}

.pythonImage {
    width: 100%;
}

.blackButton {
    padding: 12px 24px;
    background-color: var(--ds-main-black);
    border-radius: 6px;
    border: none;
    margin-top: 24px;
    margin-bottom: 36px;

    & > span {
        color: var(--ds-main-white);
        font-size: 16px;
        font-weight: 350;
        line-height: 18px;
    }
}

.accordionContent {
    border: none;
    border-bottom: 1px solid var(--ds-border-gray);
    background-color: var(--ds-bg-extra-light-gray);
    width: 725px;
}

@media screen and (max-width: 1929px) {
    .apiDataSection {
        gap: 150px;
    }
}

@media (max-width: 1359px) {
    .apiDataSection {
        padding: 80px 24px;
        gap: 18px;

        & > h4 {
            width: 314px;
            margin-top: 12px;
        }

        .apiItem {
            width: 644px;
        }
    }
    .accordionContent {
        width: 644px;
    }
}

@media (max-width: 1023px) {
    .apiDataSection {
        gap: 39px;

        .apiContainer {
            flex-direction: column;
        }

        & > h4 {
            width: 536px;
            height: 60px;
            margin-top: 0;
        }

        .apiItem {
            width: 100%;
        }
    }
    .accordionContent {
        width: 719px;
    }
}

@media (max-width: 767px) {
    .apiDataSection {
        gap: 30px;
        padding: 60px 18px;

        .apiContainer {
            flex-direction: column;
        }

        & > h4 {
            width: 324px;
            height: 50px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px; /* 106.25% */
            text-transform: uppercase;
        }

        .apiItem {
            & > h6 {
                font-size: 20px;
                line-height: 100%; /* 20px */
            }
        }
    }
    .accordionContent {
        width: 324px;
    }
}
