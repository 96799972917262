.notificationsWrapper {
    width: 100%;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.emptyText {
    color: var(--ds-main-gray);
}

.ringIcon path {
    stroke: var(--ds-main-light-gray);
    fill: none;
}
