.checkbox {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #afbccb;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
