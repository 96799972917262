.contentWrapper {
    padding: 19px 0 33px;
}

.activeItems {
    margin-top: 35px;

    & > span {
        display: block;
        width: 100%;
        height: 28px;
        margin-left: auto;
        margin-right: 0;
        font-family: 'Favorit Pro', sans-serif;
        font-size: 14px;
        font-weight: 350;
        line-height: 14px;
        text-align: right;
        color: var(--ds-main-gray);
    }

    & > div {
        margin-top: 14px;
        gap: 30px;
    }
}

.archiveItems {
    margin-top: 42px;

    & > h4 {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 24px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-dark-gray);
        margin-bottom: 30px;
    }

    & > div {
        gap: 30px;
    }
}

.description {
    margin-top: 30px;
    font-family: 'Favorit Pro', sans-serif;
    font-size: 12px;
    font-weight: 350;
    line-height: 20px;
    text-align: left;
    color: var(--ds-main-gray);
}

@media (max-width: 1023px) {
    .contentWrapper {
        padding: 8px 0 30px;
    }
}

@media (max-width: 767px) {
    .contentWrapper {
        padding: 10px 0 30px;
    }

    .activeItems {
        margin-top: 30px;

        & > span {
            width: 100%;
            height: auto;
            margin: 0;
            text-align: left;
        }

        & > div {
            margin-top: 17px;
            gap: 20px;
        }
    }

    .archiveItems {
        margin-top: 30px;

        & > h4 {
            font-size: 18px;
            margin-bottom: 20px;
        }

        & > div {
            gap: 20px;
        }
    }

    .description {
        line-height: 16px;
    }
}
