.goToProductWrapper {
    width: 453px;
    height: 404px;
    background: var(--ds-bg-pale-blue);
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    padding: 0;

    & > svg {
        width: 36px;
        height: 36px;
        margin-bottom: 29px;
        transform: rotate(180deg);
    }

    & > p {
        width: 333px;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: var(--ds-main-gray);
        margin-bottom: 48px;
    }

    & > a {
        height: 42px;
        padding: 12px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-red);
        text-decoration: none;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

@media screen and (max-width: 1929px) {
    .goToProductWrapper {
        padding: 36px 71px;
    }
}

@media (max-width: 1359px) {
    .goToProductWrapper {
        width: 476px;
    }
}

@media (max-width: 1023px) {
    .goToProductWrapper {
        width: 348px;
        padding: 0 71px 0 71px;

        & > p {
            width: 276px;
        }
    }
}

@media (max-width: 767px) {
    .goToProductWrapper {
        width: 348px;
        margin-left: 12px;
        height: 334px;
        padding: 0;

        & > p {
            width: 276px;
            font-size: 15px;
        }

        & > button {
            span {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 375px) {
    .goToProductWrapper {
        max-width: 348px;
        width: 100%;
        min-width: 320px;
        height: 334px;
        margin-left: 10px;
        padding: 0;

        & > p {
            width: 276px;
            font-size: 15px;
        }

        & > button {
            span {
                font-size: 14px;
            }
        }
    }
}
