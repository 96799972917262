.modal {
    width: min(720px, 100%);
}
.wrapper {
    padding: 48px;
    width: 100%;

    & > h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
        text-transform: uppercase;
    }
    & > p {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;
    }
    & > p:last-child {
        margin-bottom: 0;
    }

    & > .checkboxContainer {
        margin-top: 32px;
        margin-bottom: 32px;
        height: 24px;

        & > .checkbox {
            margin-right: 12px;
        }

        & > span {
            font-weight: 350;
            display: block;
            font-size: 14px;
            line-height: 24px;
        }
    }

    & > .buttonContainer {
        gap: 16px;
        height: 42px;

        & > button {
            padding: 12px 24px;
            border-radius: 6px;
            border: 1px solid var(--ds-main-black);

            & > span {
                font-weight: 350;
                font-size: 16px;
                line-height: 18px;
            }
        }

        & > button:first-child {
            background-color: var(--ds-main-black);

            & > span {
                color: var(--ds-main-white);
            }
        }

        & > button:last-child {
            background-color: var(--ds-main-white);

            & > span {
                color: var(--ds-main-black);
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        padding: 32px 18px;

        & > h2 {
            font-size: 16px;
            line-height: 24px;
        }
        & > p {
            font-size: 15px;
            line-height: 18px;
        }

        & > .checkboxContainer {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
}
