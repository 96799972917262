.searchResultsWrapper {
    margin: 60px 62px 120px;
}

.filterWrapper {
    gap: 6px;

    .tabWrapper {
        height: 40px;
        padding: 0 18px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        cursor: pointer;

        &.selected {
            background: var(--ds-main-black);

            span {
                color: var(--ds-main-white);
                text-decoration: none;

                &::before {
                    border-bottom: none;
                }
            }
        }

        span {
            font-size: 16px;
            font-weight: 350;
            text-align: left;
            color: var(--ds-main-gray);
            height: 18px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                background-color: transparent;
                border-bottom: 1px dashed var(--ds-main-gray);
            }
        }
    }
}

.contentWrapper {
    width: 1092px;
    margin-top: 36px;
    gap: 60px;

    & > div {
        gap: 30px;
    }
}

.noContent {
    margin: 42px 0 292px;
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
    text-align: left;
}

@media (max-width: 1359px) {
    .searchResultsWrapper {
        margin: 60px 24px 120px;
    }

    .contentWrapper {
        width: 100%;
    }

    .noContent {
        margin: 43px 0 98px;
    }
}

@media (max-width: 1023px) {
    .searchResultsWrapper {
        margin: 60px 25px 120px;
    }
}

@media (max-width: 767px) {
    .searchResultsWrapper {
        margin: 28px 18px 80px;
    }

    .filterWrapper {
        & > div {
            width: 190px;
            height: 40px;
        }
    }

    .contentWrapper {
        margin-top: 27px;
        gap: 45px;

        & > div {
            gap: 27px;
        }
    }

    .noContent {
        margin: 30px 0 64px;
        font-size: 14px;
    }
}
