.itemWrapper {
    .title {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 18px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
        position: relative;

        svg {
            margin-left: 8px;
            margin-bottom: -4px;
        }
    }

    .price {
        font-family: 'Inter', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 20px;
        text-align: right;
        white-space: nowrap;
        min-width: 110px;

        span {
            margin-left: 4px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            text-align: right;
        }
    }
}

@media (max-width: 1023px) {
    .itemWrapper {
        .price {
            font-size: 20px;

            span {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 767px) {
    .itemWrapper {
        gap: 8px;

        .title {
            font-size: 16px;
            line-height: 20px;
        }

        .price {
            text-align: left;
        }
    }
}
