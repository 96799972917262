.tabViewStyles {
    min-width: 250px;

    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    padding: 24px;
    background: var(--ds-main-white);

    &.nonBorder {
        border: none;
        padding: 34px 0 0;
    }
}

.navContainerStyles {
    ul,
    a,
    span {
        border: none;
    }

    li[data-pc-section='inkbar'] {
        display: none;
    }

    ul {
        display: flex;
        gap: 6px;
    }
}

.headerStyles {
    height: 40px;
    padding: 13px 18px 11px 18px;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    margin-bottom: 22px;

    .headerTitleStyles {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        text-align: left;
        color: var(--ds-main-gray);
        height: 18px;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: transparent;
            border-bottom: 1px dashed var(--ds-main-gray);
        }
    }

    &.selected {
        background: var(--ds-main-black);
        border: none;

        .headerTitleStyles {
            background: var(--ds-main-black);
            color: var(--ds-main-white);
            text-decoration: none;

            &::before {
                border-bottom: none;
            }
        }
    }
}

.contentStyles {
    gap: 22px;
}

.itemWrapper {
    gap: 13px;
    cursor: pointer;

    .titleWrapper {
        gap: 5px;

        span {
            font-size: 18px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                font-size: 14px;
                color: var(--ds-main-gray);
            }
        }
    }

    .descriptionWrapper {
        font-size: 15px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.footerWrapper {
    cursor: pointer;
    width: fit-content;

    .arrowStyle {
        width: 18px;
        height: 18px;
        color: var(--ds-main-gray);
        margin-right: 6px;
    }

    span {
        font-size: 15px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        color: var(--ds-main-blue);
    }
}

.noResults {
    font-size: 18px;
    font-weight: 350;
    line-height: 18px;
    text-align: left;
}

@media (max-width: 600px) {
    .navContainerStyles {
        ul {
            flex-wrap: wrap;
            margin-bottom: 22px;
        }
    }

    .headerStyles {
        margin-bottom: 0;
    }
}
