.avatarContainer {
    margin-bottom: 35px;

    & > img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-right: 18px;
        object-fit: cover;
    }

    .loadButton {
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 18px;
        color: var(--ds-main-gray);
        text-decoration: underline dashed;
        cursor: pointer;
    }

    & input {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .buttonContainer {
        height: 24px;
        margin-right: 18px;

        & > svg {
            margin-right: 4px;
        }
    }
}
