.big_carousel {
    width: 100%;
    display: flex;

    &_inner {
        display: flex;
        justify-content: space-between;
        position: relative;

        .prev,
        .next {
            position: absolute;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            border: none;
            cursor: pointer;
            z-index: 1;
            width: 30px;
            height: 30px;

            &:hover {
                background-color: rgba(100, 100, 100, 0.5);
            }
        }

        .prev {
            left: 50%;
        }

        .next {
            left: calc(50% + 30px);
        }
    }

    .carousel_text_block {
        width: 260px;
        padding: 20px 0;
        background: #f5f7fa;
        display: flex;
        flex: 1;
        overflow: hidden;
    }

    .carousel_text_inner {
        height: 100%;
        display: flex;
        transition: transform 0.5s ease;
    }

    .carousel_text {
        height: 100%;
        width: 260px;
        padding: 0 20px;
    }
}
