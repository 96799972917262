.cartHeaderWrapper {
  height: 300px;
  width: 100%;
  background: url("../../../../shared/images/png/cartHeaderLarge.png") center /
    100% 100% no-repeat;
  padding: 185px 0 0 62px;

  h1 {
    font-size: 80px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -1px;
  }
}

@media (max-width: 1599px) {
  .cartHeaderWrapper {
    height: 240px;
    background: url("../../../../shared/images/png/cartHeaderMedium.png") center /
      100% 100% no-repeat;
    padding: 150px 0 0 34px;

    h1 {
      font-size: 60px;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: -1px;
    }
  }
}

@media (max-width: 1024px) {
  .cartHeaderWrapper {
    height: 240px;
    overflow: hidden;
    background: url("../../../../shared/images/png/cartHeaderSmall.png") center /
      100% 100% no-repeat;
    padding: 150px 0 0 34px;
  }
}

@media (max-width: 767px) {
  .cartHeaderWrapper {
    height: 180px;
    background: url("../../../../shared/images/png/cartHeaderMobile.png") center /
      100% 100% no-repeat;
    padding: 129px 0 0 18px;

    h1 {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: -0.6px;
      line-height: 27px;
    }
  }
}
