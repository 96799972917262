.wrapper {
    padding: 90px 300px 92px 60px;
    gap: 60px;
    width: 100%;

    & > div:first-child {
        width: 310px;
        min-width: 310px;

        & > h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            text-transform: uppercase;
            margin-bottom: 24px;
        }
    }

    & > div:last-child {
        flex: 1;
        width: 861px;
    }

    & > div.accordionOuterWrapper {
        width: 100%;
        & a {
            & > svg {
                display: none;
            }
        }
    }
    .accordionOuterHeader {
        height: 56px;
        min-width: 100%;
        background-color: var(--ds-main-red);
        padding: 16px 24px 16px 24px;
        border-radius: 6px;
        & > p {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0;
            color: var(--ds-main-white);
        }

        & > svg:first-child {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        & > svg:last-child {
            width: 18px;
            height: 18px;
            margin-left: auto;

            &.rotated {
                transform: rotate(180deg);
            }
        }
    }

    .contentOuter {
        margin: 32px;
        border: none;
    }
}

@media (max-width: 1359px) {
    .wrapper {
        padding: 72px 24px;
        gap: 24px;

        & > div:first-child {
            width: 225px;
            min-width: 225px;
        }

        & > div:last-child {
            width: 726px;
        }
    }
}

@media (max-width: 1023px) {
    .wrapper {
        padding: 25px 24px 72px;
        gap: 72px;

        & > div:first-child,
        & > div:last-child {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .wrapper {
        padding: 42px 18px 60px 18px;

        .contentOuter {
            margin: 24px;
        }

        .accordionOuterHeader {
            height: 48px;
            padding: 12px 30px 12px 16px;

            & > p {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 360px) {
    .wrapper {
        gap: 40px;
    }
}
