@import '../../../../../shared/commonStyles/media-css-variables';

.dataConstitution {
    padding: 80px 64px;
    width: 100%;
    background: linear-gradient(277.23deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);
}

.titlesWrapper {
    & > h2 {
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
    }

    & > div {
        margin: 16px 0 0;
        min-width: 726px;

        span {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: var(--ds-main-black);
            width: 100%;
        }
    }
}

.dataItemsWrapper {
    gap: 31px;
    margin-top: 68px;
}

@media (max-width: $lg) {
    .dataConstitution {
        padding: 80px 24px;
    }

    .titlesWrapper {
        & > h2 {
            font-size: 32px;
            line-height: 32px;
        }

        & > div {
            margin: 4px 0 0;
            min-width: 408px;
            gap: 24px;

            span {
                text-align: center;
            }
        }
    }

    .dataItemsWrapper {
        gap: 40px;
        margin-top: 55px;
    }
}

@media (max-width: $md) {
    .titlesWrapper {
        & > div {
            margin: 0;
            min-width: 254px;
            gap: 24px;

            span {
                font-size: 14px;
                line-height: 18px;
                text-align: center;
            }
        }
    }

    .dataItemsWrapper {
        gap: 40px;
        margin-top: 80px;
    }
}

@media (max-width: $sm) {
    .dataConstitution {
        padding: 60px 18px;
    }

    .titlesWrapper {
        & > h2 {
            font-size: 20px;
            line-height: 20px;
        }
    }

    .dataItemsWrapper {
        gap: 32px;
        margin-top: 48px;
    }
}
