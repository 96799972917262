.confirmContactRequestFormWrapper {
    width: 480px;
    height: 344px;
    padding: 60px 48px 48px;
    gap: 32px;

    & > div {
        padding: 24px 0;
        gap: 16px;

        & > svg {
            width: 36px;
            height: 36px;
        }

        & > h2 {
            font-size: 18px;
            font-weight: 350;
            line-height: 24px;
            text-align: center;
        }
    }

    & > button {
        padding: 16px 24px;
        width: 100%;
        height: 56px;
        border: none;
        border-radius: 6px;
        background-color: var(--ds-main-black);

        & > span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 767px) {
    .confirmContactRequestFormWrapper {
        width: 324px;
        height: 240px;
        padding: 32px 24px 24px;
        gap: 14px;

        & > div {
            padding: 18px 0;
            gap: 22px;

            & > h2 {
                font-size: 14px;
                font-weight: 300;
                line-height: 18px;
            }
        }

        & > button {
            padding: 8px 16px;
            height: 40px;
        }
    }
}
