.hackathonSection {
  height: 530px;
  width: 100%;

  & > * {
    width: 50%;
  }

  .hackathonInfo {
    padding: 80px 138px 227px 63px;
    background: linear-gradient(277deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);

    & > h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 100%; /* 40px */
      text-transform: uppercase;
      margin-bottom: 56px;
    }

    & > p {
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 1em;

      & + p {
        margin-bottom: 0;
      }
      & > b {
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 1359px) {
  .hackathonSection {
    height: 521px;

    .hackathonInfo {
      padding: 80px 18px 73px 24px;

      & > h2 {
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .hackathonSection {
    height: auto;
    flex-direction: column;

    & > * {
      width: 100%;
    }

    .hackathonInfo {
      padding: 80px 24px 121px 24px;
      & > h2 {
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 767px) {
  .hackathonSection {
    .hackathonInfo {
      padding: 60px 18px 21px 18px;

      & > h2 {
        font-size: 20px;
      }

      & > p {
        font-size: 15px;
        font-weight: 300;
        line-height: 18px;
      }
    }
  }
}
