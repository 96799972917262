.sharedTitleWrapper {
    //display: flex;
    //justify-content: space-between;
    align-items: center;
}

.linkWrapper {
    gap: 10px;
    text-decoration: none;

    p {
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
    }
}

.buttonsWrapper {
    gap: 8px;

    button {
        background: var(--ds-bg-extra-light-gray);
        height: 32px;
        padding: 0 16px;
        border: none;
        border-radius: 4px;

        &.iconBtn {
            padding: 4px;
        }

        span {
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            color: var(--ds-main-dark-gray);
        }
    }

    .sharedButton {
        background: var(--ds-bg-extra-light-gray);
        height: 32px;
        padding: 0 16px;
        border: none;
        border-radius: 4px;
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            color: var(--ds-main-dark-gray);
        }

        .category {
            color: var(--ds-main-blue);
        }

        div {
            width: 1px;
            height: 11px;
            background: var(--ds-main-dark-gray);
            margin: 0 10px 1px;
        }
    }
}

.overlayPanelWrapper {
    padding: 43px 48px 36px;
    border: 1px solid #d8dfe6;
    border-radius: 6px;

    & > button {
        top: 9px;
        right: 9px;
        background: transparent;
        color: #d8dfe6;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    &::before,
    &::after {
        display: none;
    }

    h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
    }

    .socialWrapper {
        gap: 24px;
        margin-top: 36px;

        button {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 100%;
            background: var(--ds-bg-pale-blue);
        }
    }
}

@media (max-width: 767px) {
    .sharedTitleWrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 34px;
    }
}
