.contactRequestForm {
    width: 608px;
    min-height: 553px;
    padding: 43px 48px 48px;
    border: 1px solid var(--ds-main-light-gray);
    border-radius: 6px;
    gap: 32px;

    .errorBlock {
        display: block !important;
        background: rgba(255, 51, 0, 0.05);
        padding-left: 24px;
        position: relative;

        p {
            font-size: 14px;
            margin: 13px 0;
            font-weight: 300;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: var(--ds-error-red);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 2px;
            background: var(--ds-error-red);
        }
    }

    & > h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        color: var(--ds-main-black);
        text-align: left;
        text-transform: uppercase;
    }

    .form {
        gap: 32px;

        .formFields {
            display: flex;
            flex-direction: column;
            gap: 10px;

            & > .input {
                font-size: 15px;
                font-weight: 300;
                line-height: 24px;
                width: 100%;
                height: 42px;
                color: var(--ds-main-black);
                padding: 9px 12px 9px 18px;

                &.redBorder {
                    border-color: var(--ds-main-red);
                }

                &::placeholder {
                    font-size: 14px;
                    color: var(--ds-placeholder);
                }
            }
        }

        & > p {
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;

            a {
                font-size: 14px;
                font-weight: 350;
                line-height: 18px;
                text-align: left;
                text-decoration: underline;
                text-underline-offset: 3px;
                color: var(--ds-main-blue);
                position: relative;
            }
        }

        & > button {
            width: 100%;
            height: 56px;
            padding: 16px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--ds-main-red);
            border: none;

            & > span {
                color: var(--ds-main-white);
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: 767px) {
    .contactRequestForm {
        width: 100%;
        min-height: 534px;
        padding: 36px 18px 24px;
        gap: 30px;

        & > h3 {
            font-size: 20px;
        }

        .form {
            gap: 30px;

            & > p {
                font-size: 13px;

                a {
                    font-size: 13px;
                }
            }
        }
    }
}
