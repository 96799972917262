.inputLabel {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    width: 100%;
    color: var(--ds-text-black);

    &:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
}

.calendarContainer {
    // margin-top: 12px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.modalMobileTitle {
    font-size: 18px;
    font-weight: 400;
    text-align: left;

    color: var(--ds-text-black);
}

.inputWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.blockWrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.calendarInput {
    height: 36px;
    width: 100%;
    max-width: 144px;
    border-radius: 6px;
    border-right: none !important;

    & > input {
        border-right: none !important;

        min-width: 4rem;
        padding: 6px 0px 6px 12px !important;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 350 !important;
        line-height: 16px;
    }

    & > button {
        border-left: none !important;
        background-color: var(--ds-main-white);
        color: var(--ds-main-blue);
        padding: 8px 0 !important;
        width: 36px;
    }

    & [class~='p-datepicker'] {
        width: 375px;
        transform: translate(-40%, 0%) !important;
        z-index: 1000 !important;
    }

    & [class~='p-icon'] {
        padding: 2px;
        width: 24px !important;
        height: 24px !important;
    }

    & td {
        padding: 0;
        width: 36px !important;
        height: 36px;

        & > span {
            width: 36px;
            height: 36px;
            margin: 0;
            padding: 0;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 11px;
        }
    }
}

.sectionWrapper {
    width: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > p {
        display: inline;
        color: var(--ds-text-black);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -1%;
        text-transform: uppercase;
        text-align: left;
    }
}

.dropdownGroup {
    font-weight: 700;
    color: var(--ds-main-black);
    flex-direction: column;
    flex: 1;
}

.dropdownGroupItem {
    font-size: 14px;
}

.dropdownItem {
    padding-left: 4px;
    font-weight: 300;
    font-size: 14px;
}

.dropdownInput {
    height: 36px;
    border-radius: 6px;
    width: 100%;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-text-gray-incative);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > div {
        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.h36px {
    height: 36px;
}

.accordionHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    & > p {
        color: var(--ds-main-black);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -1%;
        text-transform: uppercase;
        text-align: left;
    }
}

.columnsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.extendedSearchWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    gap: 30px;
}

.createListInput {
    height: 36px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > input {
        padding: 6px 12px 6px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        border: none;
    }

    & > button {
        border: none;
        background-color: var(--ds-main-white);
        color: var(--ds-main-light-gray);
    }
}

.subHeader {
    color: var(--ds-text-black);
}

.createListButton {
    background-color: var(--ds-main-white);
    color: var(--ds-main-black);
    padding: 6px 16px 6px 16px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span:first-child {
        padding: 0 5px;
        color: var(--ds-main-blue);
    }
}

.yieldInputsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.profitInput {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    color: var(--ds-main-gray);
    padding: 8px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
}

.extendedSearchLabel {
    cursor: pointer;
    align-self: start;
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    color: var(--ds-bg-dark-blue);
    border-radius: 0px;
    border-bottom: 1px dashed var(--ds-bg-dark-blue);
    height: 18px;
}

.isinMultiselectHeader {
    padding: 8px 8px;
    display: flex;
    justify-content: space-between;
    gap: 4px;

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.multiSelectHeaderCheckbox[data-p-highlight='true'] {
    border-color: var(--ds-bg-dark-blue);
    background: var(--ds-bg-dark-blue);
}

.isinMultiSelectFilterInput {
    & input {
        height: 36px;
        color: var(--ds-main-gray);
        padding: 4px 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & svg {
        transform: translate(50%, -50%) !important;
        margin-right: 8px;
    }
}

.blockParamSearchInput {
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    position: relative;
    width: 100%;

    & > div {
        color: var(--ds-main-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & [class~='p-multiselect-label'] {
        padding: 8px 18px;
        color: var(--ds-text-gray-incative);
    }

    & [class~='p-multiselect-trigger'] {
        width: 12px;
        margin-right: 13px;
    }

    & [class~='p-multiselect-panel'] {
        width: 100% !important;
    }

    & [class~='p-dropdown-panel'] {
        width: 100% !important;
    }
}

.pinButtonCount {
    margin-left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #ff5b01;
    color: var(--ds-main-white);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .dropdownInput {
        max-width: 315px;
        width: 100%;
    }

    .calendarInput {
        & > input {
            padding: 6px 12px 6px 14px;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 350;
            line-height: 16px;
        }
    }
}

@media screen and (max-width: 767px) {
    .sectionWrapper {
        flex-direction: column;
        text-align: left;
        align-items: normal;
    }

    .blockWrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 475px) {
    .modalMobileTitle {
        font-size: 16px;
    }
    .dropdownInput {
        max-width: 100%;
        width: 100%;

        & > span {
            font-size: 13px;
        }

        & [class~='p-dropdown-trigger'] {
            width: 18px;
        }
    }
    .blockParamSearchInput {
        & [class~='p-multiselect-trigger'] {
            width: 18px;
        }
        & > span {
            font-size: 13px;
        }
    }

    .sectionWrapper {
        & > p {
            font-size: 13px;
            font-weight: 500;
        }
    }

    .subHeader {
        color: var(--ds-text-black);
        margin-bottom: 12px;
        font-size: 13px;
    }

    .accordionHeader {
        font-size: 13px;

        & > p {
            font-size: 13px;
            font-weight: 500;
        }
    }

    .calendarInput {
        height: 36px;
        width: 160px;
        border-radius: 6px;

        & > input {
            font-size: 13px !important;
        }

        & [class~='p-datepicker'] {
            width: auto;
            transform: translate(-20%, 0px) !important;
            z-index: 1000 !important;
        }
    }
}
