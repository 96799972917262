.multiselectPanel {
    padding: 12px 6px;
    box-shadow: 8px 8px 0px 0px #40658026;
    border: 1px solid #d8e1eb;
}

.multiselectItem {
    & > span {
        & > span {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            padding: 6px 12px;
            color: #576273 !important;
        }

        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    border-radius: 4px;
    background-color: transparent;

    .multiselectItemCheckedMark {
        width: 50px;
        display: flex;
        justify-content: center;
    }

    svg {
        width: 20px;
        height: 20px;
    }

    .addIcon {
        width: 14px;
        height: 14px;

        path {
            stroke: #a5b1c0;
        }
    }

    &:hover {
        background-color: #b7cee533;

        .addIcon path {
            stroke: #636e80;
        }
    }

    &.active {
        color: #576273 !important;
    }

    & > div {
        display: none;
    }

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.controlsWrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 720px;
    padding: 32px 36px 32px 36px;
    border: 1px solid var(--ds-border-gray);
    background-color: var(--ds-main-white);
    border-radius: 12px;
    caret-color: transparent;
    gap: 6px;

    .selectorWrapper {
        width: 427px;
        gap: 0px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .labelSelector {
            padding: 8px 18px 8px 18px;
            border-radius: 6px;
            font-family: 'Favorit Pro', sans-serif;
            font-size: 15px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            display: flex;
            align-items: center;
        }

        .activeSelector {
            background-color: var(--ds-dtsip-text-black-100);
            border-color: (--ds-dtsip-text-black-100);
            color: var(--ds-main-white);
        }
    }

    .dropdownsBlockWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .smallDropdown {
            height: 42px;
        }

        .rateMultiSelectDropdown {
            padding: 12px 0px 8px 18px;

            & > span,
            & > div {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--ds-dtsip-text-black-100);

                & > svg {
                    width: 12px;
                    color: var(--gray-500);
                    margin-left: 10px;
                    margin-bottom: 5px;
                }
            }
        }

        .calendarWrapper {
            & > div {
                height: 36px;
            }

            & > span > input {
                font-family: Inter, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--ds-dtsip-text-black-100);
            }
        }
    }

    .chipsWrapper {
        flex-direction: column;
        gap: 16px;

        .chipsContainer {
            display: flex;
            flex-direction: row;
            gap: 8px;
            width: 100%;
            flex-wrap: wrap;

            .infoChip {
                max-width: 150px;
                min-width: 93px;
            }
        }

        .addButtonBlock {
            position: relative;
            width: 128px;
            height: 36px;

            & > span {
                height: 36px;
            }

            .addChipButton {
                position: absolute;
                top: 0;
                left: 0;
                height: 36px;
                border-radius: 6px;
                padding: 6px 16px;
                width: 100%;
                background-color: var(--ds-bg-dark-blue);
                border: none;
                cursor: pointer;

                & > span {
                    font-weight: 400;
                    color: var(--ds-main-white);
                    font-family: 'Inter', sans-serif;
                    font-size: 13px;
                    line-height: 20px;
                }
            }

            .stylesChipCalendarInput {
                opacity: 0;
            }

            .calendarInput {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
                width: 130px;
            }

            .rateDropdown {
                cursor: pointer;
                opacity: 0;
                width: 100%;
                box-sizing: border-box;

                .rateDropdownItem {
                    height: 100%;
                    padding: 5px;
                }
            }
        }
    }

    .timeDropdown {
        width: 100%;

        & > span {
            padding: 10px 12px 10px 18px;
        }
    }
}

.infoChip {
    border-radius: 6px;
    height: 36px;
    padding: 10px 6px 10px 18px;
    background-color: var(--ds-main-white);
    border: 1px solid var(--ds-main-gray);

    & > span {
        display: block;
        margin-right: 18px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
    }

    .removeIcon {
        cursor: pointer;
    }
}

.titleWrapper {
    margin-bottom: 8px;

    & > p {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        margin-right: 8px;
    }
}

@media (max-width: 1599px) {
    .controlsWrapper {
        width: 324px;
        margin: 0;
        margin-top: 20px;
        padding: 16px;
        font-size: 13px;
        gap: 0px;

        & > div {
            width: 100%;
        }

        .selectorWrapper {
            width: 100%;
            height: 70px;
            flex-direction: column;

            .labelSelector {
                text-align: center;
                vertical-align: middle;
                justify-content: center;
            }
        }

        .chipsWrapper .chipsContainer {
            flex-direction: column;

            .infoChip {
                width: 100%;
                max-width: 100%;
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
            }
        }

        .dropdownsBlockWrapper {
            flex-direction: column;
            align-items: start;

            & > div {
                width: 100%;

                &:first-child > div {
                    width: 100%;

                    & > div {
                        width: 100%;
                    }
                }

                &:nth-child(2) > div {
                    width: 100%;

                    & > div {
                        width: 100%;
                    }
                }

                &:last-child > div {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .controlsWrapper {
        width: 100%;
        max-width: 698px !important;
        margin: 0;
        padding: 32px 36px 32px 36px;

        .selectorWrapper {
            flex-direction: row;
            width: 427px;
            height: 36px;
        }

        .bottomSection .dropdownsBlockWrapper {
            flex-direction: row;
        }

        .chipsWrapper {
            flex-direction: row;

            .chipsContainer {
                flex-direction: row;
            }

            .infoChip {
                width: 100%;
                max-width: 150px !important;
                min-width: 93px;
            }
        }
    }
}

@media (max-width: 767px) {
    .controlsWrapper {
        max-width: 324px;
        margin: 0;
        padding: 16px;
        font-size: 13px;

        .selectorWrapper {
            width: 100%;
            height: 100%;
            margin-bottom: 24px;

            .labelSelector {
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                width: 100%;
            }
        }

        .dropdownsBlockWrapper {
            flex-direction: column;
            align-items: start;
        }

        .chipsWrapper {
            .chipsContainer {
                .infoChip {
                    width: 100%;
                    padding: 2px 6px;
                    max-width: 84px !important;
                    min-width: 55px;
                    height: 24px;

                    & > span {
                        font-size: 9px;
                        font-weight: 400;
                        margin-right: 8px;
                    }
                }

                margin-bottom: 16px;
            }
        }
    }
    .scatterCalendarPanel {
        width: 375px !important;
        transform: translate(-5%, 36px) !important;
        z-index: 1000 !important;
    }

    .calendarDropdownButton > [class~='p-icon'] {
        padding: 2px;
        width: 24px !important;
        height: 24px !important;
    }

    .calendarTableBody > tr > td {
        padding: 0;
    }
}

@media (max-width: 400px) {
    .scatterCalendarPanel {
        width: 300px !important;
        left: 30px !important;
    }
}

.calendarHeader {
    & button {
        margin: 0;
        padding: 8px;
    }
}
