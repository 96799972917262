.navigationItemWrapper {
    width: 100%;
    padding: 6px 125px 0 0;
    gap: 18px;

    & > img {
        width: 60px;
        height: 60px;
    }

    &.borderBtm {
        padding-bottom: 30px;
        border-bottom: 1px solid var(--ds-border-gray);
    }
}

.contentWrapper {
    padding: 16px 0 0 0;

    & > h4 {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin-bottom: 8px;
    }

    & > p {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
        color: var(--ds-main-dark-gray);
        margin-bottom: 24px;
    }

    & > button {
        width: 151px;
        height: 42px;
        padding: 9px 24px 9px 12px;
        border: 1px solid var(--ds-main-gray);
        border-radius: 6px;
        background: transparent;

        svg {
            margin-right: 6px;
        }

        span {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-black);
        }
    }
}

@media (max-width: 1359px) {
    .navigationItemWrapper {
        padding: 0;

        &.borderBtm {
            padding-bottom: 30px;
            border-bottom: 1px solid var(--ds-border-gray);
        }
    }

    .contentWrapper {
        & > h4 {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 1023px) {
    .navigationItemWrapper {
        padding: 0;
        flex-direction: column;

        &.borderBtm {
            padding-bottom: 24px;
            border-bottom: 1px solid var(--ds-border-gray);
        }
    }

    .contentWrapper {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .navigationItemWrapper {
        gap: 16px;
    }

    .contentWrapper {
        & > h4 {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 8px;
        }

        & > p {
            font-size: 14px;
            line-height: 18px;
        }

        & > button {
            width: 143px;
            height: 36px;
            padding: 6px 18px 6px 10px;
        }
    }
}
