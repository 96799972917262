.infoModalWrapper {
    margin: 48px;
    width: 570px;
    gap: 32px;

    .contentWrapper {
        & > h3 {
            margin-bottom: 36px;
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            text-transform: uppercase;
        }

        & > p {
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            text-align: left;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            & > a {
                font-family:
                    Favorit Pro,
                    sans-serif;
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                text-align: left;
                text-decoration: none;
                color: var(--ds-main-blue);
            }
        }
    }

    .btnWrapper {
        gap: 12px;

        & > button {
            height: 42px;
            padding: 12px 24px 12px 24px;
            border-radius: 6px;
            cursor: pointer;

            span {
                font-family:
                    Favorit Pro,
                    sans-serif;
                font-size: 16px;
                font-weight: 350;
                line-height: 18px;
                text-align: left;
                color: inherit;
            }

            &.blackBtn {
                background: var(--ds-main-black);
                color: var(--ds-main-white);
                border: none;
            }

            &.whiteBtn {
                background: var(--ds-main-white);
                color: var(--ds-main-black);
                border: 1px solid var(--ds-main-gray);
            }
        }
    }
}

@media (max-width: 767px) {
    .infoModalWrapper {
        width: 280px;
        margin: 20px;
    }
}
