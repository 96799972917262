.section {
    padding: 76px 62px 68px 62px;
}

.mainContainer {
    width: 980px;
}

.safetyWrapper {
    width: 100%;
    padding-right: 50px;
}

.accountCardWrapper {
    margin-right: 45px;
    margin-top: -142px;
}

.safetyOuterWrapper {
    margin-top: -8px;
}

.modalWrapper {
    width: 480px;

    & > div {
        padding: 48px;

        & > div {
            & > h2 {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 24px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                text-transform: uppercase;
                color: var(--ds-main-black);
                margin-bottom: 19px;
            }

            & > p {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                text-align: left;
                color: var(--ds-main-black);
                margin-bottom: 32px;
            }

            & > button {
                width: 127px;
                height: 42px;
                padding: 12px 24px 12px 24px;
                gap: 12px;
                border: none;
                border-radius: 6px;
                background: var(--ds-main-black);

                span {
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 18px;
                    text-align: left;
                    color: var(--ds-main-white);
                }
            }
        }
    }
}

@media (max-width: 1599px) {
    .section {
        padding: 0 24px;
    }

    .safetyWrapper {
        padding-right: 0;
    }

    .mainContainer {
        width: 100%;
    }

    .accountCardWrapper {
        margin-top: -67px;
        margin-bottom: 60px;
    }
}

@media (max-width: 1023px) {
    .accountCardWrapper {
        margin-right: 0;
        margin-bottom: 52px;
    }
}

@media (max-width: 767px) {
    .section {
        padding: 0;
    }

    .section > * {
        padding: 0 18px;
    }
    .accountCardWrapper {
        margin-top: -55px;
        margin-bottom: 40px;
    }

    .safetyWrapper {
        flex-direction: column;
    }
    .safetyOuterWrapper {
        padding: 8px 18px;
    }

    .mainContainer {
        padding: 0;

        & > *:not(.safetyOuterWrapper) {
            padding: 0 18px;
        }
        & > *:first-child {
            padding: 0 0 0 18px;
        }
    }

    .modalWrapper {
        width: calc(100% - 60px);

        & > div {
            padding: 24px;
        }
    }
}
