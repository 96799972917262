.sendQuestionModalWrapper {
    width: 438px;
    padding: 36px 48px;

    & > h2 {
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 9px;
    }

    & > p {
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
    }
}

.errorBlock {
    background: rgba(255, 51, 0, 0.05);
    margin-top: 15px;
    padding: 13px 0 13px 24px;
    gap: 10px;
    position: relative;

    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-error-red);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2px;
        background: var(--ds-error-red);
    }
}

.errorBlock + .formWrapper {
    margin-top: 25px;
}

.formWrapper {
    margin-top: 40px;
}

.controller {
    margin-bottom: 15px;

    label {
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-black);
        margin-bottom: 5px;
    }

    input {
        height: 42px;
        padding: 0 18px;
    }

    textarea {
        padding: 16px 18px;
    }

    :is(input, textarea) {
        border-radius: 6px;
        border: 1px solid var(ds-border-gray);

        font-family: 'Favorit Pro', sans-serif;
        font-size: 15px;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-black);

        &.redBorder {
            border-color: var(--ds-main-red);
        }

        &::placeholder {
            font-size: 14px;
            color: var(--ds-main-gray);
        }
    }
}

.submitBtn {
    padding: 9px 24px;
    width: 129px;
    height: 42px;
    border-radius: 6px;
    border: none;
    background-color: var(--ds-main-black);
    margin-top: 48px;

    & span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-white);
    }
}
