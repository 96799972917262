.descriptionModalWrapper {
    width: 624px;
    margin: 48px;
    gap: 24px;

    & > h2 {
        font-family: 'Favorit Pro', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }

    & > ul {
        list-style: none;

        & > li {
            font-weight: 350;
            font-size: 16px;
            line-height: 24px;
            color: var(--ds-main-black);
            position: relative;
            margin-left: 10px;
            padding-left: 23px;

            &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: var(--ds-main-red);
            }
        }
    }

    & > button {
        width: 112px;
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-black);

        span {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 767px) {
    .descriptionModalWrapper {
        width: calc(100vw - 48px);
        margin: 48px 12px;
    }
}
