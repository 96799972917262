.articleWrapper {
    width: 33%;

    & > * {
        white-space: pre-wrap;
    }

    & > h6 {
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 24px;
    }

    & > div {
        & > p {
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 24px;
            margin-bottom: 32px;
            height: 60px;
            max-width: 350px;
        }

        & > a,
        div {
            padding: 9px 24px 9px 12px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);
            cursor: pointer;
            background-color: var(--ds-main-white);
            text-decoration: none;

            & > img {
                width: 24px;
                height: 24px;
                margin-right: 12px;
            }

            & > span {
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
            }
        }
    }
}
@media (max-width: 1435px) {
    .articleWrapper {
        & > div {
            & > p {
                height: 90px;
            }
        }
    }
}
@media (max-width: 1359px) {
    .articleWrapper {
        & > h6 {
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 6px;
        }
        & > div {
            & > p {
                font-size: 16px;
                line-height: 20px;
                height: 70px;
                max-width: 313px;
                margin-bottom: 30px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .articleWrapper {
        width: 100%;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        & > h6 {
            margin-bottom: 24px;
        }

        & > div {
            display: flex;
            width: 100%;
            justify-content: space-between;
            & > p {
                min-width: 515px;
                margin-bottom: 0;
                max-width: 360px;
                height: auto;
            }
            & > a {
                margin-left: auto;
            }
        }
    }
}

@media (max-width: 767px) {
    .articleWrapper {
        margin-bottom: 36px;

        & > h6 {
            margin-bottom: 6px;
            font-size: 18px;
            line-height: 24px;
        }

        & > div {
            flex-direction: column;
            & > p {
                min-width: 310px;
                margin-bottom: 16px;
                max-width: 310px;
                font-size: 14px;
            }

            & > a,
            div {
                margin-left: 0;
                width: fit-content;
                padding: 4px 12px;

                & > img {
                    margin-right: 6px;
                }

                & > span {
                    font-size: 14px;
                }
            }
        }
    }
}
