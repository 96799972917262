.headerWrapper {
    width: 100%;
    height: 300px;
    background: url('../../../../../shared/images/png/articlesHeader.png') center / cover no-repeat;

    .contentWrapper {
        padding: 122px 0 0 62px;
        gap: 40px;

        h1 {
            color: var(--ds-main-black);
            font-size: 80px;
            font-weight: 700;
            line-height: 56px;
            letter-spacing: -1px;
        }

        & > span {
            width: fit-content;
            position: relative;

            .searchIcon {
                width: 33px;
                height: 100%;
                padding: 8px;
                margin-top: -22px;
                margin-right: -10px;
            }

            & > div {
                position: absolute;
                width: 480px;
                top: 44px;
                z-index: 1000;
            }
        }

        input {
            width: 480px;
            height: 42px;
            padding: 12px 30px 12px 12px;
            border: 1px solid var(--ds-border-dark-gray);
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.5);
        }
    }
}

@media (max-width: 1359px) {
    .headerWrapper {
        height: 240px;

        .contentWrapper {
            padding: 104px 0 0 24px;
            gap: 24px;

            h1 {
                font-size: 48px;
                line-height: 34px;
            }
        }
    }
}

@media (max-width: 767px) {
    .headerWrapper {
        height: 216px;

        .contentWrapper {
            padding: 105px 18px 0;
            gap: 18px;

            h1 {
                font-size: 30px;
                line-height: 21px;
                letter-spacing: -0.02em;
            }

            & > span,
            input {
                width: 100%;
            }

            & > span {
                & > div {
                    width: 100%;
                }
            }
        }
    }
}
