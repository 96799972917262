$purple: #7348ed;

.mapsContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 41px;
    padding: 0 0px 0 36px;
}

.mapContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.mapHeaderTitle {
    display: flex;
    align-items: center;
    gap: 16px;

    .disabled {
        color: var(--ds-main-gray);
    }

    .switch {
        width: 28px;
        height: 16px;
        border: 1px solid;
        border-radius: 8px;
        background-color: var(--ds-main-white);

        & > span {
            width: 24px;
            height: 12px;
            top: 1px;
            left: 1px;

            &::before {
                height: 12px;
                width: 12px;
                margin-top: -6px;
            }
        }

        &[aria-checked='false'] {
            border-color: var(--ds-bg-light-blue-100);

            & > span {
                background-color: var(--ds-bg-light-blue-100);
                &::before {
                    background-color: var(--ds-bg-dark-blue);
                    left: 0px;
                    right: auto;
                }
            }
        }

        &[aria-checked='true'] {
            border-color: var(--ds-bg-light-blue-100);

            & > span {
                background-color: var(--ds-bg-light-blue-100);
                &::before {
                    background-color: var(--ds-bg-dark-blue);
                    left: -9px;
                    right: auto;
                }
            }
        }
    }

    & > h2 {
        color: var(--ds-dtsip-text-black-100);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
    }
}

.chartName {
    font-size: 14px;
    color: var(--ds-text-black);
}

.visibilityTogglesContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    padding: 0 10px;
}

.profitCurveLine {
    width: 24px;
    height: 3px;
    background-color: $purple;
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: -12px;
}

.chartToggleCheckboxContainer {
    display: flex;
    align-items: center;

    & > .checkbox {
        outline: none;
        height: 18px;
        width: 18px;
        margin-right: 8px;
        box-shadow: none;
        border-color: initial;
    }

    & > label {
        font-weight: 350;
        font-size: 14px;
        line-height: 16px;
    }
}

.profitCurveButton {
    background: transparent;
    height: 24px;
    margin-right: 8px;
    border: 0;
}

@media (max-width: 1024px) {
    .mapsContainer {
        padding: 0 24px;
    }

    .mapHeaderTitle {
        & > h2 {
            font-size: 18px;
        }
    }

    .visibilityTogglesContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 32px;
        padding: 0;
    }
}

@media (max-width: 771px) {
    .mapsContainer {
        padding: 0;
    }
}

@media (max-width: 475px) {
    .chartName {
        font-size: 13px;
    }
    .mapsContainer {
        padding: 0;
    }
}
