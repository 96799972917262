.productContainer {
  border-radius: 6px;
  border: 1px solid var(--ds-border-gray);

  .productFooter {
    height: 78px;
    background: var(--ds-bg-pale-blue);
    padding-right: 25px;
    line-height: 20px;
    justify-content: end;

    :nth-child(1) {
      color: var(--ds-main-black);
      font-size: 16px;
      font-weight: 500;
      margin-right: 12px;
    }

    :nth-child(2) {
      color: var(--ds-main-black);
      font-size: 18px;
      font-weight: 400;
    }

    :nth-child(3) {
      color: var(--ds-main-gray);
      font-size: 15px;
      font-weight: 300;
    }
  }
}

.product {
  padding: 36px 36px 53px;

  .tariffBody {
    .priceMobile {
      display: none;

      :last-child {
        color: var(--ds-main-gray);
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
      }
    }

    .prolongation {
      margin-left: 13px;
      font-size: 15px;
      font-weight: 350;
      line-height: 18px;
    }
  }

  .productContent {
    color: var(--ds-main-black);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 36px;

    .controls {
      margin-top: 16px;
      margin-bottom: 13px;

      .tariffs {
        width: 300px;
        margin-right: 12px;
      }

      .period {
        width: 200px;
      }

      .price {
        margin-left: auto;
        align-self: center;

        :last-child {
          color: var(--ds-main-gray);
          font-size: 15px;
          font-weight: 300;
        }
      }
    }
  }

  .productAdditionalOptions {
    margin-top: 63px;

    .title {
      color: var(--ds-main-gray);
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      margin-bottom: 29px;

      :last-child {
        background: var(--ds-main-light-gray);
        height: 1px;
        flex: 1;
        margin-left: 12px;
      }
    }

    .optionsList {
      .optionHead {
        .optionTitle {
          margin-bottom: 13px;

          > :last-child {
            margin-left: 12px;
          }
        }

        &:first-child {
          color: var(--ds-main-black);
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .divider {
        margin-top: 34px;
        margin-bottom: 25px;
        background: var(--ds-main-light-gray);
        height: 1px;
      }
    }

    .optionContent {
      color: var(--ds-main-black);
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;

      .optionBody {
        .priceMobile {
          display: none;

          :last-child {
            color: var(--ds-main-gray);
            font-size: 15px;
            font-weight: 300;
            line-height: 20px;
          }
        }

        .prolongation {
          margin-left: 12px;
          font-size: 15px;
          font-weight: 350;
          line-height: 18px;
        }

        .controls {
          margin-top: 22px;
          margin-bottom: 13px;

          .service {
            width: 300px;
            margin-right: 12px;
          }

          .date {
            width: 200px;
          }

          .price {
            margin-left: auto;
            align-self: center;

            :last-child {
              color: var(--ds-main-gray);
              font-size: 15px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .product {
    padding: 36px 36px 53px;

    .productContent {
      font-weight: 500;
    }

    .optionContent {
      font-weight: 500;

      .optionsList {
        .optionHead {
          &:first-child {
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .product {
    padding: 36px 36px 53px;
  }
}

@media (max-width: 767px) {
  .product {
    padding: 24px 18px 34px;
    margin-right: 0;

    .productHead {
      .trash {
        display: none;
      }
    }

    .tariffBody {
      flex-direction: column;

      .priceMobile {
        display: block;
        margin-top: 27px;

        .cost {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .prolongation {
        color: var(--ds-main-black);
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
      }
    }

    .productContent {
      font-size: 16px;

      .controls {
        margin-top: 6px;
        margin-bottom: 19px;
        flex-direction: column;

        .period,
        .tariffs {
          width: 100%;
        }

        .period {
          margin-top: 21px;
        }

        .tariffs {
          margin-bottom: 10px;
        }
      }

      .price {
        display: none;
      }

      .title {
        .questionMark {
          margin-left: 12px;
        }
      }
    }

    .productAdditionalOptions {
      margin-top: 39px;

      .title {
        color: var(--ds-main-gray);
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }

      .trash {
        display: none;
      }

      .optionContent {
        font-size: 16px;

        .optionBody {
          flex-direction: column;

          .priceMobile {
            display: block;
            margin-top: 27px;

            .cost {
              color: var(--ds-main-black);
              font-size: 18px;
              font-weight: 400;
              line-height: 20px;
            }
          }

          .controls {
            margin-top: 24px;
            margin-bottom: 19px;
            flex-direction: column;

            .date,
            .service {
              width: 100%;
            }

            .service {
              margin-bottom: 30px;
            }
          }

          .prolongation {
            margin-top: 3px;
            margin-left: 13px;
          }
        }

        .price {
          display: none;
        }
      }

      .optionsList {
        .optionHead {
          .optionTitle {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }

  .productContainer {
    .productFooter {
      justify-content: start;
      padding-left: 18px;
      height: 60px;
    }
  }
}
