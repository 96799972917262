.cardWrapper {
    min-height: 48px;
    width: calc(100% - 48px);
    gap: 18px 24px;

    & > div {
        & > svg {
            margin-left: auto;
            cursor: pointer;
        }

        & > span {
            display: none;
        }
    }

    .addCart {
        gap: 18px;

        p {
            font-size: 15px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: #919294;
        }
    }
}

.addCardBtn {
    width: 48px;
    height: 48px;
    background: var(--ds-main-black);
    border: none;

    &.btnPosition {
        position: absolute;
        right: 48px;
    }
}

@media (max-width: 1599px) {
    .cardWrapper {
        width: 100%;
    }

    .addCardBtn {
        &.btnPosition {
            position: absolute;
            right: 0;
        }
    }
}

@media (max-width: 1023px) {
    .cardWrapper {
        height: auto;
        flex-wrap: wrap;
        gap: 18px 0;

        & > div {
            & > svg {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .cardWrapper {
        height: auto;
        flex-direction: column;
        gap: 16px 0;
        width: 100%;

        & > div {
            margin: 0;
            & > svg {
                position: relative;
                margin: 0;
                width: 36px;
                height: 36px;
            }

            & > span {
                display: block;
                font-size: 13px;
                font-style: normal;
                font-weight: 350;
                line-height: 20px;
                color: var(--ds-main-gray);
                margin-left: 18px;
            }
        }
    }
}
