.articlesListWrapper {
    margin: 90px 61px 60px;

    & > div:last-child {
        margin-top: 78px;
    }
}

.contentWrapper {
    gap: 36px;
}

@media (max-width: 1359px) {
    .articlesListWrapper {
        margin: 60px 24px;

        & > div:last-child {
            margin-top: 74px;
        }
    }

    .contentWrapper {
        gap: 26px;
    }
}

@media (max-width: 1023px) {
    .articlesListWrapper {
        margin: 60px 24px 139px;

        & > div:last-child {
            margin-top: 58px;
        }
    }

    .contentWrapper {
        gap: 28px;
    }
}

@media (max-width: 767px) {
    .articlesListWrapper {
        margin: 40px 18px 48px;
    }

    .contentWrapper {
        gap: 42px;
    }
}
