.sessionExpiredPageWrapper {
    position: relative;
    height: 600px;
    width: 100%;
    margin-bottom: 108px;
    background:
        url('../../shared/images/png/technicSquaresBg.png') top left / auto 100% no-repeat,
        url('../../shared/images/png/technicBg.png') center / 100% 100% no-repeat;
}

.pageContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 156px 0 0 114px;
    width: 542px;
    gap: 48px;

    .textBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
        text-transform: uppercase;

        span {
            font-size: 18px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-red);
        }
    }

    .redBtn {
        width: 127px;
        height: 42px;
        padding: 12px 24px;
        border-radius: 6px;
        background: var(--ds-main-red);
        border: none;

        &:focus {
            box-shadow: none;
        }

        > span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 1359px) {
    .sessionExpiredPageWrapper {
        height: 480px;
    }
}

@media (max-width: 1023px) {
    .sessionExpiredPageWrapper {
        margin-bottom: 100px;
    }

    .pageContent {
        padding: 145px 0 0 48px;
    }
}

@media (max-width: 767px) {
    .sessionExpiredPageWrapper {
        display: block;
        height: 576px;
        margin-bottom: 180px;
        background:
            url('../../shared/images/png/technicSquaresBgSm.png') top left / 100% auto no-repeat,
            url('../../shared/images/png/technicBgSm.png') center / 100% 100% no-repeat;
    }

    .pageContent {
        padding: 35px 18px;
        width: 100%;
    }
}
