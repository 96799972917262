.marketPointPanel {
    width: 197px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: var(--ds-main-white);
    color: var(--ds-dtsip-text-black-100);
    padding: 0px 2px 0px 6px;
    height: 36px;
    border-radius: 4px;
    box-shadow: 4px 4px 0px 0px rgba(64, 101, 128, 0.1);

    border: 1px solid var(--ds-border-gray);
    cursor: pointer;

    &:hover {
        position: relative;
        z-index: 1000;
    }

    & > span:first-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }

    & > span:nth-child(2) {
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }
}

.panelPinButton {
    width: 30px;
    background-color: var(--ds-main-white);
    border-radius: 0;
    border: 0;
    border-left: 1px solid var(--ds-border-gray) !important;

    & > svg {
        margin: 0 auto;
    }
}

.panelItemButton {
    background-color: var(--ds-main-white);
    border: none;
    flex: 1;
    gap: 2px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: clip;
    border-radius: 0;

    & > span:first-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }

    & > span:nth-child(2) {
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }
}

.pricePointPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--ds-bg-extra-light-gray);
    color: var(--ds-dtsip-text-black-100);
    padding: 3px 0 3px 8px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--ds-border-gray);
    cursor: pointer;

    & > span:first-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }

    & > span:last-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        padding-right: 10px;
    }
}

$arrow-right: 20;

.indextTooltip {
    background: var(--ds-main-white);
    border-radius: 4px;
    padding: 5px 8px 5px 8px;
    width: 60px;
    border: 1px solid var(--ds-border-gray);

    position: relative;

    &:before,
    &:after {
        position: absolute;
        content: '';
        display: block;
    }

    & > span {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }
}

@media screen and (max-width: 769px) {
    .marketPointPanel {
        width: 141px;

        & > span:first-child {
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-dtsip-text-black-100);
        }

        & > span:nth-child(2) {
            font-size: 9px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }
    }

    .panelItemButton {
        background-color: var(--ds-main-white);
        gap: 2px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        & > span:first-child {
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-dtsip-text-black-100);
        }

        & > span:nth-child(2) {
            font-size: 9px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }
    }
}
