.errorModalWrapper {
    max-width: 560px;
    width: 100%;
    padding: 48px;

    & > h2 {
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
    }

    & > p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }

    & > button {
        margin-top: 32px;
        height: 42px;
        padding: 12px 24px 12px 24px;
        border: 1px solid var(--ds-main-gray);
        border-radius: 6px;
        background: var(--ds-main-white);

        span {
            font-family: 'Favorit Pro', sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-black);
        }
    }
}

@media (max-width: 767px) {
    .infoModalWrapper {
        width: 324px;
        padding: 43px 24px 24px;
    }

    .errorModalWrapper {
        max-width: 324px;
    }
}
