.textArticleBlockWrapper {
    width: calc(100% - 1009px);
    height: fit-content;
    gap: 56px;
}

@media (max-width: 1359px) {
    .textArticleBlockWrapper {
        width: calc(100% - 668px);
        gap: 48px;
    }
}

@media (max-width: 1023px) {
    .textArticleBlockWrapper {
        width: calc(100% - 374px);
    }
}
